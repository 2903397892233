import "../../styles/Faq.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import FaqContent from "./FaqContent";

const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl.replace("3000", "4200");
if (baseUrl.includes("app-preprod")) {
  baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
} else if (baseUrl.includes("app")) {
  baseUrl4200 = baseUrl4200.replace("app", "api");
}
function Faq({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const [options, setOptions] = useState([]);
  const [categorieOptions, setCategorieOptions] = useState([]);
  const [recordOptions, setRecordOptions] = useState([]);
  const [selectedSubj, setSelectedSubj] = useState("");
  const [selectedRecord, setSelectedRecord] = useState({});
  const [faqDate, setFaqDate] = useState("");

  // useEffect(() => {
  //   axios
  //     .get("/intranetUser")
  //     .then((res) => {
  //       // res.data.centraleName === ""
  //       //   ? null
  //       //   :
  //       if (res.status === 201) {
  //         setIsConnected(true);
  //         setUserIntranet((prevState) => {
  //           return {
  //             ...prevState,
  //             userEmail: res.data.userEmail,
  //             token: res.data.token,
  //           };
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  useEffect(() => {
    if (user.token) {
      axios
        .post(
          baseUrl4200 + "/airtableReq/getSelectFields",
          {
            tableAndFields: [{ tableName: "FAQ", fieldName: "Catégories" }],
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 201) {
            console.log(res);
            setOptions(res.data.fieldsAndOptions);
            let cateIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Catégories");
            setCategorieOptions(res.data.fieldsAndOptions[cateIndex].optionArray);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  useEffect(() => {
    // On vérifie que l'utilisateur dispose d'un token pour l'authentification côté backend
    if (user.token) {
      //On entame une requête de type POST. C'est à dire qu'on va envoyer des données/paramètres au serveur, et on attend une réponse de sa part
      axios
        .post(
          baseUrl4200 + "/airtableReq/getRecords", //URL ou "EndPoint" où l'on envoie la requête
          //Paramètres du body (au format JSON) que l'on envoie au serveur
          {
            baseId: "appdcpGp5m9QJ9ye4",
            tableName: "FAQ",
            viewName: null,
          },
          //Paramètre du header (format JSON) que l'on envoie au serveur pour authentification
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        //Lorsque la requête est traitée côté serveur, ce dernier renvoie une réponse. (Si elle est positive, on reçoit un status 20X, sinon 50X ou 40X).
        //Si le serveur rencontre un problème ou qu'une erreur est détectée, l'erreur est affichée grâce au bloque catch ci-dessous
        .then((res) => {
          //Si le status de la réponse est 200 (positif)
          if (res.status === 200) {
            // On affiche la réponse du serveur dans la console du navigateur (je te conseille d'aller y jeter un oeil pour voir comment cela est fait)
            console.log(res);
            //On vient renseigner les records dans une liste "recordOptions" grâce à setRecordOptions. On a déclarer un useState en début de code

            let tmpRecArr = res.data.records;
            tmpRecArr.sort((a, b) => a["Name"].localeCompare(b["Name"]));

            setRecordOptions(tmpRecArr);

            console.log(recordOptions);
          }
        })
        //Traque les éventuelles erreurs côté serveur
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]); //Déclencheur du useEffect(), ne déclenche que lorsque userIntranet est mis à jour.

  const handleSelectOnChange = (e) => {
    e.preventDefault();
    setSelectedSubj(document.getElementById("faq-select-questions").value);
    var selectedRec = recordOptions.find((rec) => rec["Name"] === document.getElementById("faq-select-questions").value);
    var formatedDate = new Date(selectedRec["Last modified time"]);
    formatedDate = formatedDate.toLocaleDateString("fr-FR");
    // selectedRec["Last modified time"] = formatedDate;
    setFaqDate(formatedDate);
    setSelectedRecord(selectedRec);
  };

  return (
    <div className="faq-container">
      <div className="faq-title">
        <h1 style={{ "text-shadow": "1px 1px 20px gray" }}>Bienvenue dans la FAQ d'Energies de Loire</h1>
        <p>Trouvez l'ensemble des réponses aux questions que vous vous posez !</p>
      </div>
      <div className="faq-select">
        <p>Que souhaitez-vous faire ?</p>
        <select id="faq-select-questions" onChange={handleSelectOnChange}>
          <option value="">Sélectionner</option>
          {recordOptions.length > 0
            ? recordOptions.map((opt, i) => {
                return (
                  <option key={i} value={opt["Name"]}>
                    {opt["Name"]}
                  </option>
                );
              })
            : null}
        </select>
        <div className="three-color-separator"></div>
      </div>
      <div className="faq-content">
        {selectedSubj === "" ? (
          <p>Merci de sélectionner un élément dans le menu déroulant ci-dessus.</p>
        ) : (
          <div style={{ width: "90%", margin: "0 auto" }}>
            <p style={{ "font-style": "italic", "text-align": "right" }}>
              Mis à jour le {faqDate}, par {selectedRecord["Last modified by"].name}
            </p>
            <h2 style={{ color: "#f4af24", "font-size": "30px", "text-decoration": "underline" }}>{selectedRecord["Name"]}</h2>
            {selectedRecord["Done"] ? null : (
              <p>🚧Attention, cette section est en cours d'élaboration ! Il peut manquer des informations.</p>
            )}
            <br />
            <FaqContent selectedRecord={selectedRecord} />
          </div>
        )}
      </div>
    </div>
  );
}
export default Faq;
