import backgroundEDLHome from "../assets/backgroundEDLIntranet2-3.png";
import uploadImg from "../assets/upload.png";
import "../styles/DepotFacture.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";

const FormData = require("form-data");

function DepotFacture({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [onDropMsg, setOnDropMsg] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [rdyToSend, setRdyToSend] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [longLoading, setLongLoading] = useState(false);
  const [longLoadingOpacity, setLongLoadingOpacity] = useState(0);
  const [initialLongLoading, setInitialLongLoading] = useState(0);
  const [initialLongLoadingOpacity, setInitialLongLoadingOpacity] = useState(0);
  const [amountPb, setAmountPb] = useState(false);
  const [amountPbBorder, setAmountPbBorder] = useState("none");
  const [userProject, setUserProject] = useState({
    userEmail: "",
    centraleName: "",
    projectName: "",
    token: "",
    company: "",
  });
  const [datasSent, setDatasSent] = useState(false);
  const [inputValue, setInputValue] = useState(0);

  const [jsonResponse, setJsonResponse] = useState({
    message: "",
    pdfText: "",
    netAPayer: "",
    netAPayerInd: "",
    montantHT: "",
    montantHTInd: "",
    montantHTFwd: "",
    montantHTBwd: "",
    montantTTCFwd: "",
    montantTTCBwd: "",
    montantTVAFwd: "",
    montantTVABwd: "",
    nFacture: "",
    nFactureFwd: "",
    nFactureBwd: "",
    dateFacture: "",
    dateFactureFwd: "",
    dateFactureBwd: "",
    dateFactureInd: "",
    dateEcheance: "",
    fileNameOnServ: "",
    mindTheDate: false,
    pNumber: "",
    directoryPath: "",
  });

  const [partenaires, setPartenaires] = useState([]);
  const [partenaire, setPartenaire] = useState();
  const [centrales, setCentrales] = useState([]);
  const [centrale, setCentrale] = useState();
  const [projets, setProjets] = useState([]);
  const [projet, setProjet] = useState();

  useEffect(() => {
    console.log("projet");
    console.log(projet);
  }, [projet]);

  useEffect(() => {
    console.log("centrale");
    console.log(centrale);
  }, [centrale]);

  useEffect(() => {
    console.log("partenaire");
    console.log(partenaire);
  }, [partenaire]);

  var timer;
  var interval;

  let reqPartenaireCouter = 0;

  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api-preprod");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  console.log("baseUrl4200 : " + baseUrl4200);

  useEffect(() => {
    console.log(initialLongLoading);
  }, [initialLongLoading]);

  useEffect(() => {
    if (user.token) {
      setLoading(true);
      setInitialLongLoading((initialLongLoading) => initialLongLoading + 3);

      let opaCounter = 0;
      interval = setInterval(() => {
        setInitialLongLoadingOpacity((prevOpa) => prevOpa + 0.02);
        opaCounter += 0.02;
        if (opaCounter >= 1) {
          clearInterval(interval);
        }
      }, 50);

      reqPartenaireCouter++;
      if (reqPartenaireCouter === 1) {
        axios
          .post(
            baseUrl4200 + "/airtableReq/getRecords",
            {
              baseId: "app4eaIYU41A4lONh",
              tableName: "tblRIYGEYHtXCgOBH",
              viewName: "viwfLzimijtx8ejev",
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              let partenaireArray = [];
              res.data.records.forEach((elt) => {
                partenaireArray.push({
                  label: elt["Nom"].toUpperCase(),
                  id: elt.id,
                });
              });
              setPartenaires(partenaireArray);
            } else {
              console.log("Un problème est survenu lors de l'envoi des données.");
            }
            setLoading(false);
            setInitialLongLoading((initialLongLoading) => initialLongLoading - 1);
            // clearTimeout(timer);
          });

        setLoading(true);

        //centrale
        axios
          .post(
            baseUrl4200 + "/airtableReq/getRecords",
            {
              baseId: "app4eaIYU41A4lONh",
              tableName: "tblJuu7N3vxgPy3mH",
              viewName: null,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              let centralesArray = [];
              res.data.records.forEach((elt) => {
                centralesArray.push({
                  label: elt["Référence centrale"],
                  id: elt.id,
                });
              });
              setCentrales(centralesArray);
            } else {
              console.log("Un problème est survenu lors de l'envoi des données.");
            }
            setLoading(false);
            setInitialLongLoading((initialLongLoading) => initialLongLoading - 1);
          });

        setLoading(true);
        //projet
        axios
          .post(
            baseUrl4200 + "/airtableReq/getRecords",
            {
              baseId: "app4eaIYU41A4lONh",
              tableName: "tblAKuh2vwFSNCI8F",
              viewName: null,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              let projetsArray = [];
              res.data.records.forEach((elt) => {
                projetsArray.push({
                  label: elt["Name"],
                  id: elt.id,
                });
              });
              setProjets(projetsArray);
            } else {
              console.log("Un problème est survenu lors de l'envoi des données.");
            }
            setLoading(false);
            setInitialLongLoading((initialLongLoading) => initialLongLoading - 1);
          });
      }
    }
  }, [user]);

  useEffect(() => {
    handleAmountPb();
  });

  function checkValue(value) {
    console.log(value);
    // console.log(!isNaN(value));
    var nbText = value.toString();
    console.log(nbText);
    if (nbText.includes(",")) {
      nbText = nbText.replace(/,/g, ".");
      console.log(nbText);
      return parseFloat(nbText);
    } else {
      return value;
    }
  }

  const handleAmountPb = () => {
    if (parseFloat(jsonResponse.montantHT) + parseFloat(jsonResponse.montantTVA) !== parseFloat(jsonResponse.montantTTC)) {
      setAmountPb(true);
      setAmountPbBorder("2px solid red");
    } else {
      setAmountPb(false);
      setAmountPbBorder("1px solid gray");
    }

    console.log(parseFloat(jsonResponse.montantHT) + parseFloat(jsonResponse.montantTVA));
  };

  const changeHandler = (event) => {
    console.log(event.target.files[0]);
    if (validateFile(event.target.files[0])) {
      setSelectedFile(event.target.files[0]);

      setIsSelected(true);
      document.getElementById("facture-btn-get-started").style = "z-index:100";
    } else {
      setIsSelected(false);
      setErrorMessage("File type not permitted");
      document.getElementById("facture-btn-get-started").style = "z-index:-1";
    }
  };

  const handleRdyToSend = () => {
    console.log(selectedFile);
    let formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    console.log(formData);
    setLoading(true);

    timer = setTimeout(() => {
      setLongLoading(true);
      var opaCounter = 0;
      interval = setInterval(() => {
        setLongLoadingOpacity((prevOpa) => prevOpa + 0.02);
        opaCounter += 0.02;
        if (opaCounter >= 1) {
          clearInterval(interval);
        }
      }, 50);
    }, 5000);

    axios
      .post(baseUrl4200 + "/file/gettextfrompdf", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
        mode: "cors",
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
        setLongLoading(false);
        clearTimeout(timer);
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        var theRes = response.data;

        setJsonResponse((prevState) => {
          return {
            ...prevState,
            pdfText: theRes.pdfText,
            netAPayer: theRes.netAPayer,
            netAPayerInd: theRes.netAPayerInd,
            montantHT: theRes.montantHT,
            montantHTInd: theRes.montantHTInd,
            montantTTC: theRes.montantTTC,
            montantTTCInd: theRes.montantTTCInd,
            montantTVA: theRes.montantTVA,
            montantTVAInd: theRes.montantTVAInd,
            message: theRes.message,
            montantHTFwd: theRes.montantHTFwd,
            montantHTBwd: theRes.montantHTBwd,
            montantTTCFwd: theRes.montantTTCFwd,
            montantTTCBwd: theRes.montantTTCBwd,
            montantTVAFwd: theRes.montantTVAFwd,
            montantTVABwd: theRes.montantTVABwd,
            nFacture: theRes.nFacture,
            nFactureFwd: theRes.nFactureFwd,
            nFactureBwd: theRes.nFactureBwd,
            dateFacture: theRes.dateFacture,
            dateFactureFwd: theRes.dateFactureFwd,
            dateFactureBwd: theRes.dateFactureBwd,
            dateFactureInd: theRes.dateFactureInd,
            dateEcheance: theRes.dateEcheance,
            fileNameOnServ: theRes.fileNameOnServ,
            mindTheDate: theRes.mindTheDate,
            pNumber: theRes.pNumber,
            partenaire: theRes.partenaire.toUpperCase(),
            directoryPath: theRes.directoryPath,
          };
        });

        let partenaireName = theRes.partenaire.toUpperCase();
        let partenaireDatas = partenaires.find((elt) => elt.label === partenaireName);
        if (partenaireDatas) {
          setPartenaire({
            value: partenaireDatas,
          });
        } else {
          let partenaireWords = partenaireName.split(" ");
          for (let i = 0; i < partenaires.length; i++) {
            const companyName = partenaires[i].label;
            for (let j = 0; j < partenaireWords.length; j++) {
              if (companyName.includes(partenaireWords[j])) {
                setPartenaire({
                  value: partenaires[i],
                });
                break;
              }
            }
          }
          setPartenaire({
            value: "",
          });
        }

        let nEDL = theRes.pNumber;
        console.log("nEDL", nEDL);
        console.log("centrales", centrales);
        let refCentrale = centrales.find((elt) => elt.label.indexOf(nEDL) !== -1);
        console.log("Référence Centrale", refCentrale);

        let refProjet = projets.find((elt) => elt.label.indexOf(nEDL) !== -1);
        console.log("Référence Projet", refProjet);

        if (refCentrale) {
          setCentrale({
            value: refCentrale,
          });
        } else {
          setCentrale({
            value: "",
          });
        }

        if (refProjet) {
          setProjet({
            value: refProjet,
          });
        } else {
          setProjet({
            value: "",
          });
        }
        // setCentrale({
        //   refEDL: theRes.pNumber,
        //   name: centrales.find((elt) => elt.label.indexOf(theRes.pNumber) !== -1),
        //   id: centrales.find((elt) => elt.label.indexOf(theRes.pNumber) !== -1),
        // });

        setIsLoaded(true);
        setLoading(false);
        setLongLoading(false);
        clearTimeout(timer);

        console.log(jsonResponse);
        console.log(theRes);
      });
    // .then(() => handleAmountPb);

    setRdyToSend(true);
  };

  const handleSendToAirtable = (e) => {
    e.preventDefault();
    //Get all the displayed datas to send
    var factNum = document.getElementById("inputNumFacture").value;
    var factDate = document.getElementById("submitFormDateInput").value;
    var echeanceDate = document.getElementById("submitFormDateEcheanceInput").value;
    var montantHT = document.getElementById("inputMontantHTFacture").value;
    var montantTVA = document.getElementById("inputMontantTVAFacture").value;
    var montantTTC = document.getElementById("inputMontantTTCFacture").value;

    if (
      factNum === "" ||
      factDate === "" ||
      echeanceDate === "" ||
      montantHT === "" ||
      montantTVA === "" ||
      montantTTC === "" ||
      partenaire.id === "" ||
      centrale.id === "" ||
      projet.id === ""
    ) {
      return;
    }

    console.log(factNum);
    console.log(factDate);
    console.log(echeanceDate);
    console.log(montantHT);
    console.log(montantTVA);
    console.log(montantTTC);
    console.log(partenaire);
    console.log(centrale);
    console.log(projet);

    setLoading(true);

    axios
      .post(
        baseUrl4200 + "/airtableReq/createRecord",
        {
          baseId: "app4eaIYU41A4lONh",
          tableName: "tblokGjysPpz6Y421",
          fields: {
            "[Date] Facture": factDate,
            "[Date] Echeance": echeanceDate,
            "Montant HT": parseFloat(montantHT),
            "Montant TVA": parseFloat(montantTVA),
            "Montant TTC": parseFloat(montantTTC),
            "Numéro de facture": factNum,
            Partenaires: [partenaire.value.id],
            "[Link] Synchro base facturation (centrale)": [centrale.value.id],
            "Synchro base facturation": [projet.value.id],

            Attachments: [{ url: jsonResponse.fileNameOnServ }],
          },
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setDatasSent(true);
        } else {
          console.log("Un problème est survenu lors de l'envoi des données.");
        }
        setLoading(false);
        setLongLoading(false);
        clearTimeout(timer);

        setLoading(true);
        axios
          .post(
            baseUrl4200 + "/file/delete",
            {
              path: jsonResponse.directoryPath,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            if (res.status === 200) {
            } else {
              console.log("Un problème est survenu lors de la suppression du document.");
            }
            setLoading(false);
            setLongLoading(false);
            clearTimeout(timer);
          })
          .catch((err) => {
            console.log("Un problème est survenu lors de la suppression du document.");
          });
      });
    // axios
    //   .post(
    //     // "http://localhost:4200/file/sendFactureToAirtable",
    //     baseUrl4200 + "/file/sendFactureToAirtable",
    //     {
    //       factNum: factNum,
    //       factDate: factDate,
    //       montantHT: montantHT,
    //       montantTVA: montantTVA,
    //       montantTTC: montantTTC,
    //       userEmail: user.userEmail,
    //       fileNameOnServ: jsonResponse.fileNameOnServ,
    //       company: userProject.company,
    //     },
    //     {
    //       headers: {
    //         "Content-type": "application/json",
    //         Authorization: `Bearer ${user.token}`,
    //       },
    //       mode: "cors",
    //     }
    //   )
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setDatasSent(true);
    //     } else {
    //       console.log("Un problème est survenu lors de l'envoi des données.");
    //     }
    //     setLoading(false);
    //     setLongLoading(false);
    //     clearTimeout(timer);
    //   });
  };

  //\\\\\\\\\\\\\  Récupère les données depuis airtable.  //////////////////\\
  // const handleGetTable = () => {
  //   var config = {
  //     method: "get",
  //     url: "https://api.airtable.com/v0/appkjws54CFXqjH6K/Test1",
  //     headers: {
  //       Authorization: "Bearer MyToken",
  //       // Cookie: "brw=brwGLeq5VYvLX0DSt",
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.records));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const dragOver = (e) => {
    e.preventDefault();
    setOnDropMsg("Déposez le ici !");
    document.getElementById("dropZone").style.transform = "scale(1.05)";
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setOnDropMsg("Déposez le ici !");
    document.getElementById("dropZone").style.transform = "scale(1.05)";
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setOnDropMsg("");
    document.getElementById("dropZone").style.transform = "scale(1)";
  };

  const fileDrop = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.files[0]);
    var file = e.dataTransfer.files[0];
    // console.log(selectedFile);
    // if (files.length) {
    handleFiles(file);
    // }
    setOnDropMsg("");
  };

  const handleFiles = (file) => {
    if (validateFile(file)) {
      // add to an array so we can display the name of file
      setSelectedFile(file);
      setIsSelected(true);
      document.getElementById("facture-btn-get-started").style = "z-index:100";
    } else {
      // add a new property called invalid
      // add to the same array so we can display the name of the file
      // set error message
      // file["invalid"] = true;
      // setSelectedFile(file);
      setIsSelected(false);
      setErrorMessage("File type not permitted");
    }
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
    if (validTypes.includes(file.type)) {
      return true;
    }
    return false;
  };

  return (
    <div className="facture-container-Home" onClick={() => setResponsiveMenuVisible(false)}>
      {loading ? (
        <div>
          <div id="spinner-modal"></div>
          {longLoading ? (
            <p id="loading-long-text" style={{ opacity: longLoadingOpacity }}>
              Analyzing datas...
            </p>
          ) : null}
        </div>
      ) : null}

      {initialLongLoading ? (
        <div>
          <div id="spinner-modal"></div>
          {initialLongLoading ? (
            <p id="loading-long-text" style={{ opacity: initialLongLoadingOpacity }}>
              Loading initial datas...
            </p>
          ) : null}
        </div>
      ) : null}

      <div className="facture-background-div">
        <img className="facture-background-img" src={backgroundEDLHome} alt="background-EDL" />
      </div>
      <div className="blog-home-title">
        <div className="titles-facture">
          <p>Energies de loire ☀️</p>
          <h1>DEPOSER UNE FACTURE</h1>
          <h2>Glissez-déposez votre facture, on s'occupe du reste !</h2>
        </div>

        <div className="facture-right-block">
          {!isLoaded ? (
            <div
              className="facture-submitForm"
              id="submitForm"
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
            >
              <div className="upload-icon"></div>
              <form encType="multipart/form-data">
                1 - Glissez votre document ci-dessous ou cliquez sur{" "}
                <button
                  className="btn-browse"
                  id="buttonGetFile"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("getFile").click();
                  }}
                >
                  Parcourir...
                </button>
                <br /> (format accepté : PDF)
                <input type="file" id="getFile" onChange={changeHandler} name="fileToUpload"></input>
                {/* {loading ? (
                        <p>
                          Votre document est en cours de traitement. Merci de bien
                          vouloir patienter...
                        </p>
                      ) : null} */}
                {isSelected ? (
                  <div>
                    {errorMessage !== "" ? <p>{errorMessage}</p> : null}
                    <p>Nom du fichier : {selectedFile.name}</p>
                    <p>Type de fichier : {selectedFile.type}</p>
                    <p>Poids (bytes) : {selectedFile.size}</p>
                  </div>
                ) : (
                  <div className="dropZone" id="dropZone">
                    {" "}
                    {onDropMsg !== "" ? (
                      <p>{onDropMsg}</p>
                    ) : (
                      <div>
                        <p>Glissez votre document ici !</p>
                      </div>
                    )}
                    <img src={uploadImg} alt="upload" />
                  </div>
                )}
                2 -{" "}
                <input className="btn-get-started" id="facture-btn-get-started" type="button" value="Analyser" onClick={handleRdyToSend} />
              </form>
            </div>
          ) : !datasSent ? (
            <div className="facture-submitForm">
              <h3>Merci de vérifier les champs ci-dessous avant d'envoyer votre facture.</h3>
              {amountPb ? (
                <p className="formAlert" id="formAlert">
                  Notre algorithme a détecté un potentiel problème concernant les montants relevés sur le document. Merci de bien prendre le
                  temps de vérifier ces lignes.
                </p>
              ) : null}

              <label>
                Numéro de facture :{" "}
                <input
                  id="inputNumFacture"
                  type="text"
                  defaultValue={jsonResponse.nFacture}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        nFacture: e.target.value,
                      };
                    });
                  }}
                ></input>
              </label>

              <label>
                Numéro de projet :{" "}
                <input
                  id="inputProjectNumber"
                  type="text"
                  defaultValue={jsonResponse.pNumber}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        pNumber: e.target.value,
                      };
                    });
                  }}
                ></input>
              </label>

              <label>
                Projet :
                <Autocomplete
                  disablePortal
                  id="autocomplete-projet"
                  options={projets}
                  sx={{ width: 350 }}
                  value={projet.value}
                  onChange={(event, newValue) => {
                    setProjet({
                      value: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Référence projet" />}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.label}
                    </li>
                  )}
                />
              </label>

              <label>
                Centrale :
                <Autocomplete
                  disablePortal
                  id="autocomplete-centrale"
                  options={centrales}
                  sx={{ width: 350 }}
                  value={centrale.value}
                  onChange={(event, newValue) => {
                    setCentrale({
                      value: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} label="Référence centrale" />}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.label}
                    </li>
                  )}
                />
              </label>

              <label>
                Partenaire :
                <Autocomplete
                  disablePortal
                  id="autocomplete-partenaire"
                  options={partenaires}
                  sx={{ width: 350 }}
                  value={partenaire.value}
                  onChange={(event, newValue) => {
                    setPartenaire({ value: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} label="Nom société partenaire" />}
                  renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                      {item.label}
                    </li>
                  )}
                />
              </label>
              <label>
                Date de la facture :
                <input
                  id="submitFormDateInput"
                  type="date"
                  defaultValue={jsonResponse.dateFacture}
                  // defaultValue={
                  //   jsonResponse.dateFacture !== "Not found"
                  //     ? jsonResponse.dateFacture instanceof Date && !isNaN(jsonResponse.dateFacture.valueOf())
                  //       ? jsonResponse.dateFacture
                  //       : new Date(jsonResponse.dateFacture - new Date().getTimezoneOffset() * 120000).toISOString().substring(0, 10)
                  //     : null
                  // }
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      setJsonResponse((prevState) => {
                        console.log(e.target.value);

                        console.log(e.target.value);
                        return {
                          ...prevState,
                          dateFacture: new Date(e.target.value),
                        };
                      });
                    }
                  }}
                ></input>
                {jsonResponse.mindTheDate ? (
                  <p style={{ color: "red", fontSize: "12px" }}>Attention, la date par défaut est celle d'aujourd'hui !</p>
                ) : null}
              </label>

              <label>
                Date d'échéance :
                <input
                  id="submitFormDateEcheanceInput"
                  type="date"
                  defaultValue={jsonResponse.dateEcheance}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      setJsonResponse((prevState) => {
                        console.log(e.target.value);

                        console.log(e.target.value);
                        return {
                          ...prevState,
                          dateEcheance: new Date(e.target.value),
                        };
                      });
                    }
                  }}
                ></input>
                {jsonResponse.mindTheDate ? (
                  <p style={{ color: "red", fontSize: "12px" }}>Attention, la date par défaut est celle d'aujourd'hui !</p>
                ) : null}
              </label>

              <label>
                Montant H.T. (€) :{" "}
                <input
                  id="inputMontantHTFacture"
                  type="text"
                  defaultValue={jsonResponse.montantHT}
                  style={{ border: amountPbBorder }}
                  onChange={(e) => {
                    // setInputValue(e.target.value);
                    e.target.value = checkValue(e.target.value);

                    handleAmountPb();
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        montantHT: e.target.value,
                      };
                    });
                  }}
                ></input>
              </label>

              <label>
                TVA (€) :{" "}
                <input
                  id="inputMontantTVAFacture"
                  type="text"
                  defaultValue={jsonResponse.montantTVA}
                  style={{ border: amountPbBorder }}
                  onChange={(e) => {
                    // setInputValue(e.target.value);
                    e.target.value = checkValue(e.target.value);

                    handleAmountPb();
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        montantTVA: e.target.value,
                      };
                    });
                  }}
                ></input>
              </label>

              <label>
                Montant T.T.C.(€) :{" "}
                <input
                  id="inputMontantTTCFacture"
                  type="text"
                  defaultValue={jsonResponse.montantTTC}
                  style={{ border: amountPbBorder }}
                  onChange={(e) => {
                    // setInputValue(e.target.value);
                    e.target.value = checkValue(e.target.value);
                    handleAmountPb();
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        montantTTC: e.target.value,
                      };
                    });
                  }}
                ></input>
              </label>
              <input className="btn-get-started" id="sendToAirtable" type="button" value="Envoyer" onClick={handleSendToAirtable} />
            </div>
          ) : (
            <div>
              <p>Votre facture nous a bien été transmise. Energies de Loire vous remercie de votre confiance.</p>

              <a id="backToPortail" className="btn-get-started" href="https://app.energiesdeloire.com/">
                Retourner vers le portail EDL
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DepotFacture;
