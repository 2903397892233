import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
// import Ticket from "./Ticket";

function UserTicketsList({ user, setUser }) {
  const [myTickets, setMyTickets] = useState([]);
  const [ticketStatus, setTicketStatus] = useState("");

  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  // useEffect(() => {
  //   axios
  //     .get("/intranetUser")
  //     .then((res) => {
  //       // res.data.centraleName === ""
  //       //   ? null
  //       //   :
  //       setUserIntranet((prevState) => {
  //         return {
  //           ...prevState,
  //           userEmail: res.data.userEmail,
  //           token: res.data.token,
  //         };
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    if (user.email !== "" && user.token !== "") {
      getMyTickets();
    }
  }, [user]);

  function getMyTickets() {
    // console.log(user);
    axios
      .post(
        baseUrl4200 + "/ticket/userTickets",
        { userEmail: `${user.email}` },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((res) => {
        // res.data.centraleName === ""
        //   ? null
        setMyTickets(res.data.ticketsArray);
        console.log(res.data.ticketsArray);
        myTickets.forEach((ticket) => {
          ticket.showDescription = false;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ticketClickHandler(ticket) {
    if (ticket.showDescription) {
      ticket.showDescription = false;
    } else {
      ticket.showDescription = true;
    }
    setMyTickets([...myTickets]);
  }

  return (
    <div id="containerMyTickets">
      <h2>Mes Tickets</h2>
      {myTickets.length === 0 ? (
        <p>Vous n'avez encore déposé aucun ticket. C'est que tout va bien !</p>
      ) : (
        <div>
          <select
            id="statusSelector"
            onChange={(event) => {
              setTicketStatus(event.target.value);
              // console.log(ticketStatus);
            }}
          >
            <option value="">Tous</option>
            <option value="Todo">Non traités</option>
            <option value="In progress">En cours de traitement</option>
            <option value="Done">Résolu</option>
            <option value="Cancelled">Annulé</option>
            <option value="StandBy">En Stand-by</option>
          </select>
          <ul className="ulTickets">
            {/* {myTickets.map((ticket, index) => {
              <Ticket ticketStatus={ticketStatus} setTicketStatus={setTicketStatus} />;
            })} */}
            {myTickets.map((ticket, index) => {
              //Check Lamaisonjungle2 pour dispatching des State au niveau App, et création des components (ticket.jsx et userTicketsList.jsx)
              //   return (
              //   if (document.getElementById("statusSelector") !== null) {
              return ticketStatus !== "" ? (
                ticket.Status === ticketStatus ? (
                  <div>
                    <li className="ticketList" key={`${ticket}-${index}`} onClick={() => ticketClickHandler(ticket)}>
                      🎫 Nom : {ticket.Name}, Déposé le : {ticket["Date de dépôt"]}, Statut : {ticket.Status}
                    </li>
                    {ticket.showDescription ? (
                      ticket.Attachments ? (
                        <li
                          className="ticketList"
                          key={`${ticket}-${index}-${ticket.Description}`}
                          onClick={() => ticketClickHandler(ticket)}
                        >
                          Description : {ticket.Description},{" "}
                          <a target="_blank" href={ticket.Attachments[0].url}>
                            Télécharger la pièce jointe
                          </a>
                        </li>
                      ) : (
                        <li
                          className="ticketList"
                          key={`${ticket}-${index}-${ticket.Description}`}
                          onClick={() => ticketClickHandler(ticket)}
                        >
                          Description : {ticket.Description}
                        </li>
                      )
                    ) : null}
                  </div>
                ) : null
              ) : (
                <div>
                  <li className="ticketList" key={`${ticket}-${index}`} onClick={() => ticketClickHandler(ticket)}>
                    🎫 Nom : {ticket.Name}, Déposé le : {ticket["Date de dépôt"]}, Statut : {ticket.Status}
                  </li>
                  {ticket.showDescription ? (
                    ticket.Attachments ? (
                      <li
                        className="ticketList"
                        key={`${ticket}-${index}-${ticket.Description}`}
                        onClick={() => ticketClickHandler(ticket)}
                      >
                        Description : {ticket.Description},{" "}
                        <a target="_blank" href={ticket.Attachments[0].url}>
                          Télécharger la pièce-jointe
                        </a>
                      </li>
                    ) : (
                      <li
                        className="ticketList"
                        key={`${ticket}-${index}-${ticket.Description}`}
                        onClick={() => ticketClickHandler(ticket)}
                      >
                        Description : {ticket.Description}
                      </li>
                    )
                  ) : null}{" "}
                </div>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default UserTicketsList;
