// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import EDLLogoFr from "../assets/EDL-logo.png";
import linkedIn_ico from "../assets/linkedin_button.png";
import hamburgerIcon from "../assets/Icon_menu.png";
import LogoutIcon from "@mui/icons-material/Logout";

import "../styles/EDLBanner.css";
import { useEffect, useState } from "react";

const axios = require("axios");
const listeReferents = [
  "awadoux@energiesdeloire.com",
  "it@energiesdeloire.com",
  "gpapion@energiesdeloire.com",
  "dleroueil@energiesdeloire.com",
  "llepoivre@energiesdeloire.com",
  "hpailler@energiesdeloire.com",
  "baubron@energiesdeloire.com",
  "lguidi@energiesdeloire.com",
  "plemarchand@energiesdeloire.com",
];

function EDLBanner({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const [interfacesSection, setInterfacesSection] = useState([]);

  useEffect(() => {
    // Créer un Array pour l'affichage des interfaces par section (interface) de la sorte
    // [
    //   {
    //     service:"BE Technique",
    //     name:"blabla",
    //     id: "azerty123456789",
    //   },
    //   {
    //     service:"Comptabilité",
    //     name:"blibli",
    //     id: "azerty987654321",
    //   },
    // ]
    let finalArr = [];

    if (user.user) {
      user.user.interfaces.forEach((elt, i) => {
        let tmpServiceName = "";
        elt.services.map((s) => {
          if (tmpServiceName === "") {
            tmpServiceName += s;
          } else {
            tmpServiceName += " | " + s;
          }
        });
        let j = finalArr.findIndex((section) => section.service === tmpServiceName);

        if (j > -1) {
          finalArr[j].details.push(elt);
        } else {
          finalArr.push({
            service: tmpServiceName,
            details: [elt],
          });
        }
      });
      console.log("finalArr", finalArr);
      finalArr.sort((a, b) => a["service"].localeCompare(b["service"]));

      setInterfacesSection(finalArr);
    }
  }, [user]);

  const baseUrl = window.location.origin;

  return (
    <div className="EDLbanner">
      {window.innerWidth > 900 ? setResponsiveMenuVisible(true) : null}
      <div className="EDLlogo-EDL-fr-div">
        <a href="https://app.energiesdeloire.com/">
          <img src={EDLLogoFr} alt="EDL-logo" />
        </a>
      </div>
      <div className="EDLmenuNavAndContact">
        <div className="EDLmenuNav">
          {responsiveMenuVisible ? (
            <ul>
              {/* {isConnected ? ( */}
              {user.user !== undefined ? (
                <div style={{ display: "flex" }}>
                  <li>
                    <a href="./">Accueil</a>
                  </li>
                  <div class="dropdown">
                    <li>Mes interfaces</li>
                    <div class="dropdown-content">
                      {user.user !== undefined
                        ? interfacesSection.map((section) => {
                            return (
                              <ul>
                                {
                                  <span style={{ color: "gray" }}>
                                    <em>{section.service}</em>
                                  </span>
                                }
                                {section.details.map((elt) => {
                                  return (
                                    <li>
                                      <a href={elt.url} target="_blank">
                                        {elt.name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            );
                          })
                        : null}
                    </div>
                  </div>

                  <li>
                    <a style={{ display: "flex" }} href={baseUrl + "/api/auth/logout"}>
                      <LogoutIcon />
                    </a>
                  </li>
                  {user.user !== undefined ? (
                    <li>
                      {user.user.firstname} {user.user.lastname}
                    </li>
                  ) : null}
                </div>
              ) : (
                <li>
                  <a href={baseUrl + "/connexion"}>Se connecter</a>
                </li>
              )}
            </ul>
          ) : (
            <a className="hamburgerIcon" onClick={() => setResponsiveMenuVisible(true)}>
              <img src={hamburgerIcon} alt="menu_responsive" />
            </a>
          )}
        </div>
        <div className="EDLheader-link">
          <a href="https://www.linkedin.com/company/energies-de-loire/" target="_blank" rel="noreferrer" title="LinkedIn">
            <img src={linkedIn_ico} alt="LinkedIn" height="15" width="15" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default EDLBanner;
