import APEDL from "../../assets/AP-EDL5.png";
import "../../styles/EDLHome.css";
import edlInstitutionnel from "../../assets/EDL-logo.png";
import edlPortails from "../../assets/partenaire.png";
import edlIntranet from "../../assets/intranet2.png";
import iconConnexion from "../../assets/icon-connexion.png";

import { useState } from "react";
import { useEffect } from "react";

function StandardError({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div
      className="container-Home"
      style={{ "background-image": `url(${APEDL})`, "min-height": "100vh", "background-repeat": "no-repeat", "background-size": "contain" }}
      onClick={() => setResponsiveMenuVisible(false)}>
      <div className="EDL-home-title">
        <p>ENERGIES DE LOIRE ☀️</p>
        <h1>Oups, il semble qu'une erreur soit survenue</h1>
        <h3>Si cela survient de nouveau, merci de contacter it@energiesdeloire.com</h3>
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://app.energiesdeloire.com/connexion">
            <img className="img-edl" src={iconConnexion} alt="app-connexion" />
            <h4>Portail d'Interfaces EDL</h4>
            <p>Lien vers le portail de connexion</p>
          </a>
        </div>
      </div>

      <div className="three-color-separator" style={{ "width": "50%", "margin-top": "30px", "margin-bottom": "30px" }}></div>

      <h2>Nos portails</h2>
      <div className="home-button">
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://www.energiesdeloire.com">
            <img className="img-edl" src={edlInstitutionnel} alt="edl-institutionnel" />
            <h4>energiesdeloire.com</h4>
            <p>Lien vers le site institutionnel d'EDL.</p>
          </a>
        </div>
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://portail.energiesdeloire.com">
            <img src={edlPortails} alt="edl-portails" />
            <h4> Portail partenaires</h4>
            <p>Lien vers les portails partenaire / client.</p>
          </a>
        </div>
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://intranet.energiesdeloire.com">
            <img src={edlIntranet} alt="edl-intranet" />
            <h4>Intranet</h4>
            <p>Lien vers l'intranet EDL.</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default StandardError;
