import { Link } from "react-router-dom";
import "../styles/Footer.css";
import linkedIn_ico from "../assets/linkedin_button.png";

function Footer({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div onClick={() => setResponsiveMenuVisible(false)}>
      <footer>
        <div className="footer">
          <p>
            2022 - Energies de Loire - Tous droits réservés -{" "}
            <a href="https://energiesdeloire.com/" className="lien">
              Energies de Loire
            </a>
          </p>
          <div className="footer-link">
            <a
              href="https://www.linkedin.com/company/energies-de-loire/"
              target="_blank"
              rel="noreferrer"
              title="LinkedIn"
            >
              <img src={linkedIn_ico} alt="LinkedIn" height={15} width={15} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
