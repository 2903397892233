import "../../styles/Faq.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Markdown from "markdown-to-jsx";
import CreateCustomButton from "./CreateCustomButton";

const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl.replace("3000", "4200");
if (baseUrl.includes("app-preprod")) {
  baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
} else if (baseUrl.includes("app")) {
  baseUrl4200 = baseUrl4200.replace("app", "api");
}
function FaqContent({ selectedRecord }) {
  // const [hasIFrame, setHasIFrame] = useState(false);
  // if (selectedRecord.Text.indexOf("<iframe") !== -1) {
  //   setHasIFrame(true);
  // }

  // useEffect(() => {
  //   if (hasIFrame) {
  //     const timeOut = setTimeout(() => {
  //       if (document.getElementsByTagName("iframe").length > 0) {
  //         document.getElementsByTagName("iframe").forEach((elt) => {
  //           elt.setAttribute("style", "margin: 10px calc(50% - 340px); position: relative; display: inline-block; width: 640px;");
  //         });
  //       }
  //     }, 3000);
  //   }
  // }, [hasIFrame]);

  return (
    <div className="faq-detail">
      <Markdown>{selectedRecord.Text}</Markdown>
    </div>
  );
}
export default FaqContent;
