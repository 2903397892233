// import backgroundEDLHome from "../assets/backgroundEDLIntranet2-3.png";
import uploadImg from "../assets/upload.png";
import "../styles/Planning.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import sallecande from "../assets/sallecande.pdf";
import sallelabaule from "../assets/sallelabaule.pdf";
import TableWithSelect from "./Standard/TableWithSelect";

// import { ReactComponent as GuerandeRDC } from "../assets/RDC_Guerande-salle1.svg";

function ReuCalendar({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, background, user, setUser }) {
  //Définition des useState

  const [cityOptions, setCityOptions] = useState([]); //variable stockant les villes possible (Candé & Guérande)
  const [candeRoomOptions, setCandeRoomOptions] = useState([]); //Variable stockant la salle choisie (Candé)
  const [guerandeRoomOptions, setGuerandeRoomOptions] = useState([]); //idem Guérande
  const [candeRecords, setCandeRecords] = useState([]); //Variable stockant l'ensemble des réservations faite à Candé
  const [guerandeRecords, setGuerandeRecords] = useState([]); //idem Guérande
  const [recToDisplay, setRecToDisplay] = useState({
    //Si une réservation doit être affichée on se sert de cette variable pour stocker les infos
    name: "",
    notes: "",
    attachment: "",
    city: "",
    heureDebut: "",
    heureFin: "",
    room: "",
    participants: [],
    id: "",
  });
  const [search, setSearch] = useState("");
  const [divStyles, setDivStyles] = useState({
    addResaDiv: { height: "auto", overflow: "unset", reduced: false },
    lastResaDiv: { height: "auto", overflow: "unset", reduced: false },
  });
  const [showMembersTable, setShowMembersTable] = useState(false);
  const [showMembersTableToUpdate, setShowMembersTableToUpdate] = useState(false);

  const [sendReq, setSendReq] = useState(false); //Variable de type Booléen qui permet de n'envoyer une requête qu'à partir de certaines conditions
  const [modifyResaDivDisplay, setModifyResaDivDisplay] = useState("none"); //Variable qui permet d'afficher ou non la popup de modification d'une résa (none || flex)
  const [iframeSrc, setIframeSrc] = useState(""); //Variable qui contient le lien de l'Iframe Airtable à afficher (planning)
  const [cityChosen, setCityChosen] = useState(""); //Variable stockant la ville choisie

  const [recordOptions, setRecordOptions] = useState({ records: [], baseId: "", tableName: "", viewName: "" });
  const [selectedLinkedRecordToAdd, setSelectedLinkedRecordToAdd] = useState({ records: [], baseId: "", tableName: "", viewName: "" });
  const [selectedLinkedRecordToUpdate, setSelectedLinkedRecordToUpdate] = useState({
    records: [],
    baseId: "",
    tableName: "",
    viewName: "",
  });

  const [loading, setLoading] = useState(0);

  //Définition de la racine de l'URL du serveur API => https://api.energiesdeloire.com || http://localhost:4200
  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  console.log("baseUrl4200 : " + baseUrl4200);

  //UseEffect qui se déclenche lorsque [user] est modifié (voir useEffect ci-dessus). On requête alors api pour obtenir les champs possibles de la table "Calendar Salles"
  //Dans la section headers de la requête on passe Authorization : 'Bearer TOKEN' pour permettre à l'API de nous authentifier et donc d'accepter la requête.
  useEffect(() => {
    if (user.token) {
      axios
        .post(
          baseUrl4200 + "/airtableReq/getItSelectFields",
          {
            tableAndFields: [
              { tableName: "Calendar Salles", fieldName: "City" },
              { tableName: "Calendar Salles", fieldName: "Room - Candé" },
              { tableName: "Calendar Salles", fieldName: "Room - Guérande" },
            ],
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 201) {
            console.log(res);
            let cityIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "City");
            setCityOptions(res.data.fieldsAndOptions[cityIndex].optionArray);

            let candeRoomIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Room - Candé");
            setCandeRoomOptions(res.data.fieldsAndOptions[candeRoomIndex].optionArray);

            let guerandeRoomIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Room - Guérande");
            setGuerandeRoomOptions(res.data.fieldsAndOptions[guerandeRoomIndex].optionArray);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  //UseEffect qui se déclenche lorsque [user] est modifié.
  useEffect(() => {
    if (user.token) {
      getRecords();
    }
  }, [user]);

  //getRecords() envoie une requête vers API pour requêter Airtable et obtenir les records, ie les résa faite pour Candé et Guérande.
  function getRecords() {
    if (user.token) {
      axios
        .post(
          // baseUrl4200 + "/airtableReq/getPlanningRecords",
          baseUrl4200 + "/airtableReq/getFullCalendarRecords",
          {
            tableAndViews: [
              { tableName: "Calendar Salles", view: "Réservations - Candé" },
              { tableName: "Calendar Salles", view: "Réservations - Guérande" },
            ],
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 201) {
            // console.log(res);
            let CandInd = res.data.datas.findIndex((header) => header.view === "Réservations - Candé");
            setCandeRecords(res.data.datas[CandInd].datas);
            let GuerInd = res.data.datas.findIndex((header) => header.view === "Réservations - Guérande");
            setGuerandeRecords(res.data.datas[GuerInd].datas);
            // setOptions(res.data.fieldsAndOptions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  //calendarIFrame = Component React. Cette fonction est en fait un compenent interne au component ReuCalendar. Ce composant renvoie un iframe avec le bon lien (src)
  function calendarIFrame(src) {
    return (
      <div>
        <iframe
          id="airtableIframe"
          className="airtable-embed"
          src={src}
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="533"
          style={({ background: "transparent" }, { border: "1px solid #ccc" }, { margin: "0px" })}
        />
      </div>
    );
  }

  //Idem, il s'agit là d'un composant interne
  function time(id, ini, onChangeFn) {
    const options = [
      "--:--",
      "07:00",
      "07:15",
      "07:30",
      "07:45",
      "08:00",
      "08:15",
      "08:30",
      "08:45",
      "09:00",
      "09:15",
      "09:30",
      "09:45",
      "10:00",
      "10:15",
      "10:30",
      "10:45",
      "11:00",
      "11:15",
      "11:30",
      "11:45",
      "12:00",
      "12:15",
      "12:30",
      "12:45",
      "13:00",
      "13:15",
      "13:30",
      "13:45",
      "14:00",
      "14:15",
      "14:30",
      "14:45",
      "15:00",
      "15:15",
      "15:30",
      "15:45",
      "16:00",
      "16:15",
      "16:30",
      "16:45",
      "17:00",
      "17:15",
      "17:30",
      "17:45",
      "18:00",
      "18:15",
      "18:30",
      "18:45",
      "19:00",
    ];
    return (
      <select id={id} name={id} className="timeSelect" value={ini} onChange={onChangeFn}>
        {options.map((opt, i) => {
          return (
            <option key={i} value={opt}>
              {opt}
            </option>
          );
        })}
      </select>
    );
  }

  //Fonction qui vient modifier l'Iframe à afficher (planning)
  const handleChangeCity = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setCityChosen(e.target.value);
    if (e.target.value === "Candé") {
      setIframeSrc("https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on");
    } else {
      setIframeSrc("https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on");
    }
    setSelectedLinkedRecordToAdd({ records: [], baseId: "", tableName: "", viewName: "" });
  };

  //Fonction qui vient vérifier si l'ensemble des champs permettant d'ajouter une réservation sont renseignés. S'ils sont renseignés,
  //on envoie la requête vers le serveur api puis airtable oour enregistrement. Ensuite on réaffiche le nouvel Iframe (nécessité de le recharger)
  const handleAddResa = (e) => {
    e.preventDefault();
    setSendReq(false);
    if (
      document.getElementById("submitFormDateInput").value !== "" &&
      document.getElementById("addStartTime").value !== "" &&
      document.getElementById("addEndTime").value !== "" &&
      document.getElementById("city").value !== "" &&
      document.getElementById("room").value !== "" &&
      document.getElementById("submitFormNameInput").value !== ""
    ) {
      document.getElementById("addResa-btn-get-started").style.opacity = "0";
      let linkedMembers = [];
      if (selectedLinkedRecordToAdd.records.length > 0) {
        selectedLinkedRecordToAdd.records.forEach((elt) => {
          linkedMembers.push(elt.id);
        });
      }

      if (user.token) {
        let requestTimeOut = setTimeout(() => {
          document.getElementById("addResa-btn-get-started").style.opacity = "1";
          return () => clearTimeout(requestTimeOut);
        }, 3000);
        setSendReq(true);

        // if (sendReq) {
        axios
          .post(
            baseUrl4200 + "/airtableReq/addPlanningResa",
            {
              recToAdd: {
                city: document.getElementById("city").value,
                room: document.getElementById("room").value,
                day: document.getElementById("submitFormDateInput").value,
                addStartTime: document.getElementById("addStartTime").value,
                addEndTime: document.getElementById("addEndTime").value,
                name: document.getElementById("submitFormNameInput").value,
                notes: document.getElementById("addNotes").value,
                participants: linkedMembers,
              },
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            if (res.status === 201) {
              console.log(res);
              setSendReq(false);
              getRecords();
              if (document.getElementById("city").value === "Candé") {
                // setCityChosen("Candé");
                document.getElementById("airtableIframe").src = "";
                document.getElementById("airtableIframe").src =
                  "https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on";
                setIframeSrc("https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on");
              } else {
                // setCityChosen("Guérande");
                document.getElementById("airtableIframe").src = "";
                document.getElementById("airtableIframe").src =
                  "https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on";
                setIframeSrc("https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on");
              }
              document.getElementById("room").value = "";
              document.getElementById("submitFormDateInput").value = "";
              document.getElementById("addStartTime").value = "";
              document.getElementById("addEndTime").value = "";
              document.getElementById("submitFormNameInput").value = "";
              document.getElementById("addNotes").value = "";
              setSelectedLinkedRecordToAdd({ records: [], baseId: "", tableName: "", viewName: "" });
            }
          })
          .catch((err) => {
            console.log(err);
            if (document.getElementById("city").value === "Candé") {
              // setCityChosen("Candé");
              document.getElementById("airtableIframe").src = "";
              document.getElementById("airtableIframe").src =
                "https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on";
              setIframeSrc("https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on");
            } else {
              // setCityChosen("Guérande");
              document.getElementById("airtableIframe").src = "";
              document.getElementById("airtableIframe").src =
                "https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on";
              setIframeSrc("https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on");
            }
            setSendReq(false);
            getRecords();
            setSelectedLinkedRecordToAdd({ records: [], baseId: "", tableName: "", viewName: "" });
          });
        // }
      }
    } else {
      alert("Tous les champs n'ont pas été renseignés.");
    }
  };

  //Lorsque l'on clique sur réservation existante, on affiche la popup qui permet de venir consulter et/ou modifier cette réservation
  function handleResaClick(rec, e) {
    e.preventDefault();
    if (rec) {
      console.log(rec);
      setModifyResaDivDisplay("flex");
      setRecToDisplay(rec);
      if (rec.participants.length > 0) {
        let tmpParticipants = [];
        let counter = 0;
        rec.participants.forEach((id) => {
          console.log("id", id);
          if (user.token) {
            setLoading(loading + 1);
            axios
              .post(
                baseUrl4200 + "/airtableReq/getRecord",
                {
                  baseId: "appdcpGp5m9QJ9ye4",
                  tableName: "Utilisateurs EDL",
                  // recId: record.record[fieldsOptions[j].name],
                  recId: id,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                  },
                  mode: "cors",
                }
              )
              .then((res) => {
                setLoading(loading - 1);
                if (res.status === 200) {
                  counter++;
                  console.log(res);
                  tmpParticipants.push(res.data.record.fields);
                  tmpParticipants[tmpParticipants.length - 1].id = res.data.record.id;
                  // res.data.records.forEach((rec, index) => {
                  //   // setRecordOptions(res.data.records);
                  // });
                  // setRecordOptions({ records: res.data.records, baseId: baseId, tableName: tableName, viewName: viewName });
                  console.log(res.data);
                  if (tmpParticipants.length === counter) {
                    setSelectedLinkedRecordToUpdate({
                      records: tmpParticipants,
                      baseId: "appdcpGp5m9QJ9ye4",
                      tableName: "Utilisateurs EDL",
                      viewName: null,
                    });
                  }
                }
              })
              .catch((err) => {
                setLoading(loading - 1);
                console.log(err);
              });
          }
        });
      } else {
        setSelectedLinkedRecordToUpdate({
          records: [],
          baseId: "appdcpGp5m9QJ9ye4",
          tableName: "Utilisateurs EDL",
          viewName: null,
        });
      }
    } else {
      console.log(rec);
      setModifyResaDivDisplay("none");
      setRecToDisplay({ name: "", notes: "", attachment: "", city: "", heureDebut: "", heureFin: "", room: "", id: "", participants: [] });
      setSelectedLinkedRecordToUpdate({
        records: [],
        baseId: "",
        tableName: "",
        viewName: "",
      });
    }
  }

  //Fonction qui permet de modifier une réservation (sans avoir à valider)
  const handleChangeResa = (e) => {
    e.preventDefault();
    setModifyResaDivDisplay("none");
    // setRecToDisplay({});
    setSendReq(false);
    // console.log(e.target);
    // console.log(e.target.value);
    // const { name, value } = e.target;
    // setRecToDisplay((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));
    //sûrement possibilité de refactoriser cette section de code ?
    if (recToDisplay.city === "Candé") {
      let ind = candeRecords.findIndex((header) => header.id === recToDisplay.id);
      let newArr = [...candeRecords];
      newArr[ind] = recToDisplay;
      setCandeRecords(newArr);
      let requestTimeOut = setTimeout(() => {
        // setSendReq(true);

        // if (sendReq) {
        axios
          .post(
            baseUrl4200 + "/airtableReq/modifyPlanningResa",
            {
              recToModify: recToDisplay,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            setSendReq(false);
            if (res.status === 201) {
              console.log(res);
              // setOptions(res.data.fieldsAndOptions);
              if (recToDisplay.city === "Candé") {
                // setCityChosen("Candé");
                document.getElementById("airtableIframe").src = "";
                document.getElementById("airtableIframe").src =
                  "https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on";
                setIframeSrc("https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on");
              } else {
                // setCityChosen("Guérande");
                document.getElementById("airtableIframe").src = "";
                document.getElementById("airtableIframe").src =
                  "https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on";
                setIframeSrc("https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on");
              }
            }
            setRecToDisplay({
              name: "",
              notes: "",
              attachment: "",
              city: "",
              heureDebut: "",
              heureFin: "",
              room: "",
              participants: [],
              id: "",
            });
          })
          .catch((err) => {
            setRecToDisplay({
              name: "",
              notes: "",
              attachment: "",
              city: "",
              heureDebut: "",
              heureFin: "",
              room: "",
              participants: [],
              id: "",
            });
            console.log(err);
            setSendReq(false);
          });
        // }
        return () => clearTimeout(requestTimeOut);
      }, 200);
      // return () => clearTimeout(requestTimeOut);
    }
    if (recToDisplay.city === "Guérande") {
      let ind = guerandeRecords.findIndex((header) => header.id === recToDisplay.id);
      let newArr = [...guerandeRecords];
      newArr[ind] = recToDisplay;
      setGuerandeRecords(newArr);
      let requestTimeOut = setTimeout(() => {
        setSendReq(true);
        axios
          .post(
            baseUrl4200 + "/airtableReq/modifyPlanningResa",
            {
              recToModify: recToDisplay,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            setSendReq(false);
            if (res.status === 201) {
              console.log(res);
              // setOptions(res.data.fieldsAndOptions);
              if (recToDisplay.city === "Candé") {
                document.getElementById("airtableIframe").src = "";
                document.getElementById("airtableIframe").src =
                  "https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on";
                setIframeSrc("https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on");
              } else {
                // setCityChosen("Guérande");
                document.getElementById("airtableIframe").src = "";
                document.getElementById("airtableIframe").src =
                  "https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on";
                setIframeSrc("https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on");
              }
            }
            setRecToDisplay({
              name: "",
              notes: "",
              attachment: "",
              city: "",
              heureDebut: "",
              heureFin: "",
              room: "",
              participants: [],
              id: "",
            });
          })
          .catch((err) => {
            console.log(err);
            setRecToDisplay({
              name: "",
              notes: "",
              attachment: "",
              city: "",
              heureDebut: "",
              heureFin: "",
              room: "",
              participants: [],
              id: "",
            });
            setSendReq(false);
          });
        return () => clearTimeout(requestTimeOut);
      }, 2000);
      // return () => clearTimeout(requestTimeOut);
    }
  };

  //Fonction qui permet de supprimer une réservation
  const handleDeleteResa = (e) => {
    e.preventDefault();
    axios
      .post(
        baseUrl4200 + "/airtableReq/deletePlanningResa",
        {
          recToDelete: recToDisplay,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((res) => {
        if (res.status === 201) {
          console.log(res);
          // setOptions(res.data.fieldsAndOptions);
          if (recToDisplay.city === "Candé") {
            // setCityChosen("Candé");
            document.getElementById("airtableIframe").src = "";
            document.getElementById("airtableIframe").src =
              "https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on";
            setIframeSrc("https://airtable.com/embed/shrQQbbwdM8ybS5X7?backgroundColor=teal&viewControls=on");
          } else {
            // setCityChosen("Guérande");
            document.getElementById("airtableIframe").src = "";
            document.getElementById("airtableIframe").src =
              "https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on";
            setIframeSrc("https://airtable.com/embed/shrW6aZ6GNoFpntxw?backgroundColor=teal&viewControls=on");
          }
          setModifyResaDivDisplay("none");
          setRecToDisplay({
            name: "",
            notes: "",
            attachment: "",
            city: "",
            heureDebut: "",
            heureFin: "",
            room: "",
            participants: [],
            id: "",
          });
          getRecords();
        }
      })
      .catch((err) => {
        console.log(err);
        setModifyResaDivDisplay("none");
        setRecToDisplay({
          name: "",
          notes: "",
          attachment: "",
          city: "",
          heureDebut: "",
          heureFin: "",
          room: "",
          participants: [],
          id: "",
        });
        getRecords();
      });
  };

  //Ce que le composant ReuCalendar renvoie
  const handleUpdateRecToDisplay = (e) => {
    e.preventDefault();
    console.log(e.target);
    console.log(e.target.value);
    console.log(recToDisplay);
    const { name, value } = e.target;
    setRecToDisplay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    //modifier la variable recToDisply grâce à setRecToDisplay()
  };

  const handleInputOnChange = (e) => {
    e.preventDefault();
    console.log("Typing...");
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const handleReduceDiv = (e) => {
    e.preventDefault();
    let tmpDivStyles = divStyles;
    if (!divStyles[e.target.id].reduced) {
      tmpDivStyles[e.target.id].height = "50px";
      tmpDivStyles[e.target.id].overflow = "hidden";
      tmpDivStyles[e.target.id].reduced = true;
    } else {
      tmpDivStyles[e.target.id].height = "auto";
      tmpDivStyles[e.target.id].overflow = "unset";
      tmpDivStyles[e.target.id].reduced = false;
    }
    setDivStyles(tmpDivStyles);
  };

  const handleAddMembers = (e) => {
    e.preventDefault();
    showMembersTable ? setShowMembersTable(false) : setShowMembersTable(true);
  };
  const handleUpdateMembers = (e) => {
    e.preventDefault();
    showMembersTableToUpdate ? setShowMembersTableToUpdate(false) : setShowMembersTableToUpdate(true);
  };

  useEffect(() => {
    //Met à jour le recToDisplay (la réservation que l'on est en train de modifier)
    let tmpParticipants = [];
    selectedLinkedRecordToUpdate.records.forEach((elt) => {
      elt.id ? tmpParticipants.push(elt.id) : tmpParticipants.push(elt);
    });
    setRecToDisplay((prevState) => ({
      ...prevState,
      ["participants"]: tmpParticipants,
    }));

    console.log(tmpParticipants);
    tmpParticipants.forEach((id) => {
      // let id = elt.id;
      console.log("id", id);
      if (user.token) {
        setLoading(loading + 1);
        axios
          .post(
            baseUrl4200 + "/airtableReq/getRecord",
            {
              baseId: "appdcpGp5m9QJ9ye4",
              tableName: "Utilisateurs EDL",
              // recId: record.record[fieldsOptions[j].name],
              recId: id,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            setLoading(loading - 1);
            if (res.status === 200) {
              console.log(res);
              // res.data.records.forEach((rec, index) => {
              //   // setRecordOptions(res.data.records);
              // });
              // setRecordOptions({ records: res.data.records, baseId: baseId, tableName: tableName, viewName: viewName });
              console.log(res.data);
              document.getElementById(id).textContent = res.data.record.fields.Name;
            }
          })
          .catch((err) => {
            setLoading(loading - 1);
            console.log(err);
          });
      }
    });

    console.log(selectedLinkedRecordToUpdate);
  }, [selectedLinkedRecordToUpdate]);

  useEffect(() => {
    console.log(selectedLinkedRecordToAdd);
  }, [selectedLinkedRecordToAdd]);

  return (
    <div>
      {loading > 0 ? (
        <div>
          <div id="spinner-modal"></div>
          {/* {longLoading ? (
            <p id="loading-long-text" style={{ opacity: longLoadingOpacity }}>
              Analyzing datas...
            </p>
          ) : null} */}
        </div>
      ) : null}
      <div className="ticket-container-Home" onClick={() => setResponsiveMenuVisible(false)} style={{ background: `url(${background})` }}>
        <div className="ticket-background-div">
          {/* <img className="ticket-background-img" src={backgroundEDLHome} alt="background-EDL" /> */}
        </div>
        <div className="ticket-blog-home-title">
          <div className="titles-tickets">
            <h1>RESERVER UNE SALLE DE REUNION</h1>
            {/* <h2>Ici vous pouvez soumettre vos suggestions et/ou rapporter un bug rencontré.</h2> */}
          </div>
          <div className="planning-submitForm" id="planning-submitForm">
            <h2>Bienvenue {user.userEmail} !</h2>
            <div className="ticket-subDivForm1">
              <div className="subDivPlanning2-100">
                <p>Candé ou Guérande ?</p>
                <select className="ticket-inputForm" id="city" onChange={handleChangeCity}>
                  <option value="">Sélectionner</option>
                  {cityOptions.length > 0
                    ? cityOptions.map((opt, i) => {
                        return (
                          <option key={i} value={opt}>
                            {opt}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>
            </div>
          </div>
        </div>
        {cityChosen === "" ? <p className="lieu">Choisissez un lieu !</p> : cityChosen === "Candé" ? displayCande() : displayGuerande()}
      </div>
    </div>
  );

  //Les fonctions displayCande et displayGuerande peuvent certainement être refactorisés en une seule fonction. Réflechir à comment le faire
  // Chacune de ces fonctions est un sous-composant de ReuCalendar égalment
  function displayCande() {
    return (
      <div className="planning">
        <div className="subDivPlanning1">
          <h3 style={{ "margin-top": "0px" }}>Planning de Candé</h3>
          {calendarIFrame(iframeSrc)}
          <div
            className="big-grey-div"
            style={{ display: modifyResaDivDisplay }}
            onClick={(e) => {
              handleResaClick(null, e);
            }}
          ></div>
          <div className="modify-resa-div-pl" style={{ display: modifyResaDivDisplay }}>
            <h3>Modifier une réservation</h3>
            <label htmlFor="title">Titre : </label>
            <input name="title" value={recToDisplay.title} onChange={handleUpdateRecToDisplay}></input>
            {/* <label htmlFor="room">Salle : </label>
            <input name="room" value={recToDisplay.room} onChange={handleChangeResa}></input> */}
            <label htmlFor="room">Salle : </label>
            <select id="modify-room" name="room" value={recToDisplay.room} onChange={handleUpdateRecToDisplay}>
              <option value="">Sélectionner</option>
              {candeRoomOptions.length > 0
                ? candeRoomOptions.map((opt, i) => {
                    return (
                      <option key={i} value={opt}>
                        {opt}
                      </option>
                    );
                  })
                : null}
            </select>
            <label htmlFor="day">Date : </label>
            <input name="day" type={"date"} value={recToDisplay.day} onChange={handleUpdateRecToDisplay}></input>
            <label htmlFor="heureDebut">Heure de début : </label>
            {time("heureDebut", recToDisplay.heureDebut, handleUpdateRecToDisplay)}
            <label htmlFor="heureFin">Heure de fin : </label>
            {time("heureFin", recToDisplay.heureFin, handleUpdateRecToDisplay)}
            <label htmlFor="addParticipants">Ajouter des participants : </label>
            <input id="uPdateParticipants" type="button" value="Ajouter des participants" onClick={handleUpdateMembers} />
            {showMembersTableToUpdate ? (
              <div>
                <div className="big-grey-div" onClick={handleUpdateMembers}></div>
                <div className="add-members-div">
                  <h3>Choisir un membre</h3>
                  <TableWithSelect
                    baseId={"appdcpGp5m9QJ9ye4"}
                    tableName={"Utilisateurs EDL"}
                    viewName={null}
                    recordOptions={recordOptions}
                    setRecordOptions={setRecordOptions}
                    selectedRecord={selectedLinkedRecordToUpdate}
                    setSelectedRecord={setSelectedLinkedRecordToUpdate}
                    user={user}
                    setUser={setUser}
                    displayFields={false}
                    multipleRecordsSelect={true}
                    loading={loading}
                    setLoading={setLoading}
                  />{" "}
                </div>
              </div>
            ) : null}
            {recToDisplay.participants !== undefined ? (
              recToDisplay.participants.length > 0 ? (
                <ul>
                  {recToDisplay.participants.map((rec, ind) => {
                    return <li id={rec}>{rec}</li>;
                  })}
                </ul>
              ) : (
                <p>Aucun participant</p>
              )
            ) : (
              <p>Aucun participant</p>
            )}
            <label htmlFor="notes">Notes : </label>
            <textarea
              name="notes"
              placeholder="Ecrivez vos notes ici..."
              value={recToDisplay.notes}
              onChange={handleUpdateRecToDisplay}
              style={{
                margin: "0px 5% 10px",
                border: "1px solid gray",
                height: "65px",
                width: "90%",
                "min-width": "90%",
                "max-width": "90%",
                "max-height": "150px",
              }}
            ></textarea>

            <div className="btn-group">
              <input
                name="modif-resa"
                type="button"
                onClick={handleChangeResa}
                className="btn-get-started"
                value="Confirmer les modifications"
                id="modif-resa"
                style={{ "background-color": "#3fa61a" }}
              ></input>
              <input
                name="delete-resa"
                type="button"
                onClick={handleDeleteResa}
                className="btn-get-started"
                value="Supprimer la réservation"
                id="delete-resa"
                style={{ "background-color": "#f42424" }}
              ></input>
            </div>
          </div>
        </div>
        <div className="subDivPlanning1-20">
          <div className="subDivPlanning2-100" style={{ height: divStyles.addResaDiv.height, overflow: divStyles.addResaDiv.overflow }}>
            <h3 id="addResaDiv" onClick={handleReduceDiv} style={{ cursor: "pointer" }}>
              Ajouter une réservation {!divStyles["addResaDiv"].reduced ? "-" : "+"}
            </h3>
            <div className="voirsalle">
              <p>Quelle salle ?</p>
              <a className="vuesalle" href={sallecande} target="_blank">
                Voir les salles
              </a>
            </div>
            <select className="ticket-inputForm" id="room">
              <option value="">Sélectionner</option>
              {candeRoomOptions.length > 0
                ? candeRoomOptions.map((opt, i) => {
                    return (
                      <option key={i} value={opt}>
                        {opt}
                      </option>
                    );
                  })
                : null}
            </select>

            <label htmlFor="title">Nom de la réunion</label>
            <input type="text" name="title" id="submitFormNameInput" />
            <p style={{ "margin-top": "10px", "margin-right": "10px" }}>
              Date
              <input id="submitFormDateInput" type="date"></input>
            </p>
            <p>
              De {time("addStartTime", null, null)} à {time("addEndTime", null, null)}
            </p>
            <label htmlFor="addParticipants">Ajouter des participants : </label>
            <input id="addParticipants" type="button" value="Ajouter des participants" onClick={handleAddMembers} />
            {showMembersTable ? (
              <div>
                <div className="big-grey-div" onClick={handleAddMembers}></div>
                <div className="add-members-div">
                  <h3>Choisir un membre</h3>
                  <TableWithSelect
                    baseId={"appdcpGp5m9QJ9ye4"}
                    tableName={"Utilisateurs EDL"}
                    viewName={null}
                    recordOptions={recordOptions}
                    setRecordOptions={setRecordOptions}
                    selectedRecord={selectedLinkedRecordToAdd}
                    setSelectedRecord={setSelectedLinkedRecordToAdd}
                    user={user}
                    setUser={setUser}
                    displayFields={false}
                    multipleRecordsSelect={true}
                    loading={loading}
                    setLoading={setLoading}
                  />{" "}
                </div>
              </div>
            ) : null}
            {selectedLinkedRecordToAdd.records.length > 0 ? (
              <ul>
                {selectedLinkedRecordToAdd.records.map((rec, ind) => {
                  return <li id={rec.id}>{rec["Name"]}</li>;
                })}
              </ul>
            ) : (
              <p>Aucun participant</p>
            )}
            <label htmlFor="notes">Notes : </label>
            <textarea
              name="notes"
              placeholder="Ecrivez vos notes ici..."
              onChange={handleUpdateRecToDisplay}
              id="addNotes"
              style={{
                margin: "0px 5% 10px",
                border: "1px solid gray",
                height: "65px",
                width: "90%",
                "min-width": "90%",
                "max-width": "90%",
                "max-height": "150px",
              }}
            ></textarea>
            <input className="btn-get-started" id="addResa-btn-get-started" type="button" value="Ajouter" onClick={handleAddResa} />
          </div>
          <div className="subDivPlanning2-100" style={{ height: divStyles.lastResaDiv.height, overflow: divStyles.lastResaDiv.overflow }}>
            <h3 id="lastResaDiv" onClick={handleReduceDiv} style={{ cursor: "pointer" }}>
              Dernières reservations faites {!divStyles["lastResaDiv"].reduced ? "-" : "+"}
            </h3>
            <div className="div-select" style={{ width: "100%" }}>
              <input className="select" name="select" onInput={handleInputOnChange} type="text" placeholder="Rechercher..."></input>
            </div>
            <div className="subDivPlanning2-ul">
              {candeRecords.length > 0 ? (
                candeRecords.map((rec, i) => {
                  return search !== "" ? (
                    rec.room.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    rec.day.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    rec.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ? (
                      <ul
                        key={i}
                        id={rec.id}
                        onClick={(e) => {
                          handleResaClick(rec, e);
                        }}
                      >
                        <li>
                          {" "}
                          Salle {rec.room} : {rec.title}
                        </li>
                        <li>Date: {rec.day}</li>
                        <li>
                          {rec.heureDebut} - {rec.heureFin}{" "}
                        </li>
                      </ul>
                    ) : null
                  ) : (
                    <ul
                      key={i}
                      id={rec.id}
                      onClick={(e) => {
                        handleResaClick(rec, e);
                      }}
                    >
                      <li>
                        {" "}
                        Salle {rec.room} : {rec.title}
                      </li>
                      <li>Date: {rec.day}</li>
                      <li>
                        {rec.heureDebut} - {rec.heureFin}{" "}
                      </li>
                    </ul>
                  );
                })
              ) : (
                <p>Pas de réunion prévue.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  function displayGuerande() {
    return (
      <div className="planning">
        <div className="subDivPlanning1">
          <h3 style={{ "margin-top": "0px" }}>Planning de Guérande</h3>
          {calendarIFrame(iframeSrc)}
          <div
            className="big-grey-div"
            style={{ display: modifyResaDivDisplay }}
            onClick={(e) => {
              handleResaClick(null, e);
            }}
          ></div>
          <div className="modify-resa-div-pl" style={{ display: modifyResaDivDisplay }}>
            <h3>Modifier une réservation</h3>
            <label htmlFor="title">Titre : </label>
            <input name="title" value={recToDisplay.title} onChange={handleUpdateRecToDisplay}></input>
            <label htmlFor="room">Salle : </label>
            <select id="modify-room" name="room" value={recToDisplay.room} onChange={handleUpdateRecToDisplay}>
              <option value="">Sélectionner</option>
              {guerandeRoomOptions.length > 0
                ? guerandeRoomOptions.map((opt, i) => {
                    return (
                      <option key={i} value={opt}>
                        {opt}
                      </option>
                    );
                  })
                : null}
            </select>
            <label htmlFor="day">Date : </label>
            <input name="day" type={"date"} value={recToDisplay.day} onChange={handleUpdateRecToDisplay}></input>
            <label htmlFor="heureDebut">Heure de début : </label>
            {time("heureDebut", recToDisplay.heureDebut, handleUpdateRecToDisplay)}
            <label htmlFor="heureFin">Heure de fin : </label>
            {time("heureFin", recToDisplay.heureFin, handleUpdateRecToDisplay)}
            <label htmlFor="addParticipants">Ajouter des participants : </label>
            <input id="uPdateParticipants" type="button" value="Ajouter des participants" onClick={handleUpdateMembers} />
            {showMembersTableToUpdate ? (
              <div>
                <div className="big-grey-div" onClick={handleUpdateMembers}></div>
                <div className="add-members-div">
                  <h3>Choisir un membre</h3>
                  <TableWithSelect
                    baseId={"appdcpGp5m9QJ9ye4"}
                    tableName={"Utilisateurs EDL"}
                    viewName={null}
                    recordOptions={recordOptions}
                    setRecordOptions={setRecordOptions}
                    selectedRecord={selectedLinkedRecordToUpdate}
                    setSelectedRecord={setSelectedLinkedRecordToUpdate}
                    user={user}
                    setUser={setUser}
                    displayFields={false}
                    multipleRecordsSelect={true}
                    loading={loading}
                    setLoading={setLoading}
                  />{" "}
                </div>
              </div>
            ) : null}
            {recToDisplay.participants !== undefined ? (
              recToDisplay.participants.length > 0 ? (
                <ul>
                  {recToDisplay.participants.map((rec, ind) => {
                    return <li id={rec}>{rec}</li>;
                  })}
                </ul>
              ) : (
                <p>Aucun participant</p>
              )
            ) : (
              <p>Aucun participant</p>
            )}
            <label htmlFor="notes">Notes : </label>
            <textarea
              name="notes"
              placeholder="Ecrivez vos notes ici..."
              value={recToDisplay.notes}
              onChange={handleUpdateRecToDisplay}
              style={{
                margin: "0px 5% 10px",
                border: "1px solid gray",
                height: "65px",
                width: "90%",
                "min-width": "90%",
                "max-width": "90%",
                "max-height": "150px",
              }}
            ></textarea>
            <div className="btn-group">
              <input
                name="modif-resa"
                type="button"
                onClick={handleChangeResa}
                className="btn-get-started"
                value="Confirmer les modifications"
                id="modif-resa"
              ></input>
              <input
                name="delete-resa"
                type="button"
                onClick={handleDeleteResa}
                className="btn-get-started"
                value="Supprimer la réservation"
                id="delete-resa"
              ></input>
            </div>
          </div>
        </div>
        <div className="subDivPlanning1-20">
          <div className="subDivPlanning2-100" style={{ height: divStyles.addResaDiv.height, overflow: divStyles.addResaDiv.overflow }}>
            <h3 id="addResaDiv" onClick={handleReduceDiv} style={{ cursor: "pointer" }}>
              Ajouter une réservation {!divStyles["addResaDiv"].reduced ? "-" : "+"}
            </h3>
            <div className="voirsalle">
              <p>Quelle salle ?</p>
              <a className="vuesalle" href={sallelabaule} target="_blank">
                Voir les salles
              </a>
            </div>
            <select className="ticket-inputForm" id="room">
              <option value="">Sélectionner</option>
              {guerandeRoomOptions.length > 0
                ? guerandeRoomOptions.map((opt, i) => {
                    return (
                      <option key={i} value={opt}>
                        {opt}
                      </option>
                    );
                  })
                : null}
            </select>
            <label htmlFor="title">Nom de la réunion</label>
            <input type="text" name="title" id="submitFormNameInput" />
            <p style={{ "margin-top": "10px", "margin-right": "10px" }}>
              Date
              <input id="submitFormDateInput" type="date"></input>
            </p>
            <p>
              De {time("addStartTime", null, null)} à {time("addEndTime", null, null)}
            </p>
            <label htmlFor="addParticipants">Ajouter des participants : </label>
            <input id="addParticipants" type="button" value="Ajouter des participants" onClick={handleAddMembers} />
            {showMembersTable ? (
              <div>
                <div className="big-grey-div" onClick={handleAddMembers}></div>
                <div className="add-members-div">
                  <h3>Choisir un membre</h3>
                  <TableWithSelect
                    baseId={"appdcpGp5m9QJ9ye4"}
                    tableName={"Utilisateurs EDL"}
                    viewName={null}
                    recordOptions={recordOptions}
                    setRecordOptions={setRecordOptions}
                    selectedRecord={selectedLinkedRecordToAdd}
                    setSelectedRecord={setSelectedLinkedRecordToAdd}
                    user={user}
                    setUser={setUser}
                    displayFields={false}
                    multipleRecordsSelect={true}
                    loading={loading}
                    setLoading={setLoading}
                  />{" "}
                </div>
              </div>
            ) : null}
            {selectedLinkedRecordToAdd.records.length > 0 ? (
              <ul>
                {selectedLinkedRecordToAdd.records.map((rec, ind) => {
                  return <li id={rec.id}>{rec["Name"]}</li>;
                })}
              </ul>
            ) : (
              <p>Aucun participant</p>
            )}
            <label htmlFor="notes">Notes : </label>
            <textarea
              name="notes"
              placeholder="Ecrivez vos notes ici..."
              onChange={handleUpdateRecToDisplay}
              id="addNotes"
              style={{
                margin: "0px 5% 10px",
                border: "1px solid gray",
                height: "65px",
                width: "90%",
                "min-width": "90%",
                "max-width": "90%",
                "max-height": "150px",
              }}
            ></textarea>
            <input className="btn-get-started" id="addResa-btn-get-started" type="button" value="Ajouter" onClick={handleAddResa} />
          </div>
          <div className="subDivPlanning2-100" style={{ height: divStyles.lastResaDiv.height, overflow: divStyles.lastResaDiv.overflow }}>
            <h3 id="lastResaDiv" onClick={handleReduceDiv} style={{ cursor: "pointer" }}>
              Dernières reservations faites {!divStyles["lastResaDiv"].reduced ? "-" : "+"}
            </h3>
            <div className="div-select" style={{ width: "100%" }}>
              <input className="select" name="select" onInput={handleInputOnChange} type="text" placeholder="Rechercher..."></input>
            </div>
            <div className="subDivPlanning2-ul">
              {guerandeRecords.length > 0 ? (
                guerandeRecords.map((rec, i) => {
                  return (
                    <ul
                      key={i}
                      id={rec.id}
                      onClick={(e) => {
                        handleResaClick(rec, e);
                      }}
                    >
                      <li>
                        {" "}
                        Salle {rec.room} : {rec.title}
                      </li>
                      <li>Date: {rec.day}</li>
                      <li>
                        {rec.heureDebut} - {rec.heureFin}{" "}
                      </li>
                    </ul>
                  );
                })
              ) : (
                <p>Pas de réunion prévue.</p>
              )}
            </div>
          </div>
        </div>
        {/* <div>
          <GuerandeRDC onClick={handleSVGClick} onMouseOver={handleSVGMouseOver} />
        </div> */}
      </div>
    );
  }
}
export default ReuCalendar;
