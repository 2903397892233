import { useState } from "react";
import APEDL from "../assets/AP-EDL5.png";
import "../styles/Connexion.css";
import axios from "axios";

function ConnexionIntranet({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected }) {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [firstVisit, setFirstVIsit] = useState(false);
  const [wrongPwd, setWrongPwd] = useState(false);
  const [unactivatedAcount, setUnactivatedAcount] = useState(false);
  const [UserNotFoundOrUnactivatedAcount, setUserNotFOundOrUnactivatedAcount] = useState(false);
  const [userForbidden, setUserForbidden] = useState(false);
  const [createAcountAsked, setCreateAcountAsked] = useState(false);
  const [forgotPwd, setForgotPwd] = useState("");
  const [problemOccured, setProblemOccured] = useState("");
  const [validMail, setValidMail] = useState("");

  let pwdRecovery = { key: "", id: "", resetPwd: false };
  let isActivated = false;

  const params = new URLSearchParams(window.location.search);

  const mailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  if (params.has("key") && params.has("id")) {
    pwdRecovery.key = params.get("key");
    pwdRecovery.id = params.get("id");
    pwdRecovery.resetPwd = true;
  }

  if (params.has("activated")) {
    isActivated = true;
  }

  console.log(process.env.NODE_ENV);
  console.log(window.location);
  const baseUrl = window.location.origin;
  // const protocol = window.location.protocol;

  const handleSendEmail = (e) => {
    e.preventDefault();

    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    // var userPwd = document.getElementById("pwdForm").value;

    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }
    setValidMail("");
    console.log(userMail);
    // console.log(userPwd);

    //Vérifier que les identifiants sont renseignés avant envoi inutile.
    axios
      .post(
        baseUrl + "/api/auth/getEDLUser",
        // "https://app.energiesdeloire.com/api/auth/getUser",
        // "http://localhost:3000/api/auth/getUser",
        // { email: userMail, password: userPwd },
        { email: userMail, pwdRecovery },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setUserNotFOundOrUnactivatedAcount(false);
        }

        console.log(response);
        console.log(response.data);
        var theRes = response.data;
        if (theRes.firstVisit === true) {
          setFirstVIsit(true);
        }

        setLoading(false);
        setEmailSent(true);

        console.log(theRes);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
            return setUserNotFOundOrUnactivatedAcount(true);
          } else if (error.response.status === 403) {
            setLoading(false);
            if (!pwdRecovery.resetPwd) {
              return setUserNotFOundOrUnactivatedAcount(true);
            } else {
              return setProblemOccured(
                "Impossible de réinitialiser votre mot de passe. Merci de cliquer sur mot de passe oublié. Si cela se reproduit, merci d'en informer it@energiesdeloire.com"
              );
            }
            return setUserForbidden(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      });
  };

  const handlecreateAcount = (e) => {
    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;

    if (userMail === "") {
      return;
    }
    console.log(userMail);

    axios
      .post(
        baseUrl + "/api/auth/createAcount",

        { email: userMail },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        if (response.status === 200) {
          setUserNotFOundOrUnactivatedAcount(false);
          setCreateAcountAsked(true);
        }

        console.log(response);
        console.log(response.data);
        var theRes = response.data;

        setLoading(false);

        console.log(theRes);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();

    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    var userPwd = document.getElementById("pwdForm").value;
    var confirmPwdForm = document.getElementById("confirmPwdForm").value;

    if (userPwd !== confirmPwdForm) {
      alert("Les mots de passe renseignés ne sont pas identques.");
      return;
    }

    axios
      .post(
        baseUrl + "/api/auth/signup",
        // "https://app.energiesdeloire.com/api/auth/signup",
        // "http://localhost:3000/api/auth/signup",
        { email: userMail, password: userPwd, confirmPwd: confirmPwdForm },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setUserNotFOundOrUnactivatedAcount(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        var theRes = response.data;
        // if ((theRes.firstVisit = true)) {
        //   setFirstVIsit(true);
        // }

        setLoading(false);
        if (response.status === 200 && !response.activeUser && pwdRecovery) {
          alert("Votre mot de passe a bien été modifié !");
          window.location.href = baseUrl;
        } else if (response.status === 200 && !response.activeUser) {
          alert("Vous allez recevoir un mail de confirmation. Celui vous permettra d'activer votre compte EDL.");
          window.location.href = baseUrl;
        } else if (response.status === 200) {
          alert("Votre mot de passe a bien été créé.");
          window.location.href = baseUrl;
        }
        // setEmailSent(true);

        console.log(theRes);
        // if (response.status === 200) {
        //   // window.location.href = "http://localhost:3000/depotfacture";
        //   window.location.href = baseUrl;
        //   // window.location.href = "https://app.energiesdeloire.com/depotfacture";
        // }
      });
  };
  const handleSignin = (e) => {
    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    var userPwd = document.getElementById("pwdForm").value;

    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }

    setValidMail("");

    axios
      .post(
        // "http://localhost:3000/api/auth/login",
        // "https://app.energiesdeloire.com/api/auth/login",
        baseUrl + "/api/auth/EDLlogin",

        { email: userMail, password: userPwd },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data);
        var theRes = response.data;

        setLoading(false);

        console.log(theRes);
        if (response.status === 200) {
          setIsConnected(true);

          window.location.href = baseUrl + "/";
        } else if (response.status === 401) {
          setWrongPwd(true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            if (error.response.data.error === "Mot de passe incorrect !") {
              setWrongPwd(true);
            } else if (error.response.data.error === "Compte non activé !") {
              setUnactivatedAcount(true);
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      });
  };

  const handleForgotPwd = (e) => {
    e.preventDefault();
    setLoading(true);
    setProblemOccured("");
    var userMail = document.getElementById("emailForm").value;
    if (userMail === "") {
      setLoading(false);
      setValidMail("Vous n'avez renseigné aucune adresse mail");
      return;
    }
    if (!userMail.match(mailRegex)) {
      setLoading(false);
      setValidMail("L'adresse mail renseignée n'est pas valide");
      return;
    }
    setValidMail("");

    axios
      .post(
        baseUrl + "/api/auth/forgotPwd",

        { email: userMail },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setForgotPwd("Vous allez recevoir un email pour réinitialiser votre mot de passe d'ici quelques instants.");
        } else {
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        }

        console.log(response.data);
        var theRes = response.data;

        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          // console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
            return setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
          } else if (error.response.status === 403) {
            setLoading(false);
            return setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        }
        console.log(error.config);
        setForgotPwd("Il semble qu'un problème soit survenu. Si cela persiste, merci de contacter it@energiesdeloire.com.");
        setLoading(false);
        return;
      });
  };

  return (
    <div
      className="container-Home"
      onClick={() => setResponsiveMenuVisible(false)}
      style={{
        "background-image": `url(${APEDL})`,
        "min-height": "100vh",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      }}>
      {loading ? <div id="spinner-modal"></div> : null}
      <div className="EDL-home-title">
        <p>ENERGIES DE LOIRE ☀️</p>
        <h1>Connexion</h1>
      </div>
      <div class="login-card">
        {!pwdRecovery.resetPwd ? <h2>Accéder à l'application EDL</h2> : <h2>Réinitialiser votre mot de passe</h2>}

        <form>
          <div className="div-item">
            <label for="email">Email</label>
            <input id="emailForm" type="email" name="email" required />
          </div>
          {emailSent ? (
            <div className="div-item">
              <label for="password">Mot de passe</label>
              <input type="password" name="password" required id="pwdForm" placeholder="Tapez votre mot de passe" />
            </div>
          ) : null}
          {firstVisit ? (
            <div className="div-item">
              <label for="password">Confirmer le mot de passe</label>
              <input type="password" name="password" required id="confirmPwdForm" placeholder="Tapez de nouveau votre mot de passe" />
            </div>
          ) : null}
          {UserNotFoundOrUnactivatedAcount ? (
            <div>
              <p style={{ color: "red" }}>Utilisateur non trouvé ou compte non activé. Merci de contacter it@energiesdeloire.com</p>
              <p style={{ color: "red" }}>
                Vous pouvez également envoyer une demande de création de compte en cliquant sur le bouton suivant.
              </p>
              <button className="formButton" onClick={handlecreateAcount}>
                Créer un compte
              </button>
            </div>
          ) : null}
          {userForbidden ? (
            <div>
              <p style={{ color: "red" }}>Vous n'êtes pas autorisé à accéder à ce contenu. Merci de contacter it@energiesdeloire.com</p>
            </div>
          ) : null}
          {createAcountAsked ? (
            <div>
              <p style={{ color: "green" }}>
                Votre demande a bien été transmise à Energies de Loire. Vous recevrez rapidement un email confirmant la création de votre
                compte utilisateur. Merci de votre confiance.
              </p>
              <a href="https://portail.energiesdeloire.com" id="backToPortail" className="btn-get-started">
                Retour vers Energies de Loire.
              </a>
            </div>
          ) : null}
          {/* <div className="div-item">
            <label for="password">Mot de passe</label>
            <input
              type="password"
              name="password"
              required
              // data-ms-member="password"
              id="pwdForm"
            />
          </div> */}
          {!createAcountAsked ? (
            <div>
              {" "}
              {!emailSent ? (
                <div className="div-item" id="form-button">
                  <button className="formButton" onClick={handleSendEmail}>
                    Se connecter
                  </button>
                  <a href="" onClick={handleForgotPwd}>
                    Mot de passe oublié ?
                  </a>
                  {forgotPwd !== "" ? <p style={{ color: "red" }}>{forgotPwd}</p> : null}
                  {problemOccured !== "" ? <p style={{ color: "red" }}>{problemOccured}</p> : null}
                </div>
              ) : (
                <div>
                  {firstVisit ? (
                    <div className="div-item" id="form-button">
                      <button className="formButton" onClick={handleSignup}>
                        Se connecter
                      </button>
                      <br />
                      <a href="" onClick={handleForgotPwd}>
                        Mot de passe oublié ?
                      </a>
                      {forgotPwd !== "" ? <p style={{ color: "red" }}>{forgotPwd}</p> : null}
                    </div>
                  ) : (
                    <div className="div-item">
                      {wrongPwd ? <p style={{ color: "red" }}>Le mot de passe renseigné n'est pas correct.</p> : null}
                      {unactivatedAcount ? (
                        <p style={{ color: "red" }}>
                          Le compte utilisateur n'est pas encore activé. Merci de contacter it@energiesdeloire.com
                        </p>
                      ) : null}
                      <button className="formButton" id="form-button" onClick={handleSignin}>
                        Se connecter
                      </button>
                      <br />
                      <a href="" onClick={handleForgotPwd}>
                        Mot de passe oublié ?
                      </a>
                      {forgotPwd !== "" ? <p style={{ color: "red" }}>{forgotPwd}</p> : null}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}

export default ConnexionIntranet;
