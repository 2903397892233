import "../styles/Faq.css";
import React, { useState, useEffect, useRef } from "react";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@mui/styles";
import axios from "axios";
// import FaqContent from "./FaqContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import TableChartIcon from "@mui/icons-material/TableChart";
import BackupIcon from "@mui/icons-material/Backup";
import Select from "@material-ui/core/Select/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip, withWidth } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertToRaw } from "draft-js";

function FaqViewer({ user, setUser }) {
  const ref = useRef(null);
  const [anchor, setAnchor] = useState(null);

  const [choosenFaqContent, setChoosenFaqContent] = useState({});
  const [nonSavedModifications, setNonSavedModifications] = useState(false);
  const [contentJustLoaded, setContentJustLoaded] = useState(false);
  const [reducedPannel, setReducedPannel] = useState(false);
  const [existingContents, setExistingContents] = useState([]);

  const [alertBox, setAlertBox] = useState({ message: "", isSuccess: false });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const defaultTheme = createTheme();
  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          // marginTop: 50,
          width: !reducedPannel ? "80%" : "95%",
        },
        container: {
          borderBottom: "1px solid gray",
        },
        editor: {
          "min-height": "350px",
          "max-height": "calc(100vh - 115px)",
          overflowY: "auto",
        },
      },
    },
  });

  const HtmlToJsxIframe = ({ html }) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const iframeElement = doc.querySelector("iframe");
    if (!iframeElement) {
      return null;
    }

    const iframeAttributes = Array.from(iframeElement.attributes).reduce((acc, attribute) => {
      const attributeName = attribute.name.toLowerCase();
      if (attributeName === "style") {
        const styleString = attribute.value;
        const styleObj = styleString.split(";").reduce((styleAcc, style) => {
          const [property, value] = style.split(":").map((str) => str.trim());
          if (property && value) {
            styleAcc[property] = value;
          }
          return styleAcc;
        }, {});

        acc.style = styleObj;
      } else {
        acc[attributeName] = attribute.value;
      }
      return acc;
    }, {});

    return <iframe {...iframeAttributes}></iframe>;
  };

  const MyBlock = (props) => {
    return (
      <div
        style={{
          padding: 10,
          backgroundColor: "#ebebeb",
        }}
      >
        {props.children}
      </div>
    );
  };
  const iframeRegex = /<iframe[^>]*>[\s\S]*?<\/iframe>/gi;
  const MyIframeDecorator = (props) => {
    console.log(props);

    console.log(props.decoratedText);
    return HtmlToJsxIframe({ html: props.decoratedText });
  };

  const handleChangeOption = (event) => {
    setChoosenFaqContent(existingContents.find((elt) => elt.name === event.target.value));
  };

  useEffect(() => {
    axios
      .post(
        "api/faqContent/getAll",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },

          mode: "cors",
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setExistingContents(res.data.faqContents);
        setAlertBox({ message: "✅ Téléchargement des articles réalisé avec succès !", isSuccess: true, show: true });
        setTimeout(() => {
          setAlertBox({ message: "✅ Téléchargement des articles réalisé avec succès !", isSuccess: true, show: false });
        }, 2000);
        // resolve(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div id="rte" style={{ margin: "60px 20px 20px 20px" }}>
      <div
        id="control-pannel"
        style={{
          height: "100vh",
          width: "20%",
          position: "fixed",
          right: !reducedPannel ? "0" : "-15%",
          "border-radius": "5px 0",
          "box-shadow": "1px 1px 10px #80808087",
        }}
      >
        <div style={{ position: "relative", top: "20px", left: "15px", cursor: "pointer !important", zIndex: "1000000" }}>
          {reducedPannel ? (
            <Tooltip title="Expand">
              <IconButton>
                <ArrowBackIosNewIcon
                  onClick={(elt) => {
                    setReducedPannel(false);
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Reduce">
              <IconButton>
                <ArrowForwardIosIcon
                  onClick={(elt) => {
                    setReducedPannel(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div style={{ margin: "60px 20px 20px 20px" }}>
          <InputLabel>Choisir un article...</InputLabel>
          <Select
            sx={{ minWidth: 200 }}
            labelId="fields-multiple-checkbox-label"
            id="fields-multiple-checkbox"
            value={choosenFaqContent.name ? choosenFaqContent.name : ""}
            // placeholder='Choisissez le ou les champs "Attachments"'
            onChange={handleChangeOption}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
          >
            {existingContents.map((content) => (
              <MenuItem key={content.name} value={content.name}>
                <Checkbox checked={choosenFaqContent.name === content.name} />
                <ListItemText primary={content.name} />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <ThemeProvider theme={defaultTheme}>
        <h1>{choosenFaqContent.name ? choosenFaqContent.name : "Choisissez un article dans le panneau de droite"}</h1>
        <MUIRichTextEditor
          ref={ref}
          readOnly={true}
          toolbar={false}
          inlineToolbar={false}
          decorators={[
            {
              component: MyIframeDecorator,
              regex: iframeRegex,
            },
          ]}
          defaultValue={choosenFaqContent.content ? JSON.parse(choosenFaqContent.content) : null}
          onChange={(data) => {
            const plainText = data.getCurrentContent().getPlainText();
            setNonSavedModifications(plainText !== "" && !contentJustLoaded);
          }}
        />
      </ThemeProvider>
    </div>
  );
}
export default FaqViewer;
