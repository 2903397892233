import APEDL from "../assets/AP-EDL5.png";
import uploadImg from "../assets/upload.png";
import "../styles/Tickets.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import UserTicketsList from "./UserTicketsList";

const FormData = require("form-data");

function DeposeTickets({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [onDropMsg, setOnDropMsg] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [rdyToSend, setRdyToSend] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [longLoading, setLongLoading] = useState(false);
  const [longLoadingOpacity, setLongLoadingOpacity] = useState(0);
  const [amountPb, setAmountPb] = useState(false);
  const [amountPbBorder, setAmountPbBorder] = useState("none");

  const [validEmail, setValidEmail] = useState(false);
  const [borderEmail, setBorderEmail] = useState("1px solid gray");
  const [datasSent, setDatasSent] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [jsonResponse, setJsonResponse] = useState({
    message: "",
  });
  const [airtableError, setAirtableError] = useState(false);
  // const [myTickets, setMyTickets] = useState([]);

  const [ticketStatus, setTicketStatus] = useState("");

  const [options, setOptions] = useState([]);
  const [categorieOptions, setCategorieOptions] = useState([]);
  const [bugOrUpgradeOptions, setBugOrUpgradeOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);

  var timer;
  var interval;

  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  console.log("baseUrl4200 : " + baseUrl4200);

  console.log(user);

  useEffect(() => {
    if (user.token) {
      axios
        .post(
          baseUrl4200 + "/airtableReq/getSelectFields",
          {
            tableAndFields: [
              { tableName: "Tickets", fieldName: "Catégorie", tableId: "tbl8Tpyv9p2CFh28d" },
              { tableName: "Tickets", fieldName: "Bug-Amélioration", tableId: "tbl8Tpyv9p2CFh28d" },
              { tableName: "Tickets", fieldName: "Priority", tableId: "tbl8Tpyv9p2CFh28d" },
              { tableName: "Tickets", fieldName: "Service", tableId: "tbl8Tpyv9p2CFh28d" },
            ],
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 201) {
            console.log(res);
            setOptions(res.data.fieldsAndOptions);
            let cateIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Catégorie");
            setCategorieOptions(res.data.fieldsAndOptions[cateIndex].optionArray);

            let bugOrUpgradeIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Bug-Amélioration");
            setBugOrUpgradeOptions(res.data.fieldsAndOptions[bugOrUpgradeIndex].optionArray);

            let priorityIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Priority");
            setPriorityOptions(res.data.fieldsAndOptions[priorityIndex].optionArray);

            let serviceIndex = res.data.fieldsAndOptions.findIndex((header) => header.fieldName == "Service");
            setServiceOptions(res.data.fieldsAndOptions[serviceIndex].optionArray);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const changeHandler = (event) => {
    console.log(event.target.files[0]);
    if (validateFile(event.target.files[0])) {
      setSelectedFile(event.target.files[0]);

      setIsSelected(true);
      document.getElementById("ticket-btn-get-started").style = "z-index:100";
    } else {
      setIsSelected(false);
      setErrorMessage("File type not permitted");
      document.getElementById("ticket-btn-get-started").style = "z-index:-1";
    }
  };

  const handleRdyToSend = () => {
    let formData = new FormData();

    if (
      document.getElementById("ticketName").value !== "" &&
      document.getElementById("bugOrImprove").value !== "" &&
      document.getElementById("category").value !== "" &&
      document.getElementById("priority").value !== "" &&
      document.getElementById("ticketDescription").value !== "" &&
      document.getElementById("service").value !== "" &&
      document.getElementById("respoServiceMail").value !== "" &&
      document.getElementById("ticketDateEcheance").value !== ""
    ) {
      if (selectedFile) {
        formData.append("ticket", selectedFile);

        for (var key of formData.entries()) {
          console.log(key[0] + ", " + key[1]);
        }

        setLoading(true);

        timer = setTimeout(() => {
          setLongLoading(true);
          var opaCounter = 0;
          interval = setInterval(() => {
            setLongLoadingOpacity((prevOpa) => prevOpa + 0.02);
            opaCounter += 0.02;
            if (opaCounter >= 1) {
              clearInterval(interval);
            }
          }, 50);
        }, 5000);

        axios
          .post(baseUrl4200 + "/ticket/postTicketAttachment", formData, {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${user.token}`,
            },

            mode: "cors",
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            // console.log(error.config);
            // setLoading(false);
            // setLongLoading(false);
            // clearTimeout(timer);
          })
          .then((response) => {
            console.log(response);

            if (response.status === 200) {
              console.log("response from request postTicketAttachment :");
              console.log(response);

              var fileNameOnServ = response.data.fileNameOnServ;

              axios
                .post(
                  baseUrl4200 + "/ticket/postTicketWithAttachment",
                  {
                    ticketName: document.getElementById("ticketName").value,
                    bugOrImprove: document.getElementById("bugOrImprove").value,
                    category: document.getElementById("category").value,
                    priority: document.getElementById("priority").value,
                    ticketDescription: document.getElementById("ticketDescription").value,
                    userEmail: user.email,
                    fileNameOnServ: fileNameOnServ,
                    service: document.getElementById("service").value,
                    respoServiceMail: document.getElementById("respoServiceMail").value,
                    deadline: document.getElementById("ticketDateEcheance").value,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                      Authorization: `Bearer ${user.token}`,
                    },
                    mode: "cors",
                  }
                )
                .catch(function (error) {
                  if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                  }
                  console.log(error.config);
                  setLoading(false);
                  setLongLoading(false);
                  clearTimeout(timer);
                })
                .then((res) => {
                  if (res.status === 200) {
                    setIsLoaded(true);
                    setLoading(false);
                    setLongLoading(false);
                    clearTimeout(timer);
                  } else {
                    setIsLoaded(true);
                    setLoading(false);
                    setLongLoading(false);
                    setAirtableError(true);
                  }
                });
            }
          });

        setRdyToSend(true);
      } else {
        axios
          .post(
            baseUrl4200 + "/ticket/postTicketWithoutAttachment",
            {
              ticketName: document.getElementById("ticketName").value,
              bugOrImprove: document.getElementById("bugOrImprove").value,
              category: document.getElementById("category").value,
              priority: document.getElementById("priority").value,
              service: document.getElementById("service").value,
              ticketDescription: document.getElementById("ticketDescription").value,
              userEmail: user.email,
              respoServiceMail: document.getElementById("respoServiceMail").value,
              deadline: document.getElementById("ticketDateEcheance").value,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
            setLoading(false);
            setLongLoading(false);
            clearTimeout(timer);
          })
          .then((res) => {
            if (res.status === 200) {
              setIsLoaded(true);
              setLoading(false);
              setLongLoading(false);
              clearTimeout(timer);
            } else {
              setIsLoaded(true);
              setLoading(false);
              setLongLoading(false);
              setAirtableError(true);
            }
          });
        setRdyToSend(true);
      }
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
    setOnDropMsg("Déposez le ici !");
    document.getElementById("dropZone").style.transform = "scale(1.05)";
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setOnDropMsg("Déposez le ici !");
    document.getElementById("dropZone").style.transform = "scale(1.05)";
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setOnDropMsg("");
    document.getElementById("dropZone").style.transform = "scale(1)";
  };

  const fileDrop = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.files[0]);
    var file = e.dataTransfer.files[0];

    handleFiles(file);
    setOnDropMsg("");
  };

  const handleFiles = (file) => {
    if (validateFile(file)) {
      // add to an array so we can display the name of file
      setSelectedFile(file);
      setIsSelected(true);
    } else {
      setIsSelected(false);
      setErrorMessage("File type not permitted");
    }
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
    if (validTypes.includes(file.type)) {
      return true;
    }
    return false;
  };

  const checkInputs = (e) => {
    e.preventDefault();
    //FIrst check email
    if (document.getElementById("respoServiceMail").value !== "") {
      if (document.getElementById("respoServiceMail").value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        setValidEmail(true);
        setBorderEmail("2px solid green");
      } else {
        setValidEmail(false);
        setBorderEmail("2px solid red");
      }
    }

    if (
      document.getElementById("ticketName").value !== "" &&
      document.getElementById("bugOrImprove").value !== "" &&
      document.getElementById("category").value !== "" &&
      document.getElementById("priority").value !== "" &&
      document.getElementById("ticketDescription").value !== "" &&
      document.getElementById("service").value !== "" &&
      document.getElementById("respoServiceMail").value !== "" &&
      document.getElementById("ticketDateEcheance").value !== "" &&
      document.getElementById("respoServiceMail").value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
    ) {
      document.getElementById("ticket-btn-get-started").style = "z-index:100";
    } else {
      document.getElementById("ticket-btn-get-started").style = "z-index:-100";
    }
  };

  return (
    <div>
      <div
        className="ticket-container-Home"
        onClick={() => setResponsiveMenuVisible(false)}
        style={{
          "background-image": `url(${APEDL})`,
          "min-height": "100vh",
          "background-repeat": "no-repeat",
          "background-size": "cover",
        }}
      >
        {loading ? (
          <div>
            <div id="spinner-modal"></div>
            {longLoading ? (
              <p id="loading-long-text" style={{ opacity: longLoadingOpacity }}>
                Analyzing datas...
              </p>
            ) : null}
          </div>
        ) : null}

        <div className="ticket-blog-home-title">
          {/* <p>Energies de loire</p> */}
          <div className="titles-tickets">
            <h1>DEPOSER UN TICKET</h1>
            {/* <h2>Ici vous pouvez soumettre vos suggestions et/ou rapporter un bug rencontré.</h2> */}
          </div>
          <div style={{ "margin-top": "50px" }}>
            <h2>Bienvenue {user.user ? user.user.firstname : null} !</h2>
            {!isLoaded ? (
              <div
                className="ticket-submitForm"
                id="ticket-submitForm"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              >
                <div className="ticket-subDivForm1">
                  <div className="ticket-subDivForm2">
                    <p>Objet de votre demande</p>
                    <input className="ticket-inputForm" id="ticketName" type="text" onChange={checkInputs}></input>
                  </div>
                  <div className="ticket-subDivForm2">
                    <p>Bug ou amélioration ?</p>
                    <select className="ticket-inputForm" id="bugOrImprove" onChange={checkInputs}>
                      <option value="">Sélectionner</option>
                      {bugOrUpgradeOptions.length > 0
                        ? bugOrUpgradeOptions.map((opt, i) => {
                            return (
                              <option key={i} value={opt}>
                                {opt}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="ticket-subDivForm1">
                  <div className="ticket-subDivForm2">
                    <p>Catégorie</p>
                    <select className="ticket-inputForm" id="category" onChange={checkInputs}>
                      <option value="">Sélectionner</option>
                      {categorieOptions.length > 0
                        ? categorieOptions.map((opt, i) => {
                            return (
                              <option key={i} value={opt}>
                                {opt}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="ticket-subDivForm2">
                    <p>Priorité</p>
                    <select className="ticket-inputForm" id="priority" onChange={checkInputs}>
                      <option value="">Sélectionner</option>
                      {priorityOptions.length > 0
                        ? priorityOptions.map((opt, i) => {
                            return (
                              <option key={i} value={opt}>
                                {opt}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                  <div className="ticket-subDivForm2">
                    <p>Service concerné</p>
                    <select className="ticket-inputForm" id="service" onChange={checkInputs}>
                      <option value="">Sélectionner</option>
                      {serviceOptions.length > 0
                        ? serviceOptions.map((opt, i) => {
                            return (
                              <option key={i} value={opt}>
                                {opt}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="ticket-subDivForm1">
                  <div className="ticket-subDivForm2" style={{ margin: "10px" }}>
                    <p>Email du responsable de service</p>
                    <input
                      className="ticket-inputForm"
                      id="respoServiceMail"
                      type="text"
                      onChange={checkInputs}
                      style={{ border: borderEmail }}
                    ></input>
                    {document.getElementById("respoServiceMail") !== null ? (
                      !validEmail && document.getElementById("respoServiceMail").value !== "" ? (
                        <p style={{ "font-size": "10px", color: "red", position: "relative", top: "-20px" }}>
                          L'adresse mail renseignée n'est pas valide
                        </p>
                      ) : null
                    ) : null}
                  </div>
                  <div className="ticket-subDivForm2" style={{ margin: "10px" }}>
                    <p>Deadline souhaitée</p>
                    <input
                      className="ticket-inputForm"
                      id="ticketDateEcheance"
                      type="date"
                      onChange={checkInputs}
                      style={{ cursor: "pointer" }}
                    ></input>
                  </div>
                </div>
                <p>Description</p>
                <textarea
                  type="text"
                  id="ticketDescription"
                  placeholder="Décrivez ici votre problème. Soyez précis et présenter si possible l'ensemble des étapes effectuées pour rencontrer le bug."
                  onChange={checkInputs}
                ></textarea>
                <div className="upload-icon"></div>
                <form encType="multipart/form-data">
                  Un document ou une capture d'écran à joindre ? Glissez votre document ci-dessous ou cliquez sur{" "}
                  <button
                    className="ticket-btn-browse"
                    id="buttonGetFile"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("getFile").click();
                    }}
                  >
                    Parcourir...
                  </button>
                  <br /> (format accepté : PDF/JPG/PNG)
                  <input type="file" id="getFile" onChange={changeHandler} name="ticket"></input>
                  {/* {loading ? (
                        <p>
                          Votre document est en cours de traitement. Merci de bien
                          vouloir patienter...
                        </p>
                      ) : null} */}
                  {isSelected ? (
                    <div>
                      {errorMessage !== "" ? <p>{errorMessage}</p> : null}
                      <p>Nom du fichier : {selectedFile.name}</p>
                      <p>Type de fichier : {selectedFile.type}</p>
                      <p>Poids (bytes) : {selectedFile.size}</p>
                    </div>
                  ) : (
                    <div className="dropZone" id="dropZone">
                      {" "}
                      {onDropMsg !== "" ? (
                        <p>{onDropMsg}</p>
                      ) : (
                        <div>
                          <p>Glissez votre document ici !</p>
                        </div>
                      )}
                      <img src={uploadImg} alt="upload" />
                    </div>
                  )}
                  <input className="btn-get-started" id="ticket-btn-get-started" type="button" value="Envoyer" onClick={handleRdyToSend} />
                  {/* <input
                        type="button"
                        value="Get Table Info"
                        onClick={handleGetTable}
                      /> */}
                  {/* <input type="button" value="Envoyer" onClick={handleRdyToSend} /> */}
                </form>
              </div>
            ) : (
              <div>
                {airtableError ? (
                  <div className="ticket-submitForm">
                    <h3>Une erreur est survenue.</h3>
                    <p>Si cela se répète, merci de prévenir le service IT EDL au plus vite.</p>
                    <a id="backToIntranet" className="btn-get-started" href="https://intranet.energiesdeloire.com/">
                      Retourner vers l'intranet
                    </a>
                  </div>
                ) : (
                  <div className="ticket-submitForm">
                    <h3>Merci d'avoir remonté cette information.</h3>
                    <p>
                      Le serice IT va prendre en charge votre demande dans les meilleurs délais et reviendra vers vous dès que possible.
                    </p>
                    <a
                      id="backToIntranet"
                      className="btn-get-started"
                      href="./tickets"
                      style={{ margin: "10px", width: "50%", "text-align": "center" }}
                    >
                      Déposer un nouveau ticket ?
                    </a>
                    <a
                      id="backToIntranet"
                      className="btn-get-started"
                      href="./"
                      style={{ margin: "10px", width: "50%", "text-align": "center" }}
                    >
                      Retourner vers l'accueil ?
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <UserTicketsList user={user} setUser={setUser} />
    </div>
  );
}

export default DeposeTickets;
