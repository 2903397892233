import * as React from "react";
import { useEffect, useState } from "react";
import "../../styles/customCard.css";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CreateCustomButton() {
  const [fieldsVal, setFieldsVal] = useState("");
  const [tableVal, setTableVal] = useState("");
  const [functionVal, setFunctionVal] = useState("");
  const [viewVal, setViewVal] = useState("");
  const [copy, setCopy] = useState(false);

  const [loading, setLoading] = useState(false);

  const [tableNames, setTableNames] = useState([]);
  const [choosenTable, setChoosenTable] = useState([]);

  const [viewNames, setViewNames] = useState([]);
  const [choosenView, setChoosenView] = useState([]);

  const [attachmentsFieldsName, setAttachmentsFieldsName] = useState([]);
  const [choosenField, setChoosenField] = useState([]);
  const [linkVal, setLinkVal] = useState("");
  const [linkName, setLinkName] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");

  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  useEffect(() => {
    axios
      .post(
        baseUrl4200 + "/airtableReq/getTables",
        {
          baseId: "appqe0gy4OaaiygZZ",
        },
        {
          headers: {
            "Content-type": "application/json",
            // Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          let tableNames = res.data.tableNames;
          tableNames.sort();
          setTableNames(tableNames);
          console.log(tableNames);
        } else {
          console.log("Un problème est survenu lors de l'envoi des données.");
        }
      });
  }, []);

  useEffect(() => {
    setChoosenField([]);
    setChoosenView([]);
  }, [choosenTable]);

  useEffect(() => {
    if (choosenTable.length > 0) {
      axios
        .post(
          baseUrl4200 + "/airtableReq/getViews",
          {
            baseId: "appqe0gy4OaaiygZZ",
            tableId: choosenTable[0],
          },
          {
            headers: {
              "Content-type": "application/json",
              // Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            // setAttachmentsFieldsName(res.data.attachmentsFieldsNames);
            let viewNames = res.data.viewNames;
            viewNames.sort();
            setViewNames(viewNames);
            console.log(viewNames);
          } else {
            console.log("Un problème est survenu lors de l'envoi des données.");
          }
        });
    }
    setChoosenField([]);
  }, [choosenTable]);

  useEffect(() => {
    if (choosenView.length > 0 && choosenTable.length > 0) {
      axios
        .post(
          baseUrl4200 + "/airtableReq/getAttachmentsFieldsName",
          {
            baseId: "appqe0gy4OaaiygZZ",
            tableId: choosenTable[0],
            viewId: choosenView[0],
          },
          {
            headers: {
              "Content-type": "application/json",
              // Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            // setAttachmentsFieldsName(res.data.attachmentsFieldsNames);
            let attachmentFields = res.data.attachmentsFieldsNames.map((obj) => obj.name);
            attachmentFields.sort();
            setAttachmentsFieldsName(attachmentFields);
            console.log(attachmentFields);
          } else {
            console.log("Un problème est survenu lors de l'envoi des données.");
          }
        });
    }
  }, [choosenView]);

  const handleFieldsInput = (e) => {
    e.preventDefault();
    console.log(e.target);
    setFieldsVal(e.target.value.replace(/,\s/g, ","));
  };

  const handleTableSelectOnChange = (e) => {
    e.preventDefault();
    console.log(e.target);
    setTableVal(e.target.value);
  };
  const handleLinkInput = (e) => {
    setLinkVal(e.target.value);
  };
  const handleNameInput = (e) => {
    setLinkName(e.target.value);
  };
  const handleFunctionSelectOnChange = (e) => {
    e.preventDefault();
    console.log(e.target);
    const selectedValue = e.target.value;
    setFunctionVal(selectedValue);
    setSelectedFunction(selectedValue);
  };

  const handleViewInput = (e) => {
    e.preventDefault();
    console.log(e.target);
    setViewVal(e.target.value);
  };

  const handleChangeField = (event) => {
    const {
      target: { value },
    } = event;
    setChoosenField(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFieldsVal(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeTable = (event) => {
    const {
      target: { value },
    } = event;
    setChoosenTable(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setTableVal(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeView = (event) => {
    const {
      target: { value },
    } = event;
    setChoosenView(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setViewVal(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div class="custom-card">
      {loading ? (
        <div>
          <div id="spinner-modal"></div>
        </div>
      ) : null}

      <h3>Créer votre propre bouton</h3>
      <form>
        <div className="div-item">
          <InputLabel htmlFor="function">Fonction</InputLabel>
          <FormControl variant="outlined">
            <Select
              native
              id="functionForm"
              name="function"
              value={selectedFunction}
              onChange={handleFunctionSelectOnChange}
              label="Fonction"
              required
            >
              <option value={""}>Sélectionner</option>
              <option value={"bulkDownloadAttachment"}>Télécharger tous les documents d'une vue</option>
              <option value={"externalLink"}>Renvoyer vers un lien</option>
            </Select>
          </FormControl>
        </div>
        {/* <div className="div-item">
          <label for="table">Table</label>
          <select id="tableForm" type="select" name="table" onChange={handleTableSelectOnChange} required>
            <option value={""}>Sélectionner</option>
            <option value={"Centrale"}>Centrale</option>
            <option value={"Projet"}>Projet</option>
            <option value={"ENEDIS / EDF"}>ENEDIS / EDF</option>
            <option value={"Investisseur"}>Investisseur</option>
            <option value={"Analyse centrales"}>Analyse centrales</option>
          </select>
        </div> */}
        {selectedFunction === "externalLink" && (
          <div>
            <div className="div-item">
              <label htmlFor="link">Nom du bouton</label>
              <input type="text" name="link" placeholder="Nommé votre bouton" onChange={handleNameInput} value={linkName} />
            </div>
            <div className="div-item">
              <label htmlFor="link">Lien</label>
              <input type="text" name="link" placeholder="Insérer le lien ici" onChange={handleLinkInput} value={linkVal} />
            </div>

            <div className="div-item">
              <label htmlFor="result">Texte à copier</label>
              <input
                type="textarea"
                name="result"
                value={`<a style="background: rgb(97, 206, 112); padding: 10px; border-radius: 5px; text-decoration: none; color: white;" href="${linkVal}" target="blank">${linkName}</a> />`}
              />
            </div>
          </div>
        )}
        {selectedFunction === "bulkDownloadAttachment" && (
          <div>
            <InputLabel id="labelTables">Tables</InputLabel>
            <Select
              labelId="tables-multiple-checkbox-label"
              id="tables-multiple-checkbox"
              value={choosenTable}
              placeholder="Choisissez la table"
              onChange={handleChangeTable}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {tableNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={choosenTable.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>

            <InputLabel id="labelViews">Views</InputLabel>
            <Select
              labelId="views-multiple-checkbox-label"
              id="views-multiple-checkbox"
              value={choosenView}
              placeholder="Choisissez la vue"
              onChange={handleChangeView}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {viewNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={choosenView.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>

            {/* <div className="div-item">
          <label for="view">Vue</label>
          <input type="text" name="view" placeholder="[P] Nom de la vue" onChange={handleViewInput} value={viewVal} />
        </div> */}

            <InputLabel id="labelFields">Fields</InputLabel>
            <Select
              labelId="fields-multiple-checkbox-label"
              id="fields-multiple-checkbox"
              multiple
              value={choosenField}
              placeholder='Choisissez le ou les champs "Attachments"'
              onChange={handleChangeField}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {attachmentsFieldsName.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={choosenField.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>

            {/* <div className="div-item">
          <label for="fields">Champs</label>
          <input type="text" name="fields" placeholder="Champ1,Champ2,Champ3..." onChange={handleFieldsInput} value={fieldsVal} />
        </div> */}
            <div className="div-item">
              <label for="result">Texte à copier</label>
              <input
                type="textarea"
                name="result"
                value={`<input type="button" class="dashboard-button" value="Télécharger tous les documents" table="${tableVal}" view="${viewVal}" customFunction="${functionVal}(${fieldsVal})" />`}
              />
            </div>
          </div>
        )}
        <div className="div-item">
          <button
            className="formButton"
            onClick={(e) => {
              e.preventDefault();
              let copiedText = "";
              if (selectedFunction === "externalLink") {
                // Logic for "Renvoyer vers un lien" option
                copiedText = `<a style="background: rgb(97, 206, 112); padding: 10px; border-radius: 5px; text-decoration: none; color: white;" href="${linkVal}" target="blank">${linkName}</a>`;
              } else if (selectedFunction === "bulkDownloadAttachment") {
                // Logic for "Télécharger tous les documents d'une vue" option
                copiedText = `<input type="button" class="dashboard-button" value="Télécharger tous les documents" table="${tableVal}" view="${viewVal}" customFunction="${functionVal}(${fieldsVal})" />`;
              }

              navigator.clipboard.writeText(copiedText);
              setCopy(true);
              let copyTimeOut = setTimeout(() => {
                setCopy(false);
                clearTimeout(copyTimeOut);
              }, 5000);
            }}
          >
            Copy to clipboard ?
          </button>

          {copy ? <p style={{ color: "green" }}>Le bouton est copié !</p> : null}
        </div>
      </form>
    </div>
  );
}

export default CreateCustomButton;
