// import { Wrapper } from "@googlemaps/react-wrapper";
// import MyMapComponent from "./MyMapsComponent";
// import React from "react";
// import "../styles/MapsChantier.css";

// function MapsChantier({ user, setUser }) {
//   const uluru = { lat: 47.2843733, lng: -2.4951599 };

//   //   const contentString = "TEST"
//   // const infowindow = new google.maps.InfoWindow({
//   //   content: contentString,
//   // });

//   return (
//     <Wrapper apiKey={"AIzaSyAfTQue0x7hWz0iWXMuO4vEO-IzN3rc_U0"}>
//       <MyMapComponent center={uluru} zoom={9} user={user} setUser={setUser} />
//     </Wrapper>
//   );
// }
// export default MapsChantier;
import { Wrapper } from "@googlemaps/react-wrapper";
import MyMapComponent from "./MyMapsComponent";
import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import "../styles/MapsChantier.css";

const API_KEY = "AIzaSyAfTQue0x7hWz0iWXMuO4vEO-IzN3rc_U0";

function MapsChantier({ user, setUser }) {
  const uluru = { lat: 47.2843733, lng: -2.4951599 };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Et voici la plus belle carte !</Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={{ position: "relative" }}>
        <Wrapper apiKey={API_KEY}>
          <MyMapComponent center={uluru} zoom={9} user={user} setUser={setUser} />
        </Wrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="Text input" variant="outlined" fullWidth />
      </Grid>
    </Grid>
  );
}
export default MapsChantier;
