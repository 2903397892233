import TableWithTextInput from "../Standard/TableWithSelect";
import ModifyRecordForm from "../Standard/modifyRecordForm";
import { Document, Page, pdfjs } from "react-pdf";
import "../../styles/standard/containers.css";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import "../../styles/CheckBill.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CheckBill({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, background, user, setUser }) {
  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  const [loading, setLoading] = useState(0);
  const [longLoading, setLongLoading] = useState(false);

  const [recordOptions, setRecordOptions] = useState({ records: [], baseId: "", tableName: "", viewName: "" });
  const [fieldsOptions, setFieldsOptions] = useState([]);
  const [linkedRecordOptions, setLinkedRecordOptions] = useState({ records: [], baseId: "", tableName: "", viewName: "" });
  const [linkFieldOptions, setLinkFieldOptions] = useState({ baseId: "", tableName: "", viewName: "", fieldId: "", fieldName: "" });
  const [modifyResaDivDisplay, setModifyResaDivDisplay] = useState("none"); //Variable qui permet d'afficher ou non la popup de modification

  const [selectedRecord, setSelectedRecord] = useState({
    record: {},
    baseId: "",
    tableName: "",
    viewName: "",
  });
  const [selectedLinkedRecord, setSelectedLinkedRecord] = useState({ records: [], baseId: "", tableName: "", viewName: "" });

  const [numPages, setNumPages] = useState(null);
  const [numPagesArr, setNumPagesArr] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [triggerGetRecords, setTriggerGetRecords] = useState(false);

  const [msgAfterUpdate, setMsgAfterUpdate] = useState({ message: "", display: "none" });
  const [showMsgAfterUpdate, setShowMsgAfterUpdate] = useState("none");

  const [modifiedFieldType, setModifiedFieldType] = useState("");

  useEffect(() => {
    console.log("loading", loading);
    if (loading < 0) setLoading(0);
  }, [loading]);

  // useEffect(() => {
  //   axios
  //     .get("/intranetUser")
  //     .then((res) => {
  //       if (res.status === 201) {
  //         setUserIntranet((prevState) => {
  //           return {
  //             ...prevState,
  //             userEmail: res.data.userEmail,
  //             token: res.data.token,
  //           };
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  function onDocumentLoadSuccess({ numPages }) {
    let tmpArr = [];
    for (let i = 1; i <= numPages; i++) {
      tmpArr.push(i);
    }
    setNumPages(numPages);
    setNumPagesArr(tmpArr);
    console.log(tmpArr);
    console.log(numPagesArr);
  }

  const handlePageClick = (e) => {
    e.preventDefault();
    console.log(e.target.id);
    setPageNumber(parseInt(e.target.id));
  };

  const handleZoomIn = () => {
    if (scale < 1.3) {
      setScale(scale + 0.1);
    }
  };
  const handleZoomOut = () => {
    if (scale > 0.7) {
      setScale(scale - 0.1);
    }
  };

  function handleResaClick(rec, e) {
    e.preventDefault();
    if (rec) {
      console.log(rec);
      setModifyResaDivDisplay("flex");
    } else {
      console.log(rec);
      setModifyResaDivDisplay("none");
      setLinkedRecordOptions({ records: [], baseId: "", tableName: "", viewName: "" });
      setLinkFieldOptions({ baseId: "", tableName: "", viewName: "", fieldId: "", fieldName: "" });
      setSelectedLinkedRecord({ records: [], baseId: "", tableName: "", viewName: "" });
      setModifiedFieldType("");
    }
  }

  useEffect(() => {
    //Se déclenche lorsqu'un selectedRecord a été clické (deuxième tableWithSelect)
    if (linkFieldOptions.fieldId !== "") {
      console.log(selectedLinkedRecord);
      console.log(linkFieldOptions);
      // selectedRecord.record[linkFieldOptions.fieldName] = [];
      if (selectedRecord.record[linkFieldOptions.fieldName].length < selectedLinkedRecord.records.length) {
        selectedRecord.record[linkFieldOptions.fieldName].push(selectedLinkedRecord.records[selectedLinkedRecord.records.length - 1].id);
      } else {
        let tmpIdArr = [];
        selectedLinkedRecord.records.forEach((rec) => {
          tmpIdArr.push(rec.id);
        });
        selectedRecord.record[linkFieldOptions.fieldName] = tmpIdArr;
      }
      setSelectedRecord({ ...selectedRecord });
    }
  }, [selectedLinkedRecord]);

  useEffect(() => {
    console.log(selectedRecord);
  }, [selectedRecord]);

  let msgTimer; //timer identifier
  let msgShowInterval = 3000;

  useEffect(() => {
    if (msgAfterUpdate.message !== "") {
      clearTimeout(msgTimer);
      setShowMsgAfterUpdate("block");
      msgTimer = setTimeout(() => {
        setShowMsgAfterUpdate("none");
      }, msgShowInterval);
    }
  }, [msgAfterUpdate]);

  return (
    // <div className="container" style={{ background: `url(${background})` }}>
    <div className="container" style={{ background: `#fbfbfb`, display: "flex", "flex-direction": "column" }}>
      {loading > 0 ? (
        <div>
          <div id="spinner-modal"></div>
          {/* {longLoading ? (
            <p id="loading-long-text" style={{ opacity: longLoadingOpacity }}>
              Analyzing datas...
            </p>
          ) : null} */}
        </div>
      ) : null}
      <div
        style={{
          position: "fixed",
          display: showMsgAfterUpdate,
          "z-index": "1000",
          bottom: "50px",
          background: "#b7f44a54",
          padding: "0 10px",
          "border-radius": "3px",
          "box-shadow": "1px 1px 10px gray",
          "transition-duration": "0.5s",
        }}
      >
        {msgAfterUpdate.message !== "" ? <p>{msgAfterUpdate.message}</p> : null}
      </div>
      <div>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shr3P2NtnVOvm6qv6?backgroundColor=yellow&viewControls=on"
          style={{ background: "transparent", border: "1px solid #ccc", width: "100%", height: "533px", margin: "70px auto" }}
        ></iframe>
      </div>
      <div className="container" style={{ background: `#fbfbfb`, "box-shadow": "1px 1px 10px gray", "border-radius": "10px" }}>
        <div className="sub-container" style={{ width: "30%", "max-height": "80vh" }}>
          <TableWithTextInput
            baseId={"app4eaIYU41A4lONh"}
            tableName={"tblokGjysPpz6Y421"}
            viewName={"viwEMLzAqHNoj6IXi"}
            styleOptions={{ test: "hello" }}
            recordOptions={recordOptions}
            setRecordOptions={setRecordOptions}
            fieldsOptions={fieldsOptions}
            setFieldsOptions={setFieldsOptions}
            selectedRecord={selectedRecord}
            setSelectedRecord={setSelectedRecord}
            user={user}
            setUser={setUser}
            displayFields={true}
            multipleRecordsSelect={false}
            loading={loading}
            setLoading={setLoading}
            triggerGetRecords={triggerGetRecords}
            setTriggerGetRecords={setTriggerGetRecords}
          />
        </div>
        <div
          className="sub-container"
          style={{
            "border-right": "1px solid black",
            "border-left": "1px solid black",
            width: "50vw",
            overflow: "auto",
            "max-height": "100vh",
          }}
        >
          {selectedRecord.record["Attachments"] !== undefined ? (
            <div>
              <Document file={selectedRecord.record["Attachments"][0]["url"]} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <div style={{ "text-align": "right" }}>
                <input
                  type="button"
                  onClick={handleZoomOut}
                  value="-"
                  style={{ cursor: "pointer", width: "20px", margin: "5px", "text-align": "right" }}
                ></input>
                <input
                  type="button"
                  onClick={handleZoomIn}
                  value="+"
                  style={{ cursor: "pointer", width: "20px", margin: "5px", "text-align": "right" }}
                  className=""
                  checkBill-btn-get-started
                ></input>
              </div>
              {numPagesArr.length > 0
                ? numPagesArr.map((page, index) => (
                    <li
                      id={page}
                      key={index}
                      onClick={handlePageClick}
                      style={{ display: "inline", cursor: "pointer", padding: "5px", "background-color": "#f24a", margin: "1px" }}
                    >
                      {page}
                    </li>
                  ))
                : null}
            </div>
          ) : (
            <p>Sélectionner un document</p>
          )}
        </div>
        <div className="sub-container" style={{ width: "35%" }}>
          <ModifyRecordForm
            record={selectedRecord}
            setRecord={setSelectedRecord}
            fields={[
              "[Link] Synchro base facturation (centrale)",
              "Synchro base facturation",
              "[Link] Investisseur (from Synchro base facturation)",
              "Partenaires",
              "[Date] Facture",
              "[Date] Echeance",
              "Numéro de facture",
              "Montant HT",
              "Montant TTC",
              "Montant TVA",
              "Entreprise facturée",
              "Facture à valider",
              "TVA ded auto",
              "TVA collectée auto",
            ]}
            fieldsOptions={fieldsOptions}
            title={selectedRecord.record["Name"]}
            baseId={selectedRecord.baseId}
            user={user}
            setUser={setUser}
            linkFieldOptions={linkFieldOptions}
            setLinkFieldOptions={setLinkFieldOptions}
            setModifyResaDivDisplay={setModifyResaDivDisplay}
            loading={loading}
            setLoading={setLoading}
            triggerGetRecords={triggerGetRecords}
            setTriggerGetRecords={setTriggerGetRecords}
            msgAfterUpdate={msgAfterUpdate}
            setMsgAfterUpdate={setMsgAfterUpdate}
            modifiedFieldType={modifiedFieldType}
            setModifiedFieldType={setModifiedFieldType}
          />
        </div>
        <div
          className="big-grey-div"
          style={{ display: modifyResaDivDisplay }}
          onClick={(e) => {
            handleResaClick(null, e);
          }}
        ></div>
        <div className="modify-resa-div" style={{ display: modifyResaDivDisplay }}>
          <h3>Choisir un record</h3>
          {linkFieldOptions.baseId !== "" && linkFieldOptions.tableName !== "" ? (
            <TableWithTextInput
              baseId={linkFieldOptions.baseId}
              tableName={linkFieldOptions.tableName}
              viewName={linkFieldOptions.viewName}
              recordOptions={linkedRecordOptions}
              setRecordOptions={setLinkedRecordOptions}
              user={user}
              setUser={setUser}
              displayFields={false}
              multipleRecordsSelect={true}
              selectedRecord={selectedLinkedRecord}
              setSelectedRecord={setSelectedLinkedRecord}
              loading={loading}
              setLoading={setLoading}
            />
          ) : null}
          {/* {linkedRecordOptions.length > 0
          ? linkedRecordOptions.map((opt, i) => {
              return (
                <ul key={i} value={opt["Name"]} id={opt["Name"]}>
                  {opt["Name"]}
                </ul>
              );
            })
          : null} */}
        </div>
      </div>
    </div>
  );
}

export default CheckBill;
