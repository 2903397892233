import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import "../../styles/CheckBill.css";
const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl.replace("3000", "4200");
if (baseUrl.includes("app-preprod")) {
  baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
} else if (baseUrl.includes("app")) {
  baseUrl4200 = baseUrl4200.replace("app", "api");
}
function ModifyRecordForm({
  record = {},
  setRecord,
  fields = [],
  title = "",
  fieldsOptions = [],
  user,
  setUser,
  linkFieldOptions,
  setLinkFieldOptions,
  setModifyResaDivDisplay,
  loading = 0,
  setLoading = null,
  longLoading = null,
  setLongLoading = null,
  triggerGetRecords = false,
  setTriggerGetRecords = null,
  msgAfterUpdate = {},
  setMsgAfterUpdate = null,
  modifiedFieldType = "",
  setModifiedFieldType = null,
}) {
  const [modifiedField, setModifiedField] = useState(null);

  let timer; //timer identifier
  let doneTimerInterval = 1000; //time in ms (1 second)

  const handleOnChange = (e) => {
    clearTimeout(timer);

    if (e.target.type !== "checkbox") {
      e.preventDefault();
    }
    console.log(e.target.type);
    setModifiedField(e.target.id);
    if (e.target.type === "number") {
      document.getElementById(e.target.id).value = document.getElementById(e.target.id).value.replace(".", ",");
      const { name, value } = e.target;
      record.record[name] = parseFloat(value);
      setRecord({ ...record });
    } else if (e.target.type === "checkbox") {
      const { name, checked } = e.target;
      record.record[name] = checked;
      setRecord({ ...record });
    } else {
      const { name, value } = e.target;
      record.record[name] = value;
      setRecord({ ...record });
    }
    console.log(record);
    setModifiedField(null);
    timer = setTimeout(updateChanges(e), doneTimerInterval);
  };

  const handleOnChangeAndUpdate = (e) => {
    if (e.target.type !== "checkbox") {
      e.preventDefault();
    }
    console.log(e.target.type);
    setModifiedField(e.target.id);
    if (e.target.type === "number") {
      document.getElementById(e.target.id).value = document.getElementById(e.target.id).value.replace(".", ",");
      const { name, value } = e.target;
      record.record[name] = parseFloat(value);
      setRecord({ ...record });
    } else if (e.target.type === "checkbox") {
      const { name, checked } = e.target;
      record.record[name] = checked;
      setRecord({ ...record });
    } else {
      const { name, value } = e.target;
      record.record[name] = value;
      setRecord({ ...record });
    }
    console.log(record);
    setModifiedField(null);

    // let recToModify = record;
    let recToModify = {};
    let recordId = record.record.id;
    let recordKeys = Object.keys(record.record);
    recordKeys.forEach((key) => {
      let j = fieldsOptions.findIndex((elt) => elt.name === key);
      if (fields.find((elt) => elt === key) && j > -1) {
        if (fieldsOptions[j].type !== "multipleLookupValues" && fieldsOptions[j].type !== "lookup" && fieldsOptions[j].type !== "formula")
          recToModify[key] = record.record[key];
      }
    });
    console.log(recToModify);

    if (user.token) {
      setLoading(loading + 1);
      axios
        .post(
          baseUrl4200 + "/airtableReq/updateRecord",
          {
            baseId: record.baseId,
            tableName: record.tableName,
            // viewName: viewName,
            fields: recToModify,
            recordId: recordId,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          setLoading(loading - 1);
          if (res.status === 200) {
            console.log(res);
            setTriggerGetRecords(true);
            // alert("Record updated");
            setMsgAfterUpdate({ message: "✔️ Record updated", display: "block" });
            // setRecordOptions({ records: res.data.records, baseId: baseId, tableName: tableName, viewName: viewName });
            // console.log(recordOptions);
          }
        })

        .catch((err) => {
          setLoading(loading - 1);
          // alert("A problem occurred");
          setMsgAfterUpdate({ message: "❌ A problem occurred", display: "block" });

          console.log(err);
        });
    }
  };

  // A améliorer
  useEffect(() => {
    // fields.forEach((field) => {
    console.log(modifiedField);
    if (modifiedField !== null) {
      let j = fieldsOptions.findIndex((e) => e.id === modifiedField);
      if (j > -1 && fieldsOptions[j].type === "multipleRecordLinks") {
        record.record[fieldsOptions[j].name].forEach((id) => {
          if (user.token) {
            setLoading(loading + 1);
            axios
              .post(
                baseUrl4200 + "/airtableReq/getRecord",
                {
                  baseId: record.baseId,
                  tableName: fieldsOptions[j].options.linkedTableId,
                  // recId: record.record[fieldsOptions[j].name],
                  recId: id,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${user.token}`,
                  },
                  mode: "cors",
                }
              )
              .then((res) => {
                setLoading(loading - 1);
                if (res.status === 200) {
                  console.log(res);
                  // res.data.records.forEach((rec, index) => {
                  //   // setRecordOptions(res.data.records);
                  // });
                  // setRecordOptions({ records: res.data.records, baseId: baseId, tableName: tableName, viewName: viewName });
                  console.log(res.data);
                  document.getElementById(id).textContent = res.data.record.fields.Name;
                }
              })
              .catch((err) => {
                setLoading(loading - 1);
                console.log(err);
              });
          }
        });
      }
    } else {
      console.log("Pour tous");
      console.log(fields);
      fields.forEach((field) => {
        let j = fieldsOptions.findIndex((e) => e.name === field);
        if (j > -1 && fieldsOptions[j].type === "multipleRecordLinks") {
          record.record[fieldsOptions[j].name].forEach((id) => {
            console.log("id", id);
            if (user.token) {
              setLoading(loading + 1);
              axios
                .post(
                  baseUrl4200 + "/airtableReq/getRecord",
                  {
                    baseId: record.baseId,
                    tableName: fieldsOptions[j].options.linkedTableId,
                    // recId: record.record[fieldsOptions[j].name],
                    recId: id,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                      Authorization: `Bearer ${user.token}`,
                    },
                    mode: "cors",
                  }
                )
                .then((res) => {
                  setLoading(loading - 1);
                  if (res.status === 200) {
                    console.log(res);

                    console.log(res.data);
                    document.getElementById(id).textContent = res.data.record.fields.Name;
                  }
                })
                .catch((err) => {
                  setLoading(loading - 1);
                  console.log(err);
                });
            }
          });
        }
      });
    }
    // });
  }, [record]);

  const handleMultipleRecordLinksOnClick = (e) => {
    e.preventDefault();
    setModifiedFieldType("multipleLinkRecord");

    // alert("click");
    let fdId = e.target.getAttribute("fieldid");
    console.log(e.target);
    console.log(fdId);
    let fieldName = e.target.getAttribute("fieldname");
    let view = null;
    if (fieldName === "Partenaires") view = "Partenaires";
    let j = fieldsOptions.findIndex((e) => e.id === fdId);
    if (j > -1) {
      setLinkFieldOptions({
        baseId: record.baseId,
        tableName: fieldsOptions[j].options.linkedTableId,
        viewName: view,
        fieldId: fdId,
        fieldName: e.target.getAttribute("fieldName"),
      });
      setModifyResaDivDisplay("flex");
    }
  };

  const updateChanges = (e) => {
    if (e) {
      e.preventDefault();
    }
    let recToModify = {};
    let recordId = record.record.id;
    let recordKeys = Object.keys(record.record);
    recordKeys.forEach((key) => {
      let j = fieldsOptions.findIndex((elt) => elt.name === key);
      if (fields.find((elt) => elt === key) && j > -1) {
        if (fieldsOptions[j].type !== "multipleLookupValues" && fieldsOptions[j].type !== "lookup" && fieldsOptions[j].type !== "formula")
          recToModify[key] = record.record[key];
      }
    });
    console.log(recToModify);

    if (user.token) {
      setLoading(loading + 1);
      axios
        .post(
          baseUrl4200 + "/airtableReq/updateRecord",
          {
            baseId: record.baseId,
            tableName: record.tableName,
            fields: recToModify,
            recordId: recordId,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          setLoading(loading - 1);
          if (res.status === 200) {
            console.log(res);
            setTriggerGetRecords(true);
            // alert("Record updated");
            setMsgAfterUpdate({ message: "✔️ Record updated", display: "block" });
          }
        })

        .catch((err) => {
          setLoading(loading - 1);
          // alert("A problem occurred");
          setMsgAfterUpdate({ message: "❌ A problem occurred", display: "block" });

          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (modifiedFieldType === "multipleLinkRecord") {
      updateChanges(null);
    }
  }, [modifiedFieldType, record]);

  useEffect(() => {
    console.log("fieldsOptions");
    console.log(fieldsOptions);
  }, [fieldsOptions]);

  return record.baseId !== "" ? (
    <div>
      {title !== "" ? <h3>{title}</h3> : null}
      <form style={{ padding: "30px" }}>
        {/* {fieldsOptions.map((field, index) => { */}
        {fields.map((field, index) => {
          // let recType = typeof record.record[field.name];
          // console.log(fieldsOptions);
          let k = fieldsOptions.findIndex((e) => e.name === field);
          if (fieldsOptions.length > 0 && k > -1) {
            // let recType = field.type;
            let recType = fieldsOptions[k].type;
            // console.log(record.record[field.name]);
            // console.log(recType);
            if (record.record[field] == undefined && recType !== "multipleRecordLinks") {
              record.record[field] = null;
            } else if (record.record[field] == undefined) {
              record.record[field] = [];
            }

            // return record.record.hasOwnProperty(field.name) && fields.findIndex((e) => e === field.name) > -1 ? (
            // return record.record.hasOwnProperty(field) ? (
            return (
              <div style={{ "text-align": "left", margin: "15px", display: "grid" }}>
                <label for="field">{field}</label>
                {console.log(field)}
                {console.log(record.record[field])}
                {recType === "number" && record.record[field] !== null ? (
                  <input
                    type={recType}
                    name={field}
                    id={fieldsOptions[k].id}
                    value={record.record[field]}
                    onChange={handleOnChange}
                    onBlur={updateChanges}
                    lang="en"
                    step="0.01"
                  />
                ) : recType === "number" && record.record[field] == null ? (
                  <input
                    type={recType}
                    name={field}
                    id={fieldsOptions[k].id}
                    value=""
                    onChange={handleOnChange}
                    onBlur={updateChanges}
                    lang="en"
                    step="0.01"
                  />
                ) : recType === "multipleRecordLinks" ? (
                  <div>
                    {record.record[field].length > 0 ? (
                      record.record[field].map((ref, ind) => {
                        return (
                          <ul
                            name={ref}
                            id={ref}
                            // id={field.id}
                            value={ref}
                            fieldid={fieldsOptions[k].id}
                            fieldName={field}
                            options={JSON.stringify(fieldsOptions[k].options)}
                            onClick={handleMultipleRecordLinksOnClick}
                            style={{ "list-style": "none", border: "1px gray solid", margin: "0", cursor: "pointer" }}
                          >
                            {ref}
                          </ul>
                        );
                      })
                    ) : (
                      <button
                        fieldid={fieldsOptions[k].id}
                        options={JSON.stringify(fieldsOptions[k].options)}
                        fieldName={field}
                        onClick={handleMultipleRecordLinksOnClick}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#f4af24",
                          border: "none",
                          "box-shadow": "1px 10px 10px gray",
                          "border-radius": "3px",
                          padding: "5px",
                          margin: "5px",
                        }}
                      >
                        Ajouter un élément
                      </button>
                    )}
                  </div>
                ) : recType === "lookup" || recType === "multipleLookupValues" ? (
                  <input
                    type={recType}
                    readOnly
                    name={field}
                    id={fieldsOptions[k].id}
                    value={record.record[field]}
                    onChange={handleOnChange}
                  />
                ) : recType === "checkbox" ? (
                  <input
                    style={{ cursor: "pointer" }}
                    type={recType}
                    name={field}
                    id={fieldsOptions[k].id}
                    checked={record.record[field]}
                    onChange={handleOnChangeAndUpdate}
                  />
                ) : (
                  <input
                    type={recType}
                    name={field}
                    id={fieldsOptions[k].id}
                    value={record.record[field]}
                    onChange={handleOnChange}
                    onBlur={updateChanges}
                  />
                )}
              </div>
            ); // );
            // null;
          }
        })}
        {/* {record.baseId !== "" ? (
          <input className="checkBill-btn-get-started" type="button" value="Valider" onClick={updateChanges} />
        ) : null} */}
      </form>
    </div>
  ) : null;
}
export default ModifyRecordForm;
