import "../styles/MapsChantier.css";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import React from "react";
import axios from "axios";

const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl.replace("3000", "4200");
if (baseUrl.includes("app-preprod")) {
  baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
} else if (baseUrl.includes("app")) {
  baseUrl4200 = baseUrl4200.replace("app", "api");
}
console.log("baseUrl4200 : " + baseUrl4200);
var map;

function MyMapComponent({ center, zoom, user, setUser }) {
  const ref = useRef();
  const [listeDeMarkers, setListeDeMarkers] = useState([]);
  const [listePartenaireToDisplay, setListePartenaireToDisplay] = useState([]);
  const [typePartenaire, setTypePartenaire] = useState(["Charpentier", "Maçon", "Installateur PV"]);
  const [loaded, setLoaded] = useState([]);

  useEffect(() => {
    if (user.token) {
      axios
        .post(
          baseUrl4200 + "/airtableReq/getRecords",
          {
            baseId: "appqe0gy4OaaiygZZ",
            tableName: "Centrale",
            viewName: "[Maps] Lot à réceptionner",
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);

            let listeDeMarkersTmp = [];

            res.data.records.forEach((rec) => {
              listeDeMarkersTmp.push({
                lat: parseFloat(rec["Coordonnées GPS PDL"][0].split(" ")[0]),
                lng: parseFloat(rec["Coordonnées GPS PDL"][0].split(" ")[1]),
                reference: rec["Référence centrale"],
                avancementInstallateur: rec["[Avan] Installateur PV"],
                avancementCharpentier: rec["[Avan] Charpentier"],
                avancementMaçon: rec["[Avan] Maçon"],
                googleMarker: null,
              });
            });
            setListeDeMarkers(listeDeMarkersTmp);
            setLoaded(true);

            console.log(listeDeMarkersTmp);
          }
        })

        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const handleCheckBoxClick = (e) => {
    console.log(e.target);
    let newArr = [...typePartenaire];
    let type = e.target.getAttribute("typePartenaire");
    if (e.target.checked === false) {
      if (type === "Charpentier") {
        let e = typePartenaire.findIndex((elt) => elt === "Charpentier");
        newArr.splice(e, 1);
      } else if (type === "Installateur PV") {
        let e = typePartenaire.findIndex((elt) => elt === "Installateur PV");
        newArr.splice(e, 1);
      } else if (type === "Maçon") {
        let e = typePartenaire.findIndex((elt) => elt === "Maçon");
        newArr.splice(e, 1);
      }
    } else if (e.target.checked === true) {
      if (type === "Charpentier") {
        newArr.push("Charpentier");
      } else if (type === "Installateur PV") {
        newArr.push("Installateur PV");
      } else if (type === "Maçon") {
        newArr.push("Maçon");
      }
    }
    setTypePartenaire(newArr);
  };

  useEffect(() => {
    map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  }, []);
  useEffect(() => {
    let tmpMarkersArray = [...listeDeMarkers];
    if (typePartenaire.length > 0) {
      tmpMarkersArray.forEach((marker) => {
        if (
          typePartenaire.find((elt) => elt === "Charpentier") &&
          (marker.avancementCharpentier == "Lot à réceptionner" || marker.avancementCharpentier === "Lot réceptionné avec réserves")
        ) {
          marker.googleMarker.setMap(map);
        } else if (
          typePartenaire.find((elt) => elt === "Maçon") &&
          (marker.avancementMaçon == "Lot à réceptionner" || marker.avancementMaçon === "Lot réceptionné avec réserves")
        ) {
          marker.googleMarker.setMap(map);
        } else if (
          typePartenaire.find((elt) => elt === "Installateur PV") &&
          (marker.avancementInstallateur == "Lot à réceptionner" || marker.avancementInstallateur === "Lot réceptionné avec réserves")
        ) {
          marker.googleMarker.setMap(map);
        } else {
          marker.googleMarker.setMap(null);
        }
      });
    } else {
      tmpMarkersArray.forEach((marker) => {
        marker.googleMarker.setMap(null);
      });
    }
    setListeDeMarkers(tmpMarkersArray);
  }, [typePartenaire]);

  useEffect(() => {
    console.log(typePartenaire);
  }, [typePartenaire]);

  var markerImage = null;
  var charpentierUrl = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";

  var maçonUrl = "http://maps.google.com/mapfiles/ms/icons/pink-dot.png";

  var installateurUrl = "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";

  var receptionUrl = "http://maps.google.com/mapfiles/ms/icons/purple-dot.png";

  useEffect(() => {
    let newArr = [...listeDeMarkers];

    newArr.forEach((mk) => {
      if (
        (mk.avancementMaçon == "Lot à réceptionner" || mk.avancementMaçon === "Lot réceptionné avec réserves") &&
        (mk.avancementCharpentier == "Lot à réceptionner" || mk.avancementCharpentier === "Lot réceptionné avec réserves")
      ) {
        markerImage = new window.google.maps.MarkerImage(receptionUrl);
      } else if (
        (mk.avancementMaçon == "Lot à réceptionner" || mk.avancementMaçon === "Lot réceptionné avec réserves") &&
        (mk.avancementInstallateur == "Lot à réceptionner" || mk.avancementInstallateur === "Lot réceptionné avec réserves")
      ) {
        markerImage = new window.google.maps.MarkerImage(receptionUrl);
      } else if (
        (mk.avancementCharpentier == "Lot à réceptionner" || mk.avancementCharpentier === "Lot réceptionné avec réserves") &&
        (mk.avancementInstallateur == "Lot à réceptionner" || mk.avancementInstallateur === "Lot réceptionné avec réserves")
      ) {
        markerImage = new window.google.maps.MarkerImage(receptionUrl);
      } else if (mk.avancementCharpentier == "Lot à réceptionner" || mk.avancementCharpentier === "Lot réceptionné avec réserves") {
        markerImage = new window.google.maps.MarkerImage(charpentierUrl);
      } else if (mk.avancementMaçon == "Lot à réceptionner" || mk.avancementMaçon === "Lot réceptionné avec réserves") {
        markerImage = new window.google.maps.MarkerImage(maçonUrl);
      } else if (mk.avancementInstallateur == "Lot à réceptionner" || mk.avancementInstallateur === "Lot réceptionné avec réserves") {
        markerImage = new window.google.maps.MarkerImage(installateurUrl);
      }
      let marker = new window.google.maps.Marker({
        position: mk,
        map: map,
        icon: markerImage,
      });
      marker.addListener("click", () => {
        map.setZoom(15);
        map.setCenter(marker.getPosition());
      });
      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map,
        });
      });

      let infoWindow = new window.google.maps.InfoWindow({
        content: mk.reference,
      });
      infoWindow.addListener("closeclick", () => {
        map.setZoom(8);
        map.setCenter(marker.getPosition());
      });
      infoWindow.addListener("closeclick", () => {
        infoWindow.close({});
      });
      mk.googleMarker = marker;
    });
    setListeDeMarkers(newArr);
  }, [loaded]);

  return (
    <div className="block">
      <div className="title">
        <h1>Lots à réceptionner ou Lots avec réserves</h1>
      </div>
      <div className="typePartenaire ">
        <div className="blockPartenaire">
          <div>
            <label for="Installateur PV ">Installateur PV </label>
            <input
              onChange={handleCheckBoxClick}
              typePartenaire="Installateur PV"
              defaultChecked={true}
              name="typePartenaire"
              type="checkbox"
            />
          </div>
          <div>
            <label for="Charpentier">Charpentier </label>{" "}
            <input
              onChange={handleCheckBoxClick}
              defaultChecked={true}
              typePartenaire="Charpentier"
              name="typePartenaire"
              type="checkbox"
            />
          </div>
          <div>
            <label for="Maçon">Maçon </label>{" "}
            <input onChange={handleCheckBoxClick} defaultChecked={true} typePartenaire="Maçon" name="typePartenaire" type="checkbox" />
          </div>
        </div>
        <div ref={ref} id="map" style={{ height: "100vh", width: "100vw", zindex: -10 }} />
      </div>
    </div>
  );
}

export default MyMapComponent;
