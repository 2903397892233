import { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";

const expandIcon = require("../../assets/icon-expand.png");
function StandardTable({ records, setRecords, fields, updatedRecord, setUpdatedRecord }) {
  const [sortBy, setSortBy] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [expandedRecord, setExpandedRecord] = useState(null);

  const [filterField, setFilterField] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const [editingCell, setEditingCell] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [displayExpand, setDisplayExpand] = useState("none");
  const [hoverRow, setHoverRow] = useState(-1);

  useEffect(() => {
    console.log("fields");
    console.log(fields);
  }, [fields]);
  useEffect(() => {
    console.log("records");
    console.log(records);
  }, [records]);

  const filteredRecords = records.filter((record) => {
    // if (!filterField || !record[filterField]) return true;
    if (!filterField) return true;

    if (typeof record[filterField] === "string") {
      return record[filterField].toLowerCase().includes(filterValue.toLowerCase());
    } else if (typeof record[filterField] === "number") {
      return record[filterField] === parseFloat(filterValue);
    } else if (record[filterField] instanceof Date) {
      return record[filterField].toString().includes(filterValue);
    } else if (filterValue === "isEmpty") {
      return record[filterField] === undefined;
    } else if (filterValue === "isNotEmpty") {
      return record[filterField] !== undefined;
    }
    if (!record[filterField] && filterField.toLowerCase().indexOf("attachment") === -1) return true;
    return false;
  });

  // create a function to handle sorting when a table header is clicked
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortAscending(!sortAscending);
    } else {
      setSortBy(field);
      setSortAscending(true);
    }
  };

  const sortedRecords = filteredRecords.sort((a, b) => {
    if (sortBy === "") return 0;
    if (typeof a[sortBy] === "string" && typeof b[sortBy] === "string") {
      a = a[sortBy].toLowerCase();
      b = b[sortBy].toLowerCase();
    } else if (!isNaN(parseFloat(a[sortBy])) && !isNaN(parseFloat(b[sortBy]))) {
      a = parseFloat(a[sortBy]);
      b = parseFloat(b[sortBy]);
    } else if (new Date(a[sortBy]) && new Date(b[sortBy])) {
      a = new Date(a[sortBy]).getTime();
      b = new Date(b[sortBy]).getTime();
    }
    if (sortAscending) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    } else {
      if (a > b) return -1;
      if (a < b) return 1;
      return 0;
    }
  });

  let selectedColumn;
  let initialX;
  let initialWidth;

  useEffect(() => {
    const thElements = document.querySelectorAll("table th");
    const tdElements = document.querySelectorAll("td");
    thElements.forEach((th) => {
      th.addEventListener("mousedown", (event) => {
        // save the selected column and its initial width
        selectedColumn = event.target;
        initialX = event.clientX;
        initialWidth = selectedColumn.clientWidth;

        // add event listeners for mousemove and mouseup
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      });
    });

    function handleMouseMove(event) {
      // calculate the new width of the selected column
      const newWidth = initialWidth + (event.clientX - initialX);
      selectedColumn.style.width = `${newWidth}px`;

      // select the corresponding td elements
      const selectedIndex = Array.prototype.indexOf.call(thElements, selectedColumn);
      const selectedTdElements = Array.prototype.filter.call(tdElements, (td, index) => {
        return index % thElements.length === selectedIndex;
      });

      // set the width of the selected td elements
      selectedTdElements.forEach((td) => {
        td.style.width = `${newWidth}px`;
      });
    }

    function handleMouseUp() {
      // remove event listeners for mousemove and mouseup
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
  }, [records]);

  function handleUpdate(field, value) {
    const newRecords = [...records];
    newRecords[editingCell.index][field] = value;
    setRecords(newRecords);
    let obj = { id: newRecords[editingCell.index].id, fields: {} };
    obj.fields[field] = newRecords[editingCell.index][field];
    // console.log(obj);
    setUpdatedRecord(obj);
    // setUpdatedRecord({ id: newRecords[editingCell.index].id, fields: { field: newRecords[editingCell.index][field] } });
  }

  const handleEdit = (index) => {
    setEditingCell({ index });
    // setModalIsOpen(true);
  };

  return (
    <div>
      <div>
        Filtrer par :
        <select
          value={filterField}
          onChange={(event) => {
            setFilterField(event.target.value);
            setFilterValue("");
          }}>
          <option value="">All</option>
          {/* {Object.keys(records[0]).map((field, index) => ( */}
          {fields.map((field, index) => (
            <option key={index} value={field.name}>
              {field.name}
            </option>
          ))}
        </select>
        {filterField &&
        fields[fields.findIndex((fd) => fd.name === filterField)].options &&
        fields[fields.findIndex((fd) => fd.name === filterField)].type !== "formula" &&
        fields[fields.findIndex((fd) => fd.name === filterField)].type !== "date" &&
        fields[fields.findIndex((fd) => fd.name === filterField)].type.toLowerCase().indexOf("attachment") === -1 ? (
          <select value={filterValue} onChange={(event) => setFilterValue(event.target.value)}>
            <option value="">-</option>
            {fields[fields.findIndex((fd) => fd.name === filterField)].options.choices.map((opt, i) => (
              <option key={i} value={opt.name}>
                {opt.name}
              </option>
            ))}
          </select>
        ) : filterField &&
          fields[fields.findIndex((fd) => fd.name === filterField)].options &&
          fields[fields.findIndex((fd) => fd.name === filterField)].type.toLowerCase().indexOf("attachment") !== -1 ? (
          <select value={filterValue} onChange={(event) => setFilterValue(event.target.value)}>
            <option value="">-</option>
            <option key={1} value={"isEmpty"}>
              is empty
            </option>
            <option key={2} value={"isNotEmpty"}>
              is not empty
            </option>
          </select>
        ) : filterField &&
          (!fields[fields.findIndex((fd) => fd.name === filterField)].options ||
            fields[fields.findIndex((fd) => fd.name === filterField)].type === "formula" ||
            fields[fields.findIndex((fd) => fd.name === filterField)].type === "date") ? (
          <input
            type={
              fields[fields.findIndex((fd) => fd.name === filterField)].type.toLowerCase().indexOf("text") !== -1 ||
              fields[fields.findIndex((fd) => fd.name === filterField)].type === "multipleCollaborators" ||
              fields[fields.findIndex((fd) => fd.name === filterField)].type === "formula"
                ? "text"
                : fields[fields.findIndex((fd) => fd.name === filterField)].type === "number"
                ? "number"
                : "date"
            }
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
          />
        ) : null}
      </div>

      <table className="standard-table">
        <thead>
          <tr>
            <th style={{ width: "20px", border: "none" }}></th>

            {records.length > 0 &&
              fields.length > 0 &&
              // Object.keys(records[0]).map((field, index) => (
              fields.map((field, index) => (
                <th onClick={() => handleSort(field.name)} key={index}>
                  {field.name} {sortBy === field.name ? (sortAscending ? "↑" : "↓") : null}
                </th>
              ))}
            {/* <th>Expand & Edit</th> */}
          </tr>
        </thead>
        <tbody>
          {records.length > 0 &&
            fields.length > 0 &&
            sortedRecords.map((record, index) => (
              <tr
                key={index}
                onMouseEnter={() => {
                  setDisplayExpand("block");
                  setHoverRow(index);
                }}
                onMouseLeave={() => {
                  setDisplayExpand("none");
                  setHoverRow(-1);
                }}>
                <td style={{ width: "20px", border: "none" }}>
                  {index === hoverRow ? (
                    <button
                      style={{
                        background: "#f4af24",
                        "border-radius": "30px",
                        border: "none",
                        cursor: "pointer",
                        "box-shadow": "1px 1px 10px gray",
                        display: "block",
                      }}
                      onClick={() => {
                        handleEdit(index);
                        setExpandedRecord(record);
                        console.log(record);
                      }}>
                      <img src={expandIcon} style={{ width: "15px" }} className="fas fa-expand"></img>
                    </button>
                  ) : null}
                </td>
                {/* {Object.keys(records[0]).map((field, index) => */}
                {fields.map((field, index) =>
                  record[field.name] ? (
                    Array.isArray(record[field.name]) && fields.find((fd) => fd.name === field.name) ? (
                      <td key={index}>
                        {record[field.name].map((e, i) =>
                          typeof e === "object" ? (
                            e.name ? (
                              e.name
                            ) : e.url ? (
                              e.thumbnails ? (
                                <a href={e.url} target="_blank">
                                  <img style={{ border: "1px solid black", margin: "2px" }} src={e.thumbnails.small.url} alt="" />
                                </a>
                              ) : (
                                <a href={e.url} target="_blank">
                                  {e.url}
                                </a>
                              )
                            ) : (
                              Object.keys(e).map((fd, ind) => (typeof e[fd] === "object" ? "Object" : e[fd]))
                            )
                          ) : (
                            { e }
                          )
                        )}
                      </td>
                    ) : // )
                    typeof record[field.name] === "object" ? (
                      <td key={index}>Object</td>
                    ) : (
                      // <td key={index} contentEditable={true} onBlur={(event) => handleUpdate(field, event.target.innerText)}>
                      <td
                        key={index}
                        // contentEditable={fields.length > 0 ? (fields.find((elt) => elt.name === field).editable ? true : false) : false}
                        // onBlur={(event) => handleUpdate(field, event.target.innerText)}
                      >
                        {record[field.name]}
                      </td>
                    )
                  ) : (
                    <td key={index}></td>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>

      {expandedRecord && (
        <div className="expanded-record">
          <button
            onClick={() => {
              setExpandedRecord(null);
              setEditingCell(null);
            }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              "font-weight": "bold",
              color: " #9c0d0d",
              "font-size": "x-large",
            }}>
            X
          </button>
          {/* {Object.keys(expandedRecord).map((field) => ( */}
          {fields.map((field) => (
            <div key={field.name} style={{ display: "flex", margin: "5px 0" }}>
              {/* <strong>{field}:</strong> {expandedRecord[field]} */}
              <div
                style={{
                  width: "20%",
                  "text-align": "left",
                  // color: fields.length > 0 ? (fields.find((elt) => elt.name === field).editable ? "black" : "gray") : "gray",
                  color: fields.length > 0 ? (field.editable ? "black" : "gray") : "gray",
                }}>
                {field.name} :
              </div>
              {field.type === "date" ? (
                <input
                  type="date"
                  // onBlur={(event) => handleUpdate(field, event.target.value)}
                  readOnly={fields.length > 0 ? (field.editable ? false : true) : true}
                  value={expandedRecord[field.name]}
                  onChange={(event) => handleUpdate(field.name, event.target.value)}
                  style={{
                    width: "70%",
                    "text-align": "left",
                    "background-color": "white",
                    "border-radius": "2px",
                    "border": "1px solid #d3d3d3",
                    "padding": "5px",
                    "background-color": fields.length > 0 ? (field.editable ? "white" : "#fafafa") : "#fafafa",
                    color: fields.length > 0 ? (field.editable ? "black" : "gray") : "gray",
                  }}>
                  {/* {new Date(expandedRecord[field])} */}
                </input>
              ) : (
                <div
                  style={{
                    width: "70%",
                    "text-align": "left",
                    "background-color": "white",
                    "border-radius": "2px",
                    "border": "1px solid #d3d3d3",
                    "padding": "5px",
                    "background-color": fields.length > 0 ? (field.editable ? "white" : "#fafafa") : "#fafafa",
                    color: fields.length > 0 ? (field.editable ? "black" : "gray") : "gray",
                  }}
                  contentEditable={fields.length > 0 ? (field.editable ? true : false) : false}
                  onBlur={
                    fields.length > 0
                      ? field.type.toLowerCase().indexOf("attachment") === -1
                        ? (event) => handleUpdate(field.name, event.target.innerText)
                        : null
                      : null
                  }>
                  {Array.isArray(expandedRecord[field.name]) ? (
                    expandedRecord[field.name].map((e, i) =>
                      typeof e === "object" ? (
                        e.name ? (
                          e.name
                        ) : e.url ? (
                          e.thumbnails ? (
                            <a href={e.url} target="_blank">
                              <img style={{ border: "1px solid black", margin: "2px" }} src={e.thumbnails.small.url} alt="" />
                            </a>
                          ) : (
                            <a href={e.url} target="_blank">
                              {e.url}
                            </a>
                          )
                        ) : (
                          Object.keys(e).map((fd, ind) => (typeof e[fd] === "object" ? "Object" : e[fd]))
                        )
                      ) : (
                        { e }
                      )
                    )
                  ) : typeof expandedRecord[field] === "object" ? (
                    "Object"
                  ) : field.type === "richText" ? (
                    <Markdown>{expandedRecord[field.name]}</Markdown>
                  ) : (
                    expandedRecord[field.name]
                  )}
                  {/* ici on vérifie field.type = "multilineText" */}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default StandardTable;
