import { useState } from "react";
import backgroundHome from "../assets/backgroundEDLIntranet2-3.png";
import "../styles/Connexion.css";
import axios from "axios";

function Connexion({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected }) {
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [firstVisit, setFirstVIsit] = useState(false);
  const [wrongPwd, setWrongPwd] = useState(false);
  const [unactivatedAcount, setUnactivatedAcount] = useState(false);
  const [UserNotFoundOrUnactivatedAcount, setUserNotFOundOrUnactivatedAcount] = useState(false);
  const [createAcountAsked, setCreateAcountAsked] = useState(false);
  const [userProject, setUserProject] = useState({
    userEmail: "",
    centraleName: "",
    projectName: "",
    token: "",
    company: "",
  });

  console.log(process.env.NODE_ENV);
  console.log(window.location);
  const baseUrl = window.location.origin;
  // const protocol = window.location.protocol;

  const handleSendEmail = (e) => {
    e.preventDefault();

    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    // var userPwd = document.getElementById("pwdForm").value;

    if (userMail === "") {
      return;
    }
    console.log(userMail);
    // console.log(userPwd);

    //Vérifier que les identifiants sont renseignés avant envoi inutile.
    axios
      .post(
        baseUrl + "/api/auth/getUser",
        // "https://app.energiesdeloire.com/api/auth/getUser",
        // "http://localhost:3000/api/auth/getUser",
        // { email: userMail, password: userPwd },
        { email: userMail },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
            return setUserNotFOundOrUnactivatedAcount(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        if (response.status === 200) {
          setUserNotFOundOrUnactivatedAcount(false);
        }

        console.log(response);
        console.log(response.data);
        var theRes = response.data;
        if (theRes.firstVisit === true) {
          setFirstVIsit(true);
        }

        setLoading(false);
        setEmailSent(true);

        console.log(theRes);
      });
  };

  const handlecreateAcount = (e) => {
    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;

    if (userMail === "") {
      return;
    }
    console.log(userMail);

    axios
      .post(
        baseUrl + "/api/auth/createAcount",

        { email: userMail },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setLoading(false);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        if (response.status === 200) {
          setUserNotFOundOrUnactivatedAcount(false);
          setCreateAcountAsked(true);
        }

        console.log(response);
        console.log(response.data);
        var theRes = response.data;

        setLoading(false);

        console.log(theRes);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();

    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    var userPwd = document.getElementById("pwdForm").value;
    var confirmPwdForm = document.getElementById("confirmPwdForm").value;

    if (userPwd !== confirmPwdForm) {
      alert("Les mots de passe renseignés ne sont pas identques.");
      return;
    }

    axios
      .post(
        baseUrl + "/api/auth/signup",
        // "https://app.energiesdeloire.com/api/auth/signup",
        // "http://localhost:3000/api/auth/signup",
        { email: userMail, password: userPwd, confirmPwd: confirmPwdForm },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            setUserNotFOundOrUnactivatedAcount(true);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        var theRes = response.data;
        // if ((theRes.firstVisit = true)) {
        //   setFirstVIsit(true);
        // }

        setLoading(false);
        if (response.status === 200) {
          alert("Vous allez recevoir un mail de confirmation. Celui vous permettra d'activer votre compte EDL.");
          window.location.href = baseUrl;
        }
        // setEmailSent(true);

        // axios
        //   .post(
        //     baseUrl + "/api/auth/activateUser",

        //     { email: userMail },
        //     {
        //       headers: {
        //         "Content-type": "application/json",
        //       },
        //       mode: "cors",
        //     }
        //   )
        //   .catch(function (error) {
        //     if (error.response) {
        //       // The request was made and the server responded with a status code
        //       // that falls out of the range of 2xx
        //       console.log(error.response.data);
        //       console.log(error.response.status);
        //       console.log(error.response.headers);
        //       if (error.response.status === 401) {
        //         setLoading(false);
        //       }
        //     } else if (error.request) {
        //       // The request was made but no response was received
        //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //       // http.ClientRequest in node.js
        //       console.log(error.request);
        //     } else {
        //       // Something happened in setting up the request that triggered an Error
        //       console.log("Error", error.message);
        //     }
        //     console.log(error.config);
        //     setLoading(false);
        //   })
        //   .then((response) => {
        //     if (response.status === 200) {
        //       alert(
        //         "Vous allez recevoir un mail de confirmation. Celui vous permettra d'activer votre compte EDL."
        //       );
        //       window.location.href = baseUrl;
        //     }

        //     console.log(response);
        //     console.log(response.data);
        //     var theRes = response.data;

        //     setLoading(false);

        //     console.log(theRes);
        //   });

        console.log(theRes);
        // if (response.status === 200) {
        //   // window.location.href = "http://localhost:3000/depotfacture";
        //   window.location.href = baseUrl + "/depotfacture";
        //   // window.location.href = "https://app.energiesdeloire.com/depotfacture";
        // }
      });
  };
  const handleSignin = (e) => {
    e.preventDefault();
    setLoading(true);

    var userMail = document.getElementById("emailForm").value;
    var userPwd = document.getElementById("pwdForm").value;

    axios
      .post(
        // "http://localhost:3000/api/auth/login",
        // "https://app.energiesdeloire.com/api/auth/login",
        baseUrl + "/api/auth/login",

        { email: userMail, password: userPwd },
        {
          headers: {
            "Content-type": "application/json",
          },
          mode: "cors",
        }
      )
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.status === 401) {
            if (error.response.data.error === "Mot de passe incorrect !") {
              setWrongPwd(true);
            } else if (error.response.data.error === "Compte non activé !") {
              setUnactivatedAcount(true);
            }
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        var theRes = response.data;

        setLoading(false);

        console.log(theRes);
        if (response.status === 200) {
          setIsConnected(true);
          // window.location.href = "http://localhost:3000/depotfacture";
          // window.location.href = "https://app.energiesdeloire.com/depotfacture";
          //Request to getUserAndProjectInfo
          axios
            .get("/userProject")
            .then((res) => (res.status !== 201 ? (window.location.href = baseUrl) : (window.location.href = baseUrl + "/depotfacture")))
            .catch((err) => {
              console.log(err);
              window.location.href = baseUrl;
            });

          // window.location.href = baseUrl;

          // window.location.href = baseUrl + "/depotfacture";
        } else if (response.status === 401) {
          setWrongPwd(true);
        }
      });
  };
  return (
    <div className="container-Home" onClick={() => setResponsiveMenuVisible(false)}>
      {loading ? <div id="spinner-modal"></div> : null}

      <div className="background-div">
        <img className="background-img" src={backgroundHome} alt="background-EDL" />
      </div>

      <div className="EDL-home-title">
        <p>ENERGIES DE LOIRE ☀️</p>
        <h1>Connexion</h1>
      </div>
      <div class="login-card">
        <h2>Accéder à l'application EDL</h2>
        <form>
          <div className="div-item">
            <label for="email">Email</label>
            <input id="emailForm" type="email" name="email" required />
          </div>
          {emailSent ? (
            <div className="div-item">
              <label for="password">Mot de passe</label>
              <input type="password" name="password" required id="pwdForm" placeholder="Tapez votre mot de passe" />
            </div>
          ) : null}
          {firstVisit ? (
            <div className="div-item">
              <label for="password">Confirmer le mot de passe</label>
              <input type="password" name="password" required id="confirmPwdForm" placeholder="Tapez de nouveau votre mot de passe" />
            </div>
          ) : null}
          {UserNotFoundOrUnactivatedAcount ? (
            <div>
              <p style={{ color: "red" }}>Utilisateur non trouvé ou compte non activé. Merci de contacter it@energiesdeloire.com</p>
              <p style={{ color: "red" }}>
                Vous pouvez également envoyer une demande de création de compte en cliquant sur le bouton suivant.
              </p>
              <button className="formButton" onClick={handlecreateAcount}>
                Créer un compte
              </button>
            </div>
          ) : null}
          {createAcountAsked ? (
            <div>
              <p style={{ color: "green" }}>
                Votre demande a bien été transmise à Energies de Loire. Vous recevrez rapidement un email confirmant la création de votre
                compte utilisateur. Merci de votre confiance.
              </p>
              <a href="https://portail.energiesdeloire.com" id="backToPortail" className="btn-get-started">
                Retour vers Energies de Loire.
              </a>
            </div>
          ) : null}
          {/* <div className="div-item">
            <label for="password">Mot de passe</label>
            <input
              type="password"
              name="password"
              required
              // data-ms-member="password"
              id="pwdForm"
            />
          </div> */}
          {!createAcountAsked ? (
            <div>
              {" "}
              {!emailSent ? (
                <div className="div-item" id="form-button">
                  <button className="formButton" onClick={handleSendEmail}>
                    Se connecter
                  </button>
                </div>
              ) : (
                <div>
                  {firstVisit ? (
                    <div className="div-item" id="form-button">
                      <button className="formButton" onClick={handleSignup}>
                        Se connecter
                      </button>
                    </div>
                  ) : (
                    <div className="div-item">
                      {wrongPwd ? <p style={{ color: "red" }}>Le mot de passe renseigné n'est pas correct.</p> : null}
                      {unactivatedAcount ? (
                        <p style={{ color: "red" }}>
                          Le compte utilisateur n'est pas encore activé. Merci de contacter it@energiesdeloire.com
                        </p>
                      ) : null}
                      <button className="formButton" id="form-button" onClick={handleSignin}>
                        Se connecter
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {/* {!emailSent ? (
            <div className="div-item" id="form-button">
              <button className="formButton" onClick={handleSendEmail}>
                Se connecter
              </button>
            </div>
          ) : (
            <div>
              {firstVisit ? (
                <div className="div-item" id="form-button">
                  <button className="formButton" onClick={handleSignup}>
                    Se connecter
                  </button>
                </div>
              ) : (
                <div className="div-item">
                  {wrongPwd ? (
                    <p style={{ color: "red" }}>
                      Le mot de passe renseigné n'est pas correct.
                    </p>
                  ) : null}
                  {unactivatedAcount ? (
                    <p style={{ color: "red" }}>
                      Le compte utilisateur n'est pas encore activé. Merci de
                      contacter it@energiesdeloire.com
                    </p>
                  ) : null}
                  <button
                    className="formButton"
                    id="form-button"
                    onClick={handleSignin}
                  >
                    Se connecter
                  </button>
                </div>
              )}
            </div>
          )} */}
          {/* {firstVisit ? (
            <div className="div-item">
              <button className="formButton" onClick={handleSignup}>
                Se connecter
              </button>
            </div>
          ) : (
            <div className="div-item">
              <button className="formButton" onClick={handleSignin}>
                Se connecter
              </button>
            </div>
          )} */}
          {/* <div className="div-item">
            <button className="formButton" onClick={handleSendEmail}>
              Se connecter
            </button>
          </div> */}
          {/* <a href="#/ms/password-reset" class="reset">
            Mot de passe oublié ? Cliquez ici pour changer votre mot de passe.
          </a> */}
        </form>
      </div>
    </div>
  );
}

export default Connexion;
