import { useState } from "react";
import { useEffect } from "react";
import StandardTable from "./Standard/StandardTable";
import "../styles/tableTest.css";
// import Ticket from "./Ticket";

function UserTicketsList2({ user, setUser }) {
  const [myTickets, setMyTickets] = useState([]);
  const [ticketFields, setTicketFields] = useState([]);
  const [updatedRecord, setUpdatedRecord] = useState({});
  const axios = require("axios");

  useEffect(() => {
    if (Object.keys(updatedRecord).length > 0) {
      console.log(updatedRecord);
      // socket.send(JSON.stringify(updatedRecord));
      updateTicket(updatedRecord);
    } else {
      console.log("Nothing to send to server.");
    }
  }, [updatedRecord]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (user.email !== "" && user.token !== "") {
        console.log(user);
        console.log("Looking for updates...");
        getMyTickets();
      }
    }, 8000);
    return () => {
      clearInterval(intervalId);
      console.log("Interval cleared:", intervalId);
    };
  }, [user.email, user.token]);

  const fieldsToDisplay = [
    {
      name: "id",
      editable: false,
    },
    {
      name: "Name",
      editable: true,
    },
    {
      name: "Référence ticket",
      editable: false,
    },
    {
      name: "Description",
      editable: true,
    },
    {
      name: "Priority",
      editable: false,
    },
    {
      name: "Bug-Amélioration",
      editable: false,
    },
    {
      name: "Catégorie",
      editable: false,
    },
    {
      name: "Status",
      editable: false,
    },
    {
      name: "Date de dépôt",
      editable: false,
    },
    {
      name: "Responsable service",
      editable: false,
    },
    {
      name: "Pris en charge par",
      editable: false,
    },
    {
      name: "Deadline",
      editable: false,
    },
    {
      name: "Attachments",
      editable: false,
    },
  ];

  const baseUrl = window.location.origin;
  var baseUrl4200 = baseUrl.replace("3000", "4200");
  if (baseUrl.includes("app-preprod")) {
    baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
  } else if (baseUrl.includes("app")) {
    baseUrl4200 = baseUrl4200.replace("app", "api");
  }
  useEffect(() => {
    console.log("user");
    console.log(user);
    if (user.email !== "" && user.token !== "") {
      getMyTickets();
      getTicketsFields();
    }
  }, [user]);

  function getTicketsFields() {
    const baseId = "appdcpGp5m9QJ9ye4";
    const tableName = "tbl8Tpyv9p2CFh28d";

    axios
      .post(
        baseUrl4200 + "/airtableReq/getFields",
        { baseId: `${baseId}`, tableName: `${tableName}` },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((response) => {
        console.log(response);
        let fields = response.data.tableName.fields;
        fieldsToDisplay.forEach((e) => {
          if (fields.findIndex((field) => field.name === e.name) !== -1) {
            fields[fields.findIndex((field) => field.name === e.name)].editable = e.editable;
          }
        });
        fields = fields.filter((field) => fieldsToDisplay.find((fd) => fd.name === field.name));

        console.log(fields);
        setTicketFields(fields);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getMyTickets() {
    console.log(user);
    axios
      .post(
        baseUrl4200 + "/ticket/userTickets",
        { userEmail: `${user.email}` },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((res) => {
        res.data.ticketsArray.forEach((ticket) => {
          // console.log(Object.keys(ticket));
          Object.keys(ticket).forEach((field) => {
            let j = fieldsToDisplay.find((ftd) => ftd.name === field);
            if (j) {
            } else {
              delete ticket[field];
            }
          });
        });

        const firstObject = res.data.ticketsArray[0];
        const firstObjectKeys = Object.keys(firstObject);

        for (let i = 1; i < res.data.ticketsArray.length; i++) {
          let obj = res.data.ticketsArray[i];
          let objKeys = Object.keys(obj);
          if (!(objKeys.length === firstObjectKeys.length && objKeys.every((v, i) => v === firstObjectKeys[i]))) {
            obj = Object.fromEntries(Object.entries(obj).sort((a, b) => firstObjectKeys.indexOf(a[0]) - firstObjectKeys.indexOf(b[0])));
          }
        }

        setMyTickets(res.data.ticketsArray);
        console.log(res.data.ticketsArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateTicket(record) {
    axios
      .post(
        baseUrl4200 + "/airtableReq/updateRecord",
        {
          baseId: `appdcpGp5m9QJ9ye4`,
          tableName: `tbl8Tpyv9p2CFh28d`,
          fields: record.fields,
          recordId: record.id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          mode: "cors",
        }
      )
      .then((res) => {
        console.log("record updated");
        // console.log(res);
        setUpdatedRecord({});
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div id="containerMyTickets">
      <h2>Mes Tickets</h2>
      {myTickets.length === 0 ? (
        <p>Vous n'avez encore déposé aucun ticket. C'est que tout va bien, pour le moment !</p>
      ) : (
        <div>
          <StandardTable
            records={myTickets}
            setRecords={setMyTickets}
            fields={ticketFields}
            updatedRecord={updatedRecord}
            setUpdatedRecord={setUpdatedRecord}
          />
        </div>
      )}
    </div>
  );
}

export default UserTicketsList2;
