import backgroundHome from "../assets/backgroundEDLIntranet2-1.png";
import APEDL from "../assets/AP-EDL5-resized2.png";

import "../styles/EDLHome.css";
import edlInstitutionnel from "../assets/EDL-logo.png";
import edlPortails from "../assets/partenaire.png";
import edlIntranet from "../assets/intranet2.png";
import edlTickets from "../assets/ticket.png";
import verifierFacture from "../assets/verifier-facture.png";
import edlFaqReferents from "../assets/faq-referent.png";
import edlMaps from "../assets/maps.png";
import edlReservationSalle from "../assets/reucalendar (2).png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function Home({ responsiveMenuVisible, setResponsiveMenuVisible, isConnected, setIsConnected, user, setUser, error, setError }) {
  const [interfacesSection, setInterfacesSection] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Créer un Array pour l'affichage des interfaces par section (interface) de la sorte
    // [
    //   {
    //     service:"BE Technique",
    //     name:"blabla",
    //     id: "azerty123456789",
    //   },
    //   {
    //     service:"Comptabilité",
    //     name:"blibli",
    //     id: "azerty987654321",
    //   },
    // ]
    let finalArr = [];

    if (user.user) {
      setLoading(false);
      user.user.interfaces.forEach((elt, i) => {
        let tmpServiceName = "";
        elt.services.map((s) => {
          if (tmpServiceName === "") {
            tmpServiceName += s;
          } else {
            tmpServiceName += " | " + s;
          }
        });
        let j = finalArr.findIndex((section) => section.service === tmpServiceName);

        if (j > -1) {
          finalArr[j].details.push(elt);
        } else {
          finalArr.push({
            service: tmpServiceName,
            details: [elt],
          });
        }
      });
      console.log("finalArr", finalArr);
      finalArr.sort((a, b) => a["service"].localeCompare(b["service"]));

      setInterfacesSection(finalArr);
    }
  }, [user]);

  useEffect(() => {
    console.log(error);
    setLoading(false);
  }, [error]);

  return (
    <div
      className="container-Home"
      style={{
        "background-image": `url(${APEDL})`,
        "min-height": "100vh",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-position": "top",
      }}
      onClick={() => setResponsiveMenuVisible(false)}>
      {loading ? (
        <div>
          <div id="spinner-modal"></div>
        </div>
      ) : null}
      <div className="EDL-home-title">
        <p>ENERGIES DE LOIRE ☀️</p>
        <h1>Mes interfaces</h1>
      </div>
      {error.message ? (
        <p>{error.message}</p>
      ) : (
        <div>
          <h2>Interfaces</h2>
          <div>
            {user.user ? (
              <div>
                {interfacesSection.length === 0 ? (
                  <p>Vous n'avez aucune interface spécifique...</p>
                ) : (
                  <div className="flex-container-home">
                    {interfacesSection.map((section, index) => {
                      return (
                        <div className="flex-subcontainer-home" id={index + 1 + "-" + section.service}>
                          <div className="flex-vertical-subcontainer-home">
                            <h3>{section.service}</h3>
                            {section.details.map((elt) => {
                              return (
                                <a className="btn-get-started-home" href={elt.url} target="_blank">
                                  <img src={elt.logoUrl} alt="reucalendar(2)" />
                                  <h4>{elt.name}</h4>
                                  <p>{elt.description}</p>
                                </a>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </div>{" "}
        </div>
      )}
      <div className="three-color-separator" style={{ "width": "50%", "margin-top": "30px", "margin-bottom": "30px" }}></div>

      <h2>Nos portails</h2>
      <div className="home-button">
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://www.energiesdeloire.com">
            <img className="img-edl" src={edlInstitutionnel} alt="edl-institutionnel" />
            <h4>energiesdeloire.com</h4>
            <p>Lien vers le site institutionnel d'EDL.</p>
          </a>
        </div>
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://portail.energiesdeloire.com">
            <img src={edlPortails} alt="edl-portails" />
            <h4> Portail partenaires</h4>
            <p>Lien vers les portails partenaire / client.</p>
          </a>
        </div>
        <div className="button-alone">
          <a className="btn-get-started-home" href="https://intranet.energiesdeloire.com">
            <img src={edlIntranet} alt="edl-intranet" />
            <h4>Intranet</h4>
            <p>Lien vers l'intranet EDL.</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
