// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { useState } from "react";
import "../styles/App.css";
import Footer from "./Footer";
import DepotFacture from "./DepotFacture";
import EDLBanner from "./EDLBanner";
import Home from "./Home";
import Connexion from "./Connexion";
import ConnexionIntranet from "./ConnexionIntranet";
import DeposeTickets from "./DeposeTickets";
import ReuCalendar from "./ReuCalendar";
import Diy from "./Diy";
import Faq from "./ReferentIT/Faq";
import CheckBill from "./Comptabilite/CheckBill";
import MapsChantier from "./MapsChantier";
import StandardError from "./Errors/StandardError";
import background from "../assets/backgroundEDLIntranet2-3.png";
import { useEffect } from "react";
import axios from "axios";
import CreateCustomButton from "./ReferentIT/CreateCustomButton";
import DeposeTickets2 from "./DeposeTickets2";
import RichTextEditor from "./RichTextEditor";
import FaqEditor from "./FaqEditor";
import FaqViewer from "./FaqViewer";

function App() {
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    axios
      // .get("/appUser")
      .get("/user-ssn")
      .catch((err) => {
        console.log("err");
        console.log(err);
        setIsConnected(false);
        if (err.response) {
          if (err.response.status === 403) {
            return setError({ messsage: "Connexion non autorisée", status: 403 });
          } else {
            return setError({ messsage: "Connexion non autorisée", status: "ERR" });
          }
        } else {
          setIsConnected(false);
          return setError({ messsage: "Connexion non autorisée", status: "" });
        }
      })
      .then((res) => {
        if (res) {
          console.log(res);
          if (res.status === 200) {
            setIsConnected(true);
            setUser(res.data);
            console.log(res.data);
          }
        } else {
          setIsConnected(false);
          return setError({ messsage: "Connexion non autorisée", status: "" });
        }
      });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/connexion"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Connexion
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                />
              </div>
            }
          />
          <Route
            path="/"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Home
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                  error={error}
                  setError={setError}
                />
              </div>
            }
          />
          <Route
            path="/connexion-intranet"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <ConnexionIntranet
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                />
              </div>
            }
          />
          <Route
            path="/tickets-ancien"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <DeposeTickets
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/tickets"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <DeposeTickets2
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/depotfacture"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <DepotFacture
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/faq-edl"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <Faq
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/faq-editor"
            element={
              <div>
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <FaqEditor user={user} setUser={setUser} />
              </div>
            }
          />
          <Route
            path="/faq-viewer"
            element={
              <div>
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <FaqViewer user={user} setUser={setUser} />
              </div>
            }
          />

          <Route
            path="/verifier-facture-achat"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <CheckBill
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  background={background}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/iframe/createCustomButton"
            element={
              <div>
                <CreateCustomButton />
              </div>
            }
          />
          <Route
            path="/reservationSalle"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <ReuCalendar
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  background={background}
                  user={user}
                  setUser={setUser}
                />
              </div>
            }
          />
          <Route
            path="/mapsChantier"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <MapsChantier user={user} setUser={setUser} />
              </div>
            }
          />
          <Route
            path="/error"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                  isConnected={isConnected}
                  setIsConnected={setIsConnected}
                  user={user}
                  setUser={setUser}
                />
                <StandardError responsiveMenuVisible={responsiveMenuVisible} setResponsiveMenuVisible={setResponsiveMenuVisible} />
              </div>
            }
          />
        </Routes>
        <Footer responsiveMenuVisible={responsiveMenuVisible} setResponsiveMenuVisible={setResponsiveMenuVisible} />
      </div>
    </BrowserRouter>
  );
}

export default App;
