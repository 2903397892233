import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import "../../styles/standard/tableWithSelect.css";
const baseUrl = window.location.origin;
var baseUrl4200 = baseUrl.replace("3000", "4200");
if (baseUrl.includes("app-preprod")) {
  baseUrl4200 = baseUrl4200.replace("app-preprod", "api");
} else if (baseUrl.includes("app")) {
  baseUrl4200 = baseUrl4200.replace("app", "api");
}
function TableWithTextInput({
  baseId,
  tableName,
  viewName,
  styleOptions = {},
  recordOptions,
  setRecordOptions,
  fieldsOptions = [],
  setFieldsOptions = null,
  selectedRecord = {},
  setSelectedRecord = null,
  user,
  setUser,
  displayFields,
  multipleRecordsSelect,
  loading = 0,
  setLoading = null,
  longLoading = null,
  setLongLoading = null,
  triggerGetRecords = false,
  setTriggerGetRecords = null,
}) {
  const [search, setSearch] = useState("");

  useEffect(() => {
    console.log("setFieldsOptions");
    if (user.token && displayFields) {
      setLoading(loading + 1);

      axios
        .post(
          baseUrl4200 + "/airtableReq/getFields",
          {
            baseId: baseId,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            mode: "cors",
          }
        )
        .then((res) => {
          setLoading(loading - 1);
          if (res.status === 200) {
            console.log(res);
            console.log(res.data);
            console.log("baseId", baseId);
            console.log("tableName", tableName);
            let i = res.data.tables.findIndex((elt) => elt.id === tableName || elt.name === tableName);
            console.log("i", i);
            setFieldsOptions(res.data.tables[i].fields);
            console.log(res.data.tables[i].fields);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(loading - 1);
        });
    }
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (user.token) {
        // setLoading(loading + 1);
        axios
          .post(
            baseUrl4200 + "/airtableReq/getRecords",
            {
              baseId: baseId,
              tableName: tableName,
              viewName: viewName,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
              mode: "cors",
            }
          )
          .then((res) => {
            // setLoading(loading - 1);
            if (res.status === 200) {
              console.log(res);
              res.data.records.forEach((rec, index) => {});
              setRecordOptions({ records: res.data.records, baseId: baseId, tableName: tableName, viewName: viewName });
              console.log(recordOptions);
              setTriggerGetRecords(false);
            }
          })

          .catch((err) => {
            setLoading(loading - 1);
            console.log(err);
          });
      }
    }, 8000);
    return () => {
      clearInterval(intervalId);
      console.log("Interval cleared:", intervalId);
    };
  }, [user.token]);

  // useEffect(() => {
  //   if (user.token) {
  //     setLoading(loading + 1);
  //     axios
  //       .post(
  //         baseUrl4200 + "/airtableReq/getRecords",
  //         {
  //           baseId: baseId,
  //           tableName: tableName,
  //           viewName: viewName,
  //         },
  //         {
  //           headers: {
  //             "Content-type": "application/json",
  //             Authorization: `Bearer ${user.token}`,
  //           },
  //           mode: "cors",
  //         }
  //       )
  //       .then((res) => {
  //         setLoading(loading - 1);
  //         if (res.status === 200) {
  //           console.log(res);
  //           res.data.records.forEach((rec, index) => {});
  //           setRecordOptions({ records: res.data.records, baseId: baseId, tableName: tableName, viewName: viewName });
  //           console.log(recordOptions);
  //           setTriggerGetRecords(false);
  //         }
  //       })

  //       .catch((err) => {
  //         setLoading(loading - 1);
  //         console.log(err);
  //       });
  //   }
  // }, [user, triggerGetRecords]);

  const handleInputOnChange = (e) => {
    e.preventDefault();
    console.log("Typing...");
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    var selectedRec = recordOptions.records.find((rec) => rec["Name"] === e.target.id);
    console.log(selectedRec);

    if (!multipleRecordsSelect) {
      console.log(e.target);
      console.log(e.target.id);
      setSelectedRecord({
        record: selectedRec,
        baseId: recordOptions.baseId,
        tableName: recordOptions.tableName,
        viewName: recordOptions.viewName,
      });
    } else {
      // si multiple records link
      if (selectedRecord.records.length > 0) {
        console.log(selectedRecord.records);

        if (selectedRecord.records.findIndex((rec) => rec.id === selectedRec.id) > -1) {
          let i = selectedRecord.records.findIndex((rec) => rec.id === selectedRec.id);
          selectedRecord.records.splice(i, 1);
          setSelectedRecord({ ...selectedRecord });
        } else {
          selectedRecord.records.push(selectedRec);
          setSelectedRecord({ ...selectedRecord });
        }
      } else {
        setSelectedRecord({
          records: [selectedRec],
          baseId: recordOptions.baseId,
          tableName: recordOptions.tableName,
          viewName: recordOptions.viewName,
        });
      }
    }
  };

  return (
    <div className="table-with-select">
      <div className="div-select" style={{ width: "100%" }}>
        <input className="select" name="select" onInput={handleInputOnChange} type="text" placeholder="Rechercher..."></input>
      </div>
      <div className="table-container">
        <div className="table" style={{ width: "300px", height: "100%", "text-align": "left" }}>
          {recordOptions.records.length > 0
            ? recordOptions.records.map((opt, i) => {
                return search !== "" ? (
                  opt["Name"] !== undefined ? (
                    opt["Name"].toLowerCase().indexOf(search.toLowerCase()) !== -1 ? (
                      <ul
                        key={i}
                        value={opt["Name"]}
                        id={opt["Name"]}
                        onClick={handleOnClick}
                        style={
                          multipleRecordsSelect
                            ? selectedRecord.records.findIndex(
                                (rec) => rec.id === recordOptions.records.find((rec) => rec["Name"] === opt["Name"]).id
                              ) > -1
                              ? { "background-color": "#f4af24" }
                              : null
                            : null
                        }
                      >
                        {opt["Name"]}
                      </ul>
                    ) : null
                  ) : null
                ) : (
                  <ul
                    key={i}
                    value={opt["Name"]}
                    id={opt["Name"]}
                    onClick={handleOnClick}
                    style={
                      multipleRecordsSelect
                        ? selectedRecord.records.findIndex(
                            (rec) => rec.id === recordOptions.records.find((rec) => rec["Name"] === opt["Name"]).id
                          ) > -1
                          ? { "background-color": "#f4af24" }
                          : null
                        : null
                    }
                  >
                    {opt["Name"]}
                  </ul>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
export default TableWithTextInput;
