import "../styles/Faq.css";
import React, { useState, useEffect, useRef } from "react";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@mui/styles";
import axios from "axios";
// import FaqContent from "./FaqContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";
import TableChartIcon from "@mui/icons-material/TableChart";
import BackupIcon from "@mui/icons-material/Backup";
import Select from "@material-ui/core/Select/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Tooltip, withWidth } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import "../styles/Faq.css";
function FaqEditor({ user, setUser }) {
  const ref = useRef(null);
  const [anchor, setAnchor] = useState(null);

  const [choosenFaqContent, setChoosenFaqContent] = useState({});
  const [nonSavedModifications, setNonSavedModifications] = useState(false);
  const [contentJustLoaded, setContentJustLoaded] = useState(false);
  const [reducedPannel, setReducedPannel] = useState(false);
  const [existingContents, setExistingContents] = useState([]);

  const [alertBox, setAlertBox] = useState({ message: "", isSuccess: false });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFileUpload = (file) => {
    console.log("file", file);
    console.log("ref", ref);

    ref.current?.insertAtomicBlockAsync("IMAGE", uploadImage(file), "Uploading now...");
  };

  const uploadImageToServer = (file) => {
    let formData = new FormData();

    return new Promise((resolve) => {
      formData.append("rte", file);

      console.log(`Uploading image ${file.name} ...`);
      console.log(file);

      axios
        .post("api/files/uploadFile", formData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },

          mode: "cors",
        })
        .then((res) => {
          console.log(res.data);
          resolve(res.data.url);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const uploadImage = (file) => {
    return new Promise(async (resolve, reject) => {
      const url = await uploadImageToServer(file);
      if (!url) {
        reject();
        return;
      }
      // Create a new image element to get its dimensions
      const img = new Image();
      img.onload = () => {
        resolve({
          data: {
            url: url,
            width: img.width,
            height: img.height,
            alignment: "left", // or "center", "right"
            type: "image", // or "video"
          },
        });
      };
      img.src = url;
    });
  };

  const defaultTheme = createTheme();

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          // marginTop: 50,
          width: !reducedPannel ? "80%" : "95%",
          "max-height": "calc(100vh - 230px)",
        },
        container: {
          borderBottom: "1px solid gray",
        },
        editor: {
          "min-height": "350px",
          "max-height": "calc(100vh - 115px)",
          overflowY: "auto",
          "margin-left": "20px",
          "margin-right": "20px",
        },
      },
    },
  });

  const cardPopverStyles = makeStyles({
    root: {
      padding: 10,
      maxWidth: 350,
    },
    textField: {
      width: "100%",
    },
    input: {
      display: "none",
    },
  });

  const UploadImagePopover = (props) => {
    const classes = cardPopverStyles(props);
    const [state, setState] = useState({
      anchor: null,
      isCancelled: false,
    });
    const [data, setData] = useState({});

    useEffect(() => {
      console.log(props);
      setState({
        anchor: props.anchor,
        isCancelled: false,
      });
      setData({
        file: undefined,
      });
    }, [props.anchor]);

    return (
      <Popover
        anchorEl={state.anchor}
        open={state.anchor !== null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={10}>
            <TextField className={classes.textField} disabled value={data.file?.name || ""} placeholder="Click icon to attach image" />
          </Grid>
          <Grid item xs={2}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={(event) => {
                const file = event.target.files && event.target.files[0];
                if (file) {
                  setData({
                    ...data,
                    file,
                  });
                }
              }}
            />
            <label htmlFor="contained-button-file">
              <IconButton color="primary" aria-label="upload image" component="span">
                <AttachFileIcon />
              </IconButton>
            </label>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button
              onClick={() => {
                setState({
                  anchor: null,
                  isCancelled: true,
                });
              }}
            >
              <CloseIcon />
            </Button>
            <Button
              onClick={() => {
                setState({
                  anchor: null,
                  isCancelled: false,
                });
                props.onSubmit(data, !state.isCancelled);
              }}
            >
              <DoneIcon />
            </Button>
          </Grid>
        </Grid>
      </Popover>
    );
  };

  const HtmlToJsxIframe = ({ html }) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const iframeElement = doc.querySelector("iframe");
    if (!iframeElement) {
      return null;
    }

    const iframeAttributes = Array.from(iframeElement.attributes).reduce((acc, attribute) => {
      const attributeName = attribute.name.toLowerCase();
      if (attributeName === "style") {
        const styleString = attribute.value;
        const styleObj = styleString.split(";").reduce((styleAcc, style) => {
          const [property, value] = style.split(":").map((str) => str.trim());
          if (property && value) {
            styleAcc[property] = value;
          }
          return styleAcc;
        }, {});

        acc.style = styleObj;
      } else {
        acc[attributeName] = attribute.value;
      }
      return acc;
    }, {});

    return <iframe {...iframeAttributes}></iframe>;
  };

  const MyBlock = (props) => {
    return (
      <div
        style={{
          padding: 10,
          backgroundColor: "#ebebeb",
        }}
      >
        {props.children}
      </div>
    );
  };
  const iframeRegex = /<iframe[^>]*>[\s\S]*?<\/iframe>/gi;
  const MyIframeDecorator = (props) => {
    console.log(props);

    console.log(props.decoratedText);
    return HtmlToJsxIframe({ html: props.decoratedText });
  };

  const liRegex = /<ul\b[^>]*>(.*?)<\/ul>/g;
  const MyListItemDecorator = (props) => {
    console.log(props.children);
    return (
      <ul
        style={{
          marginLeft: "15px",
          padding: "40px",
        }}
      >
        {props.children}
      </ul>
    );
  };

  const handleChangeOption = (event) => {
    console.log(event.target.value);
    if (choosenFaqContent._id && nonSavedModifications) {
      var go;
      go = window.confirm("Vous êtes sur le point de changer d'article. Toute progression non sauvegardée sera perdue. Continuer ?");
      if (go) {
        setChoosenFaqContent(existingContents.find((elt) => elt.name === event.target.value));
        setNonSavedModifications(false);
        setContentJustLoaded(true);
        setTimeout(() => {
          setContentJustLoaded(false);
        }, 500);
      }
    } else {
      setChoosenFaqContent(existingContents.find((elt) => elt.name === event.target.value));
      setNonSavedModifications(false);
      setContentJustLoaded(true);
      setTimeout(() => {
        setContentJustLoaded(false);
      }, 500);
    }
  };

  const handleCreateNewFaqContent = (content) => {
    axios
      .post(
        "api/faqContent/create",
        {
          createdTime: Date.now().toString(),
          createdBy: user.email,
          content: content.text,
          name: content.name,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },

          mode: "cors",
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setExistingContents(res.data.faqContents);
        setNonSavedModifications(false);
        setAlertBox({ message: "✅ L'article a bien été enregistré !", isSuccess: true, show: true });
        setTimeout(() => {
          setAlertBox({ message: "✅ L'article a bien été enregistré !", isSuccess: true, show: false });
        }, 2000);
        // resolve(res.message);
      })
      .catch((err) => {
        console.log(err);
        setAlertBox({ message: "❌ Un problème est survenu...", isSuccess: false, show: true });
        setTimeout(() => {
          setAlertBox({ message: "❌ Un problème est survenu...", isSuccess: false, show: false });
        }, 2000);
      });
  };

  const handleUpdateFaqContent = (content) => {
    axios
      .post(
        "api/faqContent/update",
        {
          content: content.text,
          name: content.name,
          id: content.id,
          lastModifiedBy: user.email,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },

          mode: "cors",
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setExistingContents(res.data.faqContents);
        setNonSavedModifications(false);
        setAlertBox({ message: "✅ Les modifications ont bien été prise en compte !", isSuccess: true, show: true });
        setTimeout(() => {
          setAlertBox({ message: "✅ Les modifications ont bien été prise en compte !", isSuccess: true, show: false });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setAlertBox({ message: "❌ Un problème est survenu...", isSuccess: false, show: true });
        setTimeout(() => {
          setAlertBox({ message: "❌ Un problème est survenu...", isSuccess: false, show: false });
        }, 2000);
      });
  };

  const resetEdition = () => {
    if (nonSavedModifications) {
      var go;
      go = window.confirm("Vous êtes sur le point de créer un nouvel article. Toute progression non sauvegardée sera perdue. Continuer ?");
      if (go) {
        setChoosenFaqContent({});
      }
    } else {
      setChoosenFaqContent({});
    }
  };

  const removeFaqContent = () => {
    if (choosenFaqContent._id) {
      var go;
      go = window.confirm("Vous êtes sur le point de supprimer un article. Continuer ?");
      if (go) {
        axios
          .post(
            "api/faqContent/delete",
            {
              id: choosenFaqContent._id,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },

              mode: "cors",
            }
          )
          .then((res) => {
            console.log(res.data.message);
            setExistingContents(res.data.faqContents);
            setChoosenFaqContent({});
            setNonSavedModifications(false);
            setAlertBox({ message: "✅ L'article a bien été supprimé !", isSuccess: true, show: true });
            setTimeout(() => {
              setAlertBox({ message: "✅ L'article a bien été supprimé !", isSuccess: true, show: false });
            }, 2000);
          })
          .catch((err) => {
            console.log(err);
            setAlertBox({ message: "❌ Un problème est survenu...", isSuccess: false, show: true });
            setTimeout(() => {
              setAlertBox({ message: "❌ Un problème est survenu...", isSuccess: false, show: false });
            }, 2000);
          });
      }
    }
  };

  useEffect(() => {
    console.log(user);
    axios
      .post(
        "api/faqContent/getAll",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },

          mode: "cors",
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setExistingContents(res.data.faqContents);
        setAlertBox({ message: "✅ Téléchargement des articles réalisé avec succès !", isSuccess: true, show: true });
        setTimeout(() => {
          setAlertBox({ message: "✅ Téléchargement des articles réalisé avec succès !", isSuccess: true, show: false });
        }, 2000);
        // resolve(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  // Set nonSavedModifications state after content is loaded
  useEffect(() => {
    if (choosenFaqContent.content) {
      setNonSavedModifications(false);
    }
  }, [choosenFaqContent]);

  useEffect(() => {
    console.log("nonSavedModifications", nonSavedModifications);
  }, [nonSavedModifications]);

  return (
    <div id="rte" style={{ margin: "60px 20px 20px 20px" }}>
      <div
        style={{
          width: "250px",
          padding: "10px",
          background: alertBox.isSuccess ? "#00c6008a" : "#c600008a",
          position: "fixed",
          left: "calc(50% - 125px)",
          "border-radius": "5px",
          "box-shadow": "1px 10px 10px gray",
          display: alertBox.show ? "block" : "none",
          transition: "0.5s",
        }}
      >
        {alertBox.message}
      </div>

      <div
        id="control-pannel"
        style={{
          height: "100vh",
          width: "20%",
          position: "fixed",
          right: !reducedPannel ? "0" : "-15%",
          "border-radius": "5px 0",
          "box-shadow": "1px 1px 10px #80808087",
        }}
      >
        <div style={{ position: "relative", top: "20px", left: "15px", cursor: "pointer !important", zIndex: "1000000" }}>
          {reducedPannel ? (
            <Tooltip title="Expand">
              <IconButton>
                <ArrowBackIosNewIcon
                  onClick={(elt) => {
                    setReducedPannel(false);
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Reduce">
              <IconButton>
                <ArrowForwardIosIcon
                  onClick={(elt) => {
                    setReducedPannel(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div style={{ margin: "60px 20px 20px 20px" }}>
          <InputLabel>Créer un article...</InputLabel>
          <Tooltip title="Create">
            <IconButton>
              <AddIcon sx={{ height: 40, width: 40 }} onClick={resetEdition}></AddIcon>
            </IconButton>
          </Tooltip>

          <InputLabel>Modifier un article...</InputLabel>
          <Select
            sx={{ minWidth: 200 }}
            labelId="fields-multiple-checkbox-label"
            id="fields-multiple-checkbox"
            value={choosenFaqContent.name ? choosenFaqContent.name : ""}
            // placeholder='Choisissez le ou les champs "Attachments"'
            onChange={handleChangeOption}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected}
            MenuProps={MenuProps}
          >
            {existingContents.map((content) => (
              <MenuItem key={content.name} value={content.name}>
                <Checkbox checked={choosenFaqContent.name === content.name} />
                <ListItemText primary={content.name} />
              </MenuItem>
            ))}
          </Select>
          {choosenFaqContent.content ? (
            <div>
              <InputLabel>Supprimer un article...</InputLabel>
              <Tooltip title="Remove">
                <IconButton>
                  <DeleteIcon sx={{ height: 40, width: 40 }} onClick={removeFaqContent}></DeleteIcon>
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
      <ThemeProvider theme={defaultTheme}>
        <UploadImagePopover
          anchor={anchor}
          onSubmit={(data, insert) => {
            console.log(data.file);
            console.log(insert);

            if (insert && data.file) {
              handleFileUpload(data.file);
            }
            setAnchor(null);
          }}
        />
        <TextField
          id="faq-title"
          label="Titre de l'article"
          variant="outlined"
          value={choosenFaqContent.name ? choosenFaqContent.name : ""}
          inputProps={{
            onChange: (e) => {
              if (choosenFaqContent.name) {
                setChoosenFaqContent((prevObject) => ({
                  ...prevObject,
                  name: e.target.value, // Update the 'name' property
                }));
                console.log("ici1");
                setNonSavedModifications(true);
              } else {
                setChoosenFaqContent({
                  name: e.target.value,
                  content: "",
                  createdTime: Date.now(),
                  createdBy: user.email,
                });
                setNonSavedModifications(true);
                console.log("ici2");
              }
            },
          }}
        />
        <MUIRichTextEditor
          onSave={(data) => {
            console.log(data);
            if (choosenFaqContent.content) {
              handleUpdateFaqContent({ text: JSON.stringify(data), name: choosenFaqContent.name, id: choosenFaqContent._id });
            } else {
              handleCreateNewFaqContent({ text: JSON.stringify(data), name: choosenFaqContent.name });
            }
          }}
          ref={ref}
          readOnly={false}
          toolbar={true}
          inlineToolbar={true}
          draftEditorProps={{
            handleDroppedFiles: (_selectionState, files) => {
              if (files.length && files[0].name !== undefined) {
                handleFileUpload(files[0]);
                return "handled";
              }
              return "not-handled";
            },
          }}
          decorators={[
            {
              component: MyIframeDecorator,
              regex: iframeRegex,
            },
            {
              component: MyListItemDecorator,
              regex: liRegex,
            },
          ]}
          customControls={[
            {
              name: "my-block",
              icon: <TableChartIcon />,
              type: "block",
              blockWrapper: <MyBlock />,
            },
            {
              name: "upload-image",
              icon: <BackupIcon />,
              type: "callback",
              onClick: (_editorState, _name, anchor) => {
                setAnchor(anchor);
              },
            },
          ]}
          controls={[
            "title",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "highlight",
            "undo",
            "redo",
            "link",
            "media",
            "upload-image",
            "numberList",
            "bulletList",
            "quote",
            "code",
            "clear",
            "save",
          ]}
          defaultValue={choosenFaqContent.content ? JSON.parse(choosenFaqContent.content) : null}
          label="Ecrivez quelque chose..."
          onChange={(data) => {
            const plainText = data.getCurrentContent().getPlainText();
            setNonSavedModifications(plainText !== "" && !contentJustLoaded);
            console.log("ici3");
          }}
        />
      </ThemeProvider>
    </div>
  );
}
export default FaqEditor;
